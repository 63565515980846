import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { fetchWorkOrderById, saveWorkOrder, fetchWorkOrders } from '../../redux/workOrder/workOrderThunks';

const WorkOrderForm: React.FC = () => {
    const { id } = useParams<{ id: string }>(); // Get work order ID from the route
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const workOrder = useSelector((state: RootState) => state.workOrder.selectedWorkOrder);

    const mapContainer = useRef<HTMLDivElement | null>(null); // Use a ref for the map container
    const [mapInstance, setMapInstance] = useState<any>(null); // Store the map instance
    const [placemarkInstance, setPlacemarkInstance] = useState<any>(null); // Store the placemark instance
    const [description, setDescription] = useState('');
    const [customer, setCustomer] = useState('');
    const [address, setAddress] = useState('');
    const [coordinates, setCoordinates] = useState<[number, number]>([53.9, 27.5667]); // Default Minsk coordinates
    const [isDragging, setIsDragging] = useState(false); // Track if the placemark is being dragged

    // Fetch work order data when the component mounts or ID changes
    useEffect(() => {
        if (id) {
            dispatch(fetchWorkOrderById(Number(id))); // Fetch the work order if editing
        }
    }, [dispatch, id]);

    // Update local state when work order data is fetched
    useEffect(() => {
        if (workOrder && id) {
            setDescription(workOrder.description);
            setCustomer(workOrder.customer);
            setAddress(workOrder.address || '');
            setCoordinates([workOrder.latitude || 53.9, workOrder.longitude || 27.5667]);
        }
    }, [workOrder, id]);

    // Reset form state when work order ID changes
    useEffect(() => {
        if (!id) {
            setDescription('');
            setCustomer('');
            setAddress('');
            setCoordinates([53.9, 27.5667]); // Reset to default coordinates
        }
    }, [id]);

    // Cleanup effect to reset the form when unmounting or creating a new work order
    useEffect(() => {
        return () => {
            setDescription('');
            setCustomer('');
            setAddress('');
            setCoordinates([53.9, 27.5667]);
        };
    }, []);

    // Initialize the Yandex Map after the component has mounted
    useEffect(() => {
        if (window.ymaps && mapContainer.current) {
            const map = new window.ymaps.Map(mapContainer.current, {
                center: coordinates,
                zoom: 10,
            });

            const placemark = new window.ymaps.Placemark(coordinates, {}, {
                draggable: true, // Allow the placemark to be draggable
            });

            map.geoObjects.add(placemark);
            setMapInstance(map); // Save the map instance
            setPlacemarkInstance(placemark); // Save the placemark instance

            // Update coordinates when placemark is moved
            placemark.events.add('dragstart', () => {
                setIsDragging(true);
            });

            placemark.events.add('dragend', () => {
                const newCoords = placemark.geometry.getCoordinates();
                setCoordinates(newCoords); // Update state with new coordinates
                reverseGeocode(newCoords); // Update address input with new coordinates
                setIsDragging(false);
            });

            // Cleanup function
            return () => {
                placemark.events.remove('dragstart');
                placemark.events.remove('dragend');
                map.geoObjects.remove(placemark); // Remove the placemark
                setMapInstance(null); // Clear the map instance
                setPlacemarkInstance(null); // Clear placemark instance
            };
        }
    }, []); // No dependencies to run only once when the component mounts

    // Effect for updating the map and placemark position based on coordinates
    useEffect(() => {
        if (mapInstance && placemarkInstance) {
            placemarkInstance.geometry.setCoordinates(coordinates);
            mapInstance.setCenter(coordinates);
        }
    }, [coordinates, mapInstance, placemarkInstance]);

    // Geocode the address using Yandex Maps API when address changes
    useEffect(() => {
        if (mapInstance && address && !isDragging) {
            const ymaps = window.ymaps; // Access the global Yandex Maps object
            ymaps.geocode(address).then((res: any) => {
                const firstGeoObject = res.geoObjects.get(0);
                if (firstGeoObject) {
                    const coords = firstGeoObject.geometry.getCoordinates();
                    // Only update coordinates if they have changed
                    if (coordinates[0] !== coords[0] || coordinates[1] !== coords[1]) {
                        setCoordinates(coords); // Update the map center with new coordinates
                        placemarkInstance.geometry.setCoordinates(coords); // Update placemark
                        mapInstance.setCenter(coords); // Recenter map
                    }
                }
            });
        }
    }, [address, mapInstance, isDragging, placemarkInstance, coordinates]);

    // Reverse geocode coordinates to an address
    const reverseGeocode = (coords: [number, number]) => {
        const ymaps = window.ymaps;
        ymaps.geocode(coords).then((res: any) => {
            const firstGeoObject = res.geoObjects.get(0);
            if (firstGeoObject) {
                const newAddress = firstGeoObject.getAddressLine();
                setAddress(newAddress); // Update the input field with the new address
            }
        });
    };

    const handleSave = async () => {
        await dispatch(
            saveWorkOrder({
                id: id ? Number(id) : undefined,
                description,
                customer,
                address,
                latitude: coordinates[0],
                longitude: coordinates[1],
            })
        );
        // Refetch the work orders to update the list before navigating back
        dispatch(fetchWorkOrders());
        navigate('/workorders');
    };

    const handleCancel = () => {
        navigate('/workorders');
    };

    return (
        <div className="work-order-form">
            <TextField
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
            />
            <TextField
                label="Customer"
                value={customer}
                onChange={(e) => setCustomer(e.target.value)}
                fullWidth
            />
            <TextField
                label="Work Order Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                fullWidth
            />
            <div id="map" ref={mapContainer} style={{ width: '100%', height: '300px', marginTop: '20px' }}></div>
            <div className="form-buttons">
                <Button variant="contained" color="primary" onClick={handleSave}>
                    Save
                </Button>
                <Button variant="outlined" color="secondary" onClick={handleCancel}>
                    Cancel
                </Button>
            </div>
        </div>
    );
};

export default WorkOrderForm;
