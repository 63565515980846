import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, RadioGroup, Radio, FormControlLabel, FormControl, Select, InputLabel, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';  // Import eye and crossed-out eye icons
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { createNewUser, updateExistingUser, fetchUserById, fetchUsers } from '../../redux/user/userThunks';
import { AppDispatch, RootState } from '../../redux/store';
import MenuItem from '@mui/material/MenuItem';

const timeZones = [
    'Not selected',
    'Europe/Kaliningrad (UTC +2)',
    'Europe/Moscow (UTC +3)',
    'Europe/Samara (UTC+4)',
    'Asia/Yekaterinburg (UTC+5)',
    'Asia/Omsk (UTC +6)',
    'Asia/Novosibirsk (UTC+7)',
    'Asia/Irkutsk (UTC+8)',
    'Asia/Yakutsk (UTC+9)',
    'Asia/Vladivostok (UTC+10)',
    'Asia/Magadan (UTC+11)',
    'Asia/Kamchatka ( UTC+12)'
];

type Mode = 'create' | 'edit' | 'read';

interface FormValues {
    fullName: string;
    jobTitle: string;
    mobilePhone: string;
    timeZone: string;
    email: string;
    password: string;  // Keep password as a required string
    role: string;
}

const UserForm: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const role = useSelector((state: RootState) => state.auth.user?.role);
    const user = useSelector((state: RootState) => state.users.selectedUser);
    const [mode, setMode] = useState<Mode>('create');

    // State to manage password visibility
    const [showPassword, setShowPassword] = useState(false);
    // State to force password field focus
    const [passwordFieldFocused, setPasswordFieldFocused] = useState(false);
    // Reference to the password input for focusing
    const passwordInputRef = useRef<HTMLInputElement | null>(null);

    const [formValues, setFormValues] = useState<FormValues>({
        fullName: '',
        jobTitle: '',
        mobilePhone: '',
        timeZone: '',
        email: '',
        password: '',  // Initialize as an empty string
        role: 'DIVISIONAL',
    });

    useEffect(() => {
        if (id) {
            setMode('read');
            dispatch(fetchUserById(Number(id)));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (user && id) {
            setFormValues({
                fullName: user.fullName || '',
                jobTitle: user.jobTitle || '',
                mobilePhone: user.mobilePhone || '',
                timeZone: user.timeZone || '',
                email: user.email || '',
                password: '', // Reset to empty string to avoid showing the password
                role: user.role || 'DIVISIONAL',
            });
        }
    }, [user, id]);

    const handleSave = async () => {
        const dataToSend = { ...formValues };

        // If password is empty, set it to an empty string
        if (!formValues.password) {
            dataToSend.password = '';  // Ensure password is a string
        }

        if (mode === 'create') {
            await dispatch(createNewUser(dataToSend));
        } else if (mode === 'edit') {
            await dispatch(updateExistingUser({ id: Number(id), data: dataToSend }));
        }
        dispatch(fetchUsers());
        navigate('/employees');
    };

    const generatePassword = () => {
        const length = 8;
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#";
        let password = "";
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            password += charset[randomIndex];
        }
        setFormValues({ ...formValues, password });
        setPasswordFieldFocused(true);  // Set focus when password is generated
        if (passwordInputRef.current) {
            passwordInputRef.current.focus();  // Programmatically focus the field
        }
    };

    const handleEdit = () => {
        setMode('edit');
    };

    const isSuperAdmin = role === 'SUPER_ADMIN';

    return (
        <div>
            <h2>
                {mode === 'create'
                    ? 'Add Employee'
                    : mode === 'edit'
                        ? `${formValues.fullName} - Editing Employee`
                        : `${formValues.fullName} - Viewing Employee`}
            </h2>

            {/* Full Name Input */}
            <TextField
                label="Full Name"
                value={formValues.fullName}
                onChange={(e) => setFormValues({ ...formValues, fullName: e.target.value })}
                fullWidth
                disabled={mode === 'read'}
            />

            {/* Job Title Input */}
            <TextField
                label="Job Title"
                value={formValues.jobTitle}
                onChange={(e) => setFormValues({ ...formValues, jobTitle: e.target.value })}
                fullWidth
                disabled={mode === 'read'}
            />

            {/* Mobile Phone Input */}
            <TextField
                label="Mobile Phone"
                value={formValues.mobilePhone}
                onChange={(e) => setFormValues({ ...formValues, mobilePhone: e.target.value })}
                fullWidth
                disabled={mode === 'read'}
            />

            {/* Time Zone Selector */}
            <FormControl fullWidth disabled={mode === 'read'}>
                <InputLabel>Time Zone</InputLabel>
                <Select
                    variant="filled"
                    value={formValues.timeZone}
                    onChange={(e) => setFormValues({ ...formValues, timeZone: e.target.value })}
                >
                    {timeZones.map((zone) => (
                        <MenuItem key={zone} value={zone}>
                            {zone}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* Email Input */}
            <TextField
                label="Email"
                value={formValues.email}
                onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                fullWidth
                disabled={mode === 'read'}
            />

            {/* Password Input with Toggle Visibility */}
            {mode !== 'read' && (
                <div>
                    <TextField
                        inputRef={passwordInputRef}  // Reference for programmatic focus
                        label="Password"
                        type={showPassword ? 'text' : 'password'}  // Toggle between 'text' and 'password'
                        value={formValues.password}
                        onChange={(e) => setFormValues({ ...formValues, password: e.target.value })}
                        fullWidth
                        focused={passwordFieldFocused || !!formValues.password}  // Force focus when needed
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => {
                                            setShowPassword(!showPassword);
                                            setPasswordFieldFocused(true);  // Force focus when eye icon is clicked
                                        }}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button onClick={generatePassword}>Generate Password</Button>
                </div>
            )}

            {/* Role Selection */}
            <FormControl component="fieldset" disabled={mode === 'read'}>
                <RadioGroup
                    value={formValues.role}
                    onChange={(e) => setFormValues({ ...formValues, role: e.target.value })}
                >
                    <FormControlLabel value="SUPER_ADMIN" control={<Radio />} label="СУПЕР_АДМИН" />
                    <FormControlLabel value="DISPATCHER" control={<Radio />} label="ДИСПЕТЧЕР" />
                    <FormControlLabel value="EXECUTOR_ADMINISTRATOR" control={<Radio />} label="ИСПОЛНИТЕЛЬ / АДМИНИСТРАТОР" />
                    <FormControlLabel value="SB_CONTROLLER" control={<Radio />} label="СБ-КОНТРОЛЁР" />
                    <FormControlLabel value="DIVISIONAL" control={<Radio />} label="ДИВИЗИОНАЛЬНЫЙ" />
                </RadioGroup>
            </FormControl>

            {/* Button Controls */}
            {(mode === 'create' || mode === 'edit') && (
                <div>
                    <Button variant="contained" onClick={handleSave}>Save</Button>
                    <Button variant="outlined" onClick={() => navigate('/employees')}>Cancel</Button>
                </div>
            )}

            {mode === 'read' && (
                <div>
                    <Button variant="outlined" onClick={() => navigate('/employees')}>Back</Button>
                    {isSuperAdmin && <Button variant="contained" onClick={handleEdit}>Edit</Button>}
                </div>
            )}
        </div>
    );
};

export default UserForm;
