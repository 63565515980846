import axiosInstance from "./axiosConfig";
import axios from "axios";
import {
    Template,
    CreateTemplate,
    UpdateTemplate,
    FieldType,
    ReportFieldType,
} from "../redux/templates/types";

/** Fetch all templates */
export const fetchTemplates = async (): Promise<Template[]> => {
    try {
        const response = await axiosInstance.get("/templates");
        return response.data;
    } catch (error) {
        throw new Error(getErrorMessage(error));
    }
};

/** Fetch a single template by ID */
export const fetchTemplateById = async (id: number): Promise<Template> => {
    try {
        const response = await axiosInstance.get(`/templates/${id}`);
        return response.data;
    } catch (error) {
        throw new Error(getErrorMessage(error));
    }
};

/** Create a new template */
export const createTemplate = async (data: CreateTemplate): Promise<Template> => {
    try {
        const response = await axiosInstance.post<Template>("/templates", data);
        console.log("✅ API Response (Create Template):", response.data);
        return response.data;
    } catch (error: any) {
        console.error("❌ API Error (Create Template):", error);
        throw new Error(error.response?.data?.message || "Failed to create template");
    }
};

/** Update an existing template */
export const updateTemplate = async (id: number, data: UpdateTemplate): Promise<Template> => {
    try {
        const formattedData = {
            ...data,

            resolutions: data.resolutions.map(res => ({
                resolutionId: res.resolutionId,
                type: res.type,
            })),

            additionalFields: data.additionalFields.map(field => ({
                id: field.id,
                name: field.name,
                fieldType: field.fieldType,
                required: field.required ?? false, // ✅ Correct for Additional Fields
                placeholder: field.placeholder ?? null,
                url: [FieldType.LINK, FieldType.BUTTON].includes(field.fieldType)
                    ? field.url ?? null
                    : null,
                valueListId: field.fieldType === FieldType.SELECTOR
                    ? field.valueListId ?? null
                    : null,
            })),

            reportFields: data.reportFields.map(field => ({
                id: field.id,
                name: field.name,
                fieldType: field.fieldType,
                required: field.required ?? false,
                placeholder: field.placeholder ?? null,
                url: [ReportFieldType.LINK, ReportFieldType.BUTTON].includes(field.fieldType)
                    ? field.url ?? null
                    : null,
                valueListId: field.fieldType === ReportFieldType.SELECTOR
                    ? field.valueListId ?? null
                    : null,
            })),

        };

        const response = await axiosInstance.patch(`/templates/${id}`, formattedData);
        console.log("✅ API Response (Update Template):", response.data);
        return response.data;
    } catch (error: any) {
        console.error("❌ API Error (Update Template):", error);
        throw new Error(error.response?.data?.message || "Failed to update template");
    }
};

/** Delete a template by ID */
export const deleteTemplate = async (id: number): Promise<void> => {
    try {
        await axiosInstance.delete(`/templates/${id}`);
    } catch (error) {
        throw new Error(getErrorMessage(error));
    }
};

/** Helper function to extract API error messages */
const getErrorMessage = (error: unknown): string => {
    if (axios.isAxiosError(error)) {
        if (error.response?.data?.message) {
            return error.response.data.message;
        } else if (error.request) {
            return "No response from server";
        } else {
            return error.message;
        }
    }
    return "An unexpected error occurred";
};

