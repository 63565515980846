import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../redux/store';
import {
    fetchResolutionsThunk,
    createResolutionThunk,
    updateResolutionThunk,
    deleteResolutionThunk
} from '../../redux/resolutions/resolutionsThunks';
import { Resolution } from '../../redux/resolutions/resolutionsSlice';
import { Modal, Box, Typography, Button, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import './Resolution.scss';

const ResolutionItem: React.FC<{ resolution: Resolution }> = ({ resolution }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [isEditing, setIsEditing] = useState(false);
    const [editedName, setEditedName] = useState(resolution.name);
    const [editedType, setEditedType] = useState<'SUCCESSFUL' | 'UNSUCCESSFUL'>(resolution.type);
    const [editedRequiresNote, setEditedRequiresNote] = useState(resolution.requiresNote);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const handleSave = () => {
        dispatch(updateResolutionThunk({ id: resolution.id, data: { name: editedName, type: editedType, requiresNote: editedRequiresNote } }));
        setIsEditing(false);
    };

    return (
        <li>
            {isEditing ? (
                <div className='resolution__item-edit'>
                    <div className="resolution__item-edit__top">
                        <p className="resolution__item-edit__title">Редактирование резолюции</p>
                        <button onClick={() => setIsEditing(false)} className="resolution__item-edit__back">
                            Отменить
                        </button>
                    </div>
                    <p className="resolution__item-edit__input-name">Название *</p>
                    <input value={editedName} onChange={(e) => setEditedName(e.target.value)} />

                    <div className="resolution__item-edit__checkboxs">
                        <div className="resolution__item-edit__checkbox">
                            <p className="resolution__item-edit__input-name">Тип резолюции</p>
                            <RadioGroup row value={editedType} onChange={(e) => setEditedType(e.target.value as 'SUCCESSFUL' | 'UNSUCCESSFUL')}>
                                <FormControlLabel value="SUCCESSFUL" control={<Radio />} label="Успешная" className="resolution__item-edit__radio-button" />
                                <FormControlLabel value="UNSUCCESSFUL" control={<Radio />} label="Неуспешная" className="resolution__item-edit__radio-button" />
                            </RadioGroup>
                        </div>
                        <div className="resolution__item-edit__checkbox">
                            <p className="resolution__item-edit__input-name">Примечание</p>
                            <RadioGroup row value={editedRequiresNote ? 'true' : 'false'} onChange={(e) => setEditedRequiresNote(e.target.value === 'true')}>
                                <FormControlLabel value="true" control={<Radio />} label="С примечанием" className="resolution__item-edit__radio-button" />
                                <FormControlLabel value="false" control={<Radio />} label="Без примечания" className="resolution__item-edit__radio-button" />
                            </RadioGroup>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='resolution__content'>
                    <p className='resolution__name'>{resolution.name}</p>
                    <p className='resolution__note'>{resolution.requiresNote ? 'Примечание обязательно' : 'Примечание необязательно'}</p>
                </div>
            )}

            {isEditing ? <button onClick={handleSave} className='settings__change settings__change_save'></button> : <button onClick={() => setIsEditing(true)} className='settings__change settings__change_edit'></button>}
            <button onClick={() => setOpenDeleteModal(true)} className='settings__change settings__change_trash'></button>

            {/* ✅ MODAL FOR DELETE CONFIRMATION ✅ */}
            <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
                <Box className="delete-modal">
                    <Typography variant="h6" className="delete-modal-title">
                        Действительно ли вы хотите удалить резолюцию "{resolution.name}"?
                    </Typography>
                    <Box className="delete-modal-buttons">
                        <Button
                            variant="contained"
                            className="delete-modal-confirm"
                            onClick={() => {
                                dispatch(deleteResolutionThunk(resolution.id));
                                setOpenDeleteModal(false);
                            }}
                        >
                            Удалить
                        </Button>
                        <Button variant="outlined" className="delete-modal-cancel" onClick={() => setOpenDeleteModal(false)}>
                            Отмена
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </li>
    );
};

const ResolutionsList: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const resolutions = useSelector((state: RootState) => state.resolutions.resolutions);
    const [isAdding, setIsAdding] = useState(false);
    const [newResolutionName, setNewResolutionName] = useState('');
    const [newResolutionType, setNewResolutionType] = useState<'SUCCESSFUL' | 'UNSUCCESSFUL'>('SUCCESSFUL');
    const [newResolutionRequiresNote, setNewResolutionRequiresNote] = useState(false);

    useEffect(() => {
        dispatch(fetchResolutionsThunk());
    }, [dispatch]);

    const handleSaveNewResolution = () => {
        if (!newResolutionName.trim()) return;

        dispatch(createResolutionThunk({
            name: newResolutionName,
            type: newResolutionType,
            requiresNote: newResolutionRequiresNote,
        }));

        setIsAdding(false);
        setNewResolutionName('');
    };

    return (
        <section className='settings'>
            <div className="container container-small">
                <div className='order-type settings__inner'>
                    <h2 className='section-title'>Резолюции</h2>
                    <div className="resolution__item">
                        <p className='resolution__title green'>Успешные</p>
                        <ul className='resolution__list'>
                            {resolutions.filter(res => res.type === 'SUCCESSFUL').map((resolution) => (
                                <ResolutionItem key={resolution.id} resolution={resolution} />
                            ))}
                        </ul>
                    </div>
                    <div className="resolution__item">
                        <p className='resolution__title red'>Неуспешные</p>
                        <ul className='resolution__list'>
                            {resolutions.filter(res => res.type === 'UNSUCCESSFUL').map((resolution) => (
                                <ResolutionItem key={resolution.id} resolution={resolution} />
                            ))}
                        </ul>
                    </div>

                    {isAdding && (
                        <ul className="resolution__list">
                            <li>
                                <div className='resolution__item-edit'>
                                    <div className="resolution__item-edit__top">
                                        <p className="resolution__item-edit__title">Добавление резолюции</p>
                                        <button onClick={() => setIsAdding(false)} className="resolution__item-edit__back">
                                            Отменить
                                        </button>
                                    </div>
                                    <p className="resolution__item-edit__input-name">Название *</p>
                                    <input value={newResolutionName} onChange={(e) => setNewResolutionName(e.target.value)} />
                                    <div className="resolution__item-edit__checkboxs">
                                        <div className="resolution__item-edit__checkbox">
                                            <p className="resolution__item-edit__input-name">Тип резолюции</p>
                                            <RadioGroup
                                                row
                                                value={newResolutionType}
                                                onChange={(e) => setNewResolutionType(e.target.value as 'SUCCESSFUL' | 'UNSUCCESSFUL')}
                                            >
                                                <FormControlLabel
                                                    value="SUCCESSFUL"
                                                    control={<Radio />}
                                                    label="Успешная"
                                                    className="resolution__item-edit__radio-button"
                                                />
                                                <FormControlLabel
                                                    value="UNSUCCESSFUL"
                                                    control={<Radio />}
                                                    label="Неуспешная"
                                                    className="resolution__item-edit__radio-button"
                                                />
                                            </RadioGroup>
                                        </div>
                                        <div className="resolution__item-edit__checkbox">
                                            <p className="resolution__item-edit__input-name">Примечание</p>
                                            <RadioGroup
                                                value={newResolutionRequiresNote ? 'true' : 'false'}
                                                onChange={(e) => setNewResolutionRequiresNote(e.target.value === 'true')}
                                                row
                                            >
                                                <FormControlLabel
                                                    value="true"
                                                    control={<Radio />}
                                                    label="С примечанием"
                                                    className="resolution__item-edit__radio-button"
                                                />
                                                <FormControlLabel
                                                    value="false"
                                                    control={<Radio />}
                                                    label="Без примечания"
                                                    className="resolution__item-edit__radio-button"
                                                />
                                            </RadioGroup>
                                        </div>
                                    </div>
                                </div>
                                <button onClick={handleSaveNewResolution} className='settings__change settings__change_save'></button>
                            </li>
                        </ul>
                    )}
                    <button onClick={() => setIsAdding(true)} className='resolution__btn-add'>Добавить резолюцию</button>
                </div>
            </div>
        </section>
    );
};

export default ResolutionsList;
