// src/api/axiosConfig.ts
import axios from 'axios';

// Create an Axios instance with the base URL
const axiosInstance = axios.create({
    baseURL: 'https://api.alcovrach.tech/api', // Set the base URL here
    headers: {
        'Content-Type': 'application/json',
    },
});

export default axiosInstance;
