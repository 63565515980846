import React, { useState, ChangeEvent, useEffect } from 'react';

interface Props {
  initialValue?: string;
  onChange?: (value: string) => void;
}

const formatCurrency = (value: string): string => {
  const number = parseFloat(value.replace(/[^\d]/g, '')) / 100;
  if (isNaN(number)) {
    return '0.00';
  }
  return number.toLocaleString('ru-RU', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const PriceInput: React.FC<Props> = ({ initialValue = '0.00', onChange }) => {
  const [value, setValue] = useState(formatCurrency(initialValue));

  useEffect(() => {
    setValue(formatCurrency(initialValue));
  }, [initialValue]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;
    newValue = newValue.replace(/[^\d]/g, '');

    if (newValue.length > 0) {
      newValue = formatCurrency(newValue);
    } else {
      newValue = '0.00';
    }

    setValue(newValue);
    onChange?.(newValue);
  };

  return (
    <div className='standart-input-price'>
    <input
      type="text"
      className="standart-input"
      placeholder="0.00"
      value={value}
      onChange={handleChange}
      maxLength={10}
    />
     </div>
  );
};

export default PriceInput;