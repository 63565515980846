// src/redux/user/userThunks.ts
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUsers, createUser, updateUser, deleteUser, getUserById } from '../../api/userApi';
import { CreateUserPayload, UpdateUserPayload } from './userTypes';

// Fetch all users
export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
    return await getUsers();
});

// Fetch user by ID
export const fetchUserById = createAsyncThunk('users/fetchUserById', async (id: number) => {
    return await getUserById(id);
});

// Create a new user
export const createNewUser = createAsyncThunk('users/createUser', async (user: CreateUserPayload) => {
    return await createUser(user);
});

// Update an existing user
export const updateExistingUser = createAsyncThunk('users/updateUser', async ({ id, data }: {
    id: number;
    data: UpdateUserPayload;
}) => {
    return await updateUser(id, data);
});

// Delete a user
export const deleteUserById = createAsyncThunk('users/deleteUser', async (id: number) => {
    return await deleteUser(id);
});
