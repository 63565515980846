import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    createTemplate,
    deleteTemplate,
    fetchTemplateById,
    fetchTemplates,
    updateTemplate,
} from "../../api/templatesApi";
import { CreateTemplate, UpdateTemplate } from "./types";

// Error message extraction helper
const getErrorMessage = (error: unknown): string => {
    if (error instanceof Error && error.message) return error.message;
    return "An unexpected error occurred";
};

// Fetch all templates
export const fetchTemplatesThunk = createAsyncThunk(
    "templates/fetchAll",
    async (_, { rejectWithValue }) => {
        try {
            return await fetchTemplates();
        } catch (error) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

// Fetch a single template by ID
export const fetchTemplateByIdThunk = createAsyncThunk(
    "templates/fetchById",
    async (id: number, { rejectWithValue }) => {
        try {
            return await fetchTemplateById(id);
        } catch (error) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

// Create a new template
export const createTemplateThunk = createAsyncThunk(
    "templates/create",
    async (data: CreateTemplate, { rejectWithValue }) => {
        try {
            return await createTemplate(data); // fully formed object
        } catch (error) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

// Update template
export const updateTemplateThunk = createAsyncThunk(
    "templates/update",
    async ({ id, data }: { id: number; data: UpdateTemplate }, { rejectWithValue }) => {
        try {
            return await updateTemplate(id, data);
        } catch (error) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

// Delete template
export const deleteTemplateThunk = createAsyncThunk(
    "templates/delete",
    async (id: number, { rejectWithValue }) => {
        try {
            await deleteTemplate(id);
            return id;
        } catch (error) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);
