// src/hooks/useAuth.ts
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginThunk, logoutThunk } from '../redux/auth/authThunks';
import { AppDispatch, RootState } from '../redux/store';

export const useAuth = () => {
    const token = useSelector((state: RootState) => state.auth.token); // Access the token
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    const login = async (email: string, password: string, rememberMe: boolean) => {
        await dispatch(loginThunk({ email, password, rememberMe }));
        navigate('/workorders');
    };

    const logout = async () => {
        await dispatch(logoutThunk());
        navigate('/');
    };

    return { token, login, logout };
};
