import React, { useEffect, useState } from 'react';
import {
TextField,
Button,
Box,
Typography,
IconButton,
List,
ListItem,
} from '@mui/material';
import { Save, Delete, Add, DragIndicator, ArrowBack } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../redux/store';
import {
fetchValueListById,
createValueList,
updateValueList,
deleteValueList,
} from '../../redux/valueList/valueListThunks';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate, useParams } from 'react-router-dom';

const ITEM_TYPE = 'Option';

interface Option {
id: number;
value: string;
}

const ValueListForm: React.FC = () => {
const { id } = useParams<{ id: string }>();
const isEditMode = !!id;

const dispatch = useDispatch<AppDispatch>();
const navigate = useNavigate();

const { activeValueList } = useSelector((state: RootState) => state.valueList);

const [name, setName] = useState('');
const [options, setOptions] = useState<Option[]>([]);

// Load data in edit mode
useEffect(() => {
if (isEditMode && id) {
    dispatch(fetchValueListById(Number(id)));
}
}, [dispatch, id, isEditMode]);

// Sync activeValueList with state
useEffect(() => {
if (activeValueList && isEditMode) {
    setName(activeValueList.name);
    setOptions(
        activeValueList.options.map((value, index) => ({
            id: index,
            value,
        }))
    );
}
}, [activeValueList, isEditMode]);

const handleSave = () => {
const payload = { name, options: options.map((option) => option.value) };
if (isEditMode && id) {
    dispatch(updateValueList({ id: Number(id), data: payload }));
} else {
    dispatch(createValueList(payload));
}
navigate('/value-lists');
};

const handleBack = () => {
navigate('/value-lists');
};

const handleDelete = () => {
if (isEditMode && id) {
    dispatch(deleteValueList(Number(id)));
    navigate('/value-lists');
}
};

const handleAddOption = () => {
setOptions((prevOptions) => [
    ...prevOptions,
    { id: Date.now(), value: '' },
]);
};

const handleOptionDelete = (id: number) => {
setOptions((prevOptions) => prevOptions.filter((option) => option.id !== id));
};

const moveOption = (dragIndex: number, hoverIndex: number) => {
setOptions((prevOptions) => {
    const newOptions = [...prevOptions];
    const [draggedOption] = newOptions.splice(dragIndex, 1);
    newOptions.splice(hoverIndex, 0, draggedOption);
    return newOptions;
});
};

const OptionItem: React.FC<{
option: Option;
index: number;
moveOption: (dragIndex: number, hoverIndex: number) => void;
onDelete: (id: number) => void;
}> = React.memo(({ option, index, moveOption, onDelete }) => {
const [value, setValue] = useState(option.value);

const [, ref] = useDrag({
    type: ITEM_TYPE,
    item: { index },
});

const [, drop] = useDrop({
    accept: ITEM_TYPE,
    hover: (item: { index: number }) => {
        if (item.index !== index) {
            moveOption(item.index, index);
            item.index = index;
        }
    },
});

useEffect(() => {
    setValue(option.value);
}, [option.value]);

return (
    <ListItem
        ref={(node) => ref(drop(node))}
        style={{ display: 'flex', alignItems: 'center' }}
    >
        <DragIndicator />
        <TextField
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={() => setOptions((prevOptions) =>
                prevOptions.map((opt) =>
                    opt.id === option.id ? { ...opt, value } : opt
                )
            )}
            fullWidth
        />
        <IconButton onClick={() => onDelete(option.id)}>
            <Delete />
        </IconButton>
    </ListItem>
);
});

return (
<DndProvider backend={HTML5Backend}>
    <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Button startIcon={<ArrowBack />} onClick={handleBack}>
                Back
            </Button>
            {isEditMode && (
                <Button startIcon={<Delete />} color="error" onClick={handleDelete}>
                    Delete
                </Button>
            )}
        </Box>
        <Typography variant="h5">
            {isEditMode ? 'Edit Value List' : 'Create Value List'}
        </Typography>
        <TextField
            label="List Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
        />
        <Typography variant="h6">Options</Typography>
        <List>
            {options.map((option, index) => (
                <OptionItem
                    key={option.id}
                    option={option}
                    index={index}
                    moveOption={moveOption}
                    onDelete={handleOptionDelete}
                />
            ))}
        </List>
        <Button startIcon={<Add />} onClick={handleAddOption} sx={{ mt: 2 }}>
            Add Option
        </Button>
        <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button variant="contained" color="primary" startIcon={<Save />} onClick={handleSave}>
                Save
            </Button>
        </Box>
    </Box>
</DndProvider>
);
};

export default ValueListForm;
