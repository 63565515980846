import React, { useRef, useEffect, useState } from 'react';

interface CustomPopoverProps {
  id: string;
  children: React.ReactNode;
  renderTrigger: (props: { onClick: (event: React.MouseEvent<HTMLElement>) => void, id: string }) => React.ReactNode;
}

const CustomPopover: React.FC<CustomPopoverProps> = ({ id, children, renderTrigger }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation(); // Prevent unwanted propagation
    setIsOpen((prev) => !prev); // Toggle the popover state
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
          isOpen &&
          popoverRef.current &&
          !popoverRef.current.contains(event.target as Node) &&
          !(event.target as HTMLElement)?.closest('.MuiDialog-root') &&
          !(event.target as HTMLElement)?.closest('.MuiPopper-root')
      ) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
      <>
        {/* Button to toggle popover */}
        {renderTrigger({ onClick: handleClick, id })}

        {/* Popover content */}
        {isOpen && (
            <div id={`${id}-popover`} ref={popoverRef} onClick={handleClose}>
              {children}
            </div>
        )}
      </>
  );
};

export default CustomPopover;
