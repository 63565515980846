// Enum for field types
export enum FieldType {
    TEXT = "TEXT",
    FILE = "FILE",
    LINK = "LINK",
    SELECTOR = "SELECTOR",
    MONEY = "MONEY",
    INTEGER = "INTEGER",
    FLOAT = "FLOAT",
    DATE = "DATE",
    TIME = "TIME",
    DATE_TIME = "DATE_TIME",
    BUTTON = "BUTTON",
}

// Enum for report field types
export enum ReportFieldType {
    TEXT = "TEXT",
    FILE = "FILE",
    LINK = "LINK",
    SELECTOR = "SELECTOR",
    MONEY = "MONEY",
    INTEGER = "INTEGER",
    FLOAT = "FLOAT",
    DATE = "DATE",
    TIME = "TIME",
    DATE_TIME = "DATE_TIME",
    PHOTO = "PHOTO",
    YES_NO = "YES_NO",
    QR_CODE = "QR_CODE",
    SIGNATURE = "SIGNATURE",
    ACTION = "ACTION",
    BUTTON = "BUTTON",
}

// Enum for resolution types
export enum ResolutionType {
    SUCCESSFUL = "SUCCESSFUL",
    UNSUCCESSFUL = "UNSUCCESSFUL",
}

// Resolutions should only store ID & type (we do NOT create them)
// Fix Resolution Type to Use `resolutionId` Instead of `id`
export interface Resolution {
    resolutionId: number; // BackEnd expects `resolutionId`
    type: ResolutionType; // SUCCESSFUL or UNSUCCESSFUL
    name?: string;
}

// Ensure TemplateField & ReportField Include `templateId`
export interface TemplateField {
    id?: number;
    templateId?: number;
    name: string;
    fieldType: FieldType;
    required?: boolean;
    placeholder?: string;
    url?: string; // Only for LINK or BUTTON
    valueListId?: number; // Only for SELECTOR
}

export interface ReportField {
    id?: number;
    templateId?: number;
    name: string;
    fieldType: ReportFieldType;
    required?: boolean;
    placeholder?: string;
    url?: string; // Only for LINK or BUTTON
    valueListId?: number; // Only for SELECTOR
}


// Create Template (Used when making a new template)
export interface CreateTemplate {
    name: string;
    orderTypeId: number | null;
    description?: string | null;
    duration?: number | null;
    executorSkillId?: number | null;
    areaId?: number | null;
    teamId?: number | null;
    additionalFields: TemplateField[];
    reportFields: ReportField[];
    resolutions: Resolution[]; // Only stores IDs
}

// Update Template (Includes ID)
export interface UpdateTemplate extends CreateTemplate {
    id: number;
}

// Full Template (Returned from API)
export interface Template extends UpdateTemplate {
    id: number;
}
