import React from 'react';
import MapCategory from '../components/Map/MapCategory';

export const Map = () => {
    return (
        <>
            <MapCategory/>
        </>
    );
};
