import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Table, TableRow, TableCell, IconButton, Modal, Typography, Box, TableHead, TableBody, TableSortLabel } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { fetchTeams, deleteTeamById } from '../../redux/team/teamThunks';
import { RootState, AppDispatch } from '../../redux/store';
import { useNavigate } from 'react-router-dom';

type SortField = 'name' | 'leader' | 'area';

const TeamsList: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const teams = useSelector((state: RootState) => state.teams.teams);
    const navigate = useNavigate();

    const [deleteTeamId, setDeleteTeamId] = useState<number | null>(null);
    const [openModal, setOpenModal] = useState(false);

    // Sorting state
    const [sortField, setSortField] = useState<SortField>('name');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

    useEffect(() => {
        dispatch(fetchTeams()); // Fetch all teams from backend
    }, [dispatch]);

    const handleDelete = async (id: number) => {
        await dispatch(deleteTeamById(id));
        setOpenModal(false); // Close modal after deletion
    };

    const openDeleteModal = (id: number) => {
        setDeleteTeamId(id);
        setOpenModal(true); // Open delete confirmation modal
    };

    const closeDeleteModal = () => {
        setOpenModal(false);
        setDeleteTeamId(null);
    };

    const handleEdit = (id: number) => {
        // Navigate to the team in edit mode
        navigate(`/teams/${id}`, { state: { mode: 'edit' } });
    };

    const handleRowClick = (id: number) => {
        // Navigate to the team in read mode
        navigate(`/teams/${id}`, { state: { mode: 'read' } });
    };

    const handleCreate = () => {
        // Navigate to the team creation page
        navigate(`/teams/new`, { state: { mode: 'create' } });
    };

    // Sorting logic
    const handleSort = (field: SortField) => {
        const isAsc = sortField === field && sortDirection === 'asc';
        setSortField(field);
        setSortDirection(isAsc ? 'desc' : 'asc');
    };

    const sortedTeams = [...teams].sort((a, b) => {
        let aValue = '';
        let bValue = '';

        switch (sortField) {
            case 'name':
                aValue = a.name;
                bValue = b.name;
                break;
            case 'leader':
                aValue = a.leader?.fullName || '';
                bValue = b.leader?.fullName || '';
                break;
            case 'area':
                aValue = a.area?.name || '';
                bValue = b.area?.name || '';
                break;
        }

        if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
        return 0;
    });

    return (
        <div>
            <h2>Teams</h2>
            <h3>Total Teams: {teams.length}</h3>

            <Button variant="contained" color="primary" onClick={handleCreate}>
                Add New Team
            </Button>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={sortField === 'name'}
                                direction={sortField === 'name' ? sortDirection : 'asc'}
                                onClick={() => handleSort('name')}
                            >
                                Team Name
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortField === 'leader'}
                                direction={sortField === 'leader' ? sortDirection : 'asc'}
                                onClick={() => handleSort('leader')}
                            >
                                Leader
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>Members</TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortField === 'area'}
                                direction={sortField === 'area' ? sortDirection : 'asc'}
                                onClick={() => handleSort('area')}
                            >
                                Area
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>Options</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedTeams.map((team) => (
                        <TableRow
                            key={team.id}
                            onClick={() => handleRowClick(team.id)}
                            sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: '#c7e8f2', // Light grey on hover
                                },
                            }}
                        >
                            <TableCell>{team.name}</TableCell>
                            <TableCell>{team.leader?.fullName || 'No Leader'}</TableCell>
                            <TableCell>
                                {team.members ? team.members.map((member) => member.fullName).join(', ') : 'No Members'}
                            </TableCell>
                            <TableCell>{team.area?.name || 'No Area'}</TableCell>
                            <TableCell>
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleEdit(team.id);
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openDeleteModal(team.id);
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {/* Delete Confirmation Modal */}
            <Modal open={openModal} onClose={closeDeleteModal}>
                <Box sx={{ padding: 2, backgroundColor: 'white', borderRadius: '8px', width: '400px', margin: 'auto' }}>
                    <Typography variant="h6">Are you sure you want to delete this team?</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                        <Button variant="contained" color="secondary" onClick={() => deleteTeamId && handleDelete(deleteTeamId)}>
                            Yes, delete
                        </Button>
                        <Button variant="outlined" onClick={closeDeleteModal}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default TeamsList;
