import React, { useState, useRef, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';

const ResponsiveAppBar: React.FC = () => {
    const { token, logout } = useAuth();
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorElSettings, setAnchorElSettings] = useState<null | HTMLElement>(null);
    const settingsMenuRef = useRef<HTMLButtonElement | null>(null);
    const navigate = useNavigate();
    const location = useLocation();

    const pages = ['WorkOrders', 'Teams', 'Employees'];

    // Check if current route matches any settings submenu path
    const isSettingsSelected = ['/resolutions', '/areas', '/reports'].some(
        path => location.pathname === path
    );

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleOpenSettingsMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElSettings(event.currentTarget);
    };

    const handleCloseSettingsMenu = () => {
        setAnchorElSettings(null);
    };

    // Close settings menu if clicking outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                anchorElSettings &&
                !settingsMenuRef.current?.contains(event.target as Node)
            ) {
                handleCloseSettingsMenu();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [anchorElSettings]);

    const handleSettingsItemClick = (path: string) => {
        navigate(path);
        handleCloseSettingsMenu();
    };

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        {'Алковрач'}
                    </Typography>

                    {token && (
                        <>
                            {/* Mobile View */}
                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="open navigation menu"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                    keepMounted
                                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{ display: { xs: 'block', md: 'none' } }}
                                >
                                    {pages.map((page) => (
                                        <MenuItem
                                            key={page}
                                            onClick={() => {
                                                navigate(`/${page.toLowerCase()}`);
                                                handleCloseNavMenu();
                                            }}
                                            selected={location.pathname === `/${page.toLowerCase()}`}
                                            sx={{
                                                '&:hover': {
                                                    backgroundColor: 'rgba(255,255,255,0.15)',
                                                },
                                            }}
                                        >
                                            <Typography textAlign="center">{page}</Typography>
                                        </MenuItem>
                                    ))}
                                    {/* Settings in Mobile View */}
                                    <MenuItem onClick={handleOpenSettingsMenu}>
                                        <Typography textAlign="center">Settings</Typography>
                                    </MenuItem>
                                    <Menu
                                        anchorEl={anchorElSettings}
                                        open={Boolean(anchorElSettings)}
                                        onClose={handleCloseSettingsMenu}
                                        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                    >
                                        <MenuItem
                                            onClick={() => handleSettingsItemClick('/resolutions')}
                                            selected={location.pathname === '/resolutions'}
                                        >
                                            Resolutions
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => handleSettingsItemClick('/areas')}
                                            selected={location.pathname === '/areas'}
                                        >
                                            Areas
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => handleSettingsItemClick('/reports')}
                                            selected={location.pathname === '/reports'}
                                        >
                                            Reports
                                        </MenuItem>
                                    </Menu>
                                </Menu>
                            </Box>

                            {/* Desktop View */}
                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                {pages.map((page) => (
                                    <Button
                                        key={page}
                                        onClick={() => navigate(`/${page.toLowerCase()}`)}
                                        sx={{
                                            my: 2,
                                            color: 'white',
                                            display: 'block',
                                            fontWeight: location.pathname === `/${page.toLowerCase()}` ? 'bold' : 'normal',
                                            '&:hover': { backgroundColor: 'rgba(255,255,255,0.15)' },
                                        }}
                                    >
                                        {page}
                                    </Button>
                                ))}
                                {/* Settings button for Desktop */}
                                <Button
                                    onClick={handleOpenSettingsMenu}
                                    ref={settingsMenuRef}
                                    sx={{
                                        my: 2,
                                        color: 'white',
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontWeight: isSettingsSelected ? 'bold' : 'normal',
                                        '&:hover': { backgroundColor: 'rgba(255,255,255,0.15)' },
                                    }}
                                    startIcon={<SettingsIcon />}
                                >
                                    Settings
                                </Button>
                                <Menu
                                    anchorEl={anchorElSettings}
                                    open={Boolean(anchorElSettings)}
                                    onClose={handleCloseSettingsMenu}
                                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                >
                                    <MenuItem
                                        onClick={() => handleSettingsItemClick('/resolutions')}
                                        selected={location.pathname === '/resolutions'}
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: '#c7e8f2',
                                            },
                                        }}
                                    >
                                        Resolutions
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => handleSettingsItemClick('/areas')}
                                        selected={location.pathname === '/areas'}
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: '#c7e8f2',
                                            },
                                        }}
                                    >
                                        Areas
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => handleSettingsItemClick('/reports')}
                                        selected={location.pathname === '/reports'}
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: '#c7e8f2',
                                            },
                                        }}
                                    >
                                        Reports
                                    </MenuItem>
                                </Menu>
                            </Box>

                            {/* Logout Button */}
                            <Button
                                color="inherit"
                                onClick={logout}
                                startIcon={<LogoutIcon />}
                                sx={{
                                    border: '1px solid white',
                                    ml: 2,
                                    padding: '5px 15px',
                                    '&:hover': { backgroundColor: 'rgba(255,255,255,0.15)' },
                                }}
                            >
                                Logout
                            </Button>
                        </>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default ResponsiveAppBar;
