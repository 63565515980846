// Field types for DynamicFields
export const dynamicFieldTypes = [
    { value: "TEXT", label: "Текст", icon: "field-icon-text" },
    { value: "FILE", label: "Файл",  icon: 'field-icon-instruction' }, 
    { value: "LINK", label: "Ссылка", icon: "field-icon-link" },
    { value: "SELECTOR", label: "Значение из списка", icon: "field-icon-select" },
    { value: "MONEY", label: "Деньги", icon: "field-icon-money" },
    { value: "INTEGER", label: "Число", icon: "field-icon-text" },
    { value: "DATE", label: "Дата", icon: "field-icon-date" },
    { value: "TIME", label: "Время", icon: "field-icon-time" },
    { value: "DATE_TIME", label: "Дата и время", icon: "field-icon-date-time" },
    { value: "BUTTON", label: "Кнопка", icon: "field-icon-btn" },
];

// Field types for ReportFields
export const reportFieldTypes = [
    { value: "TEXT", label: "Текст", icon: "field-icon-text" },
    { value: "LINK", label: "Ссылка", icon: "field-icon-link" },
    { value: "FILE", label: "Файл", icon: 'field-icon-instruction' },
    { value: "PHOTO", label: "Фото", icon: "field-icon-photo" },
    { value: "QR_CODE", label: "QR-код", icon: "field-icon-qr" },
    { value: "SIGNATURE", label: "Подпись", icon: "field-icon-text" },
    { value: "SELECTOR", label: "Список значений", icon: "field-icon-select" },
    { value: "MONEY", label: "Деньги", icon: "field-icon-money" },
    { value: "INTEGER", label: "Целое число", icon: "field-icon-text" },
    { value: "FLOAT", label: "Дробное число", icon: "field-icon-text" },
    { value: "DATE", label: "Дата", icon: "field-icon-date" },
    { value: "TIME", label: "Время", icon: "field-icon-time" },
    { value: "DATE_TIME", label: "Дата и время", icon: "field-icon-date-time" },
    { value: "BUTTON", label: "Кнопка", icon: "field-icon-btn" },
    { value: "YES_NO", label: "Да/Нет", icon: "field-icon-btn" },
    { value: "ACTION", label: "Действие", icon: "field-icon-btn" },
];