import React, { useEffect, useState } from 'react';

import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './Map.scss'

const ITEM_TYPE = 'MapCategory';
interface MapCategory {
        id: number;
        name: string;
        order: number;
}
interface MapCategoryItemProps {
    MapCategory: MapCategory;
    index: number;
    moveMapCategory: (dragIndex: number, hoverIndex: number) => void;
    onDelete: (id: number) => void;
    onSave: (MapCategory: MapCategory) => void;
}

const MapCategoryItem: React.FC<MapCategoryItemProps> = ({ MapCategory, index, moveMapCategory, onDelete, onSave }) => {
    const [{ isDragging }, dragRef] = useDrag({
        type: ITEM_TYPE,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, dropRef] = useDrop({
        accept: ITEM_TYPE,
        hover: (item: { index: number }) => {
            if (item.index !== index) {
                moveMapCategory(item.index, index);
                item.index = index;
            }
        },
    });

    const [isEditing, setIsEditing] = useState(false);
    const [editedName, setEditedName] = useState(MapCategory.name);
     const [originalName, setOriginalName] = useState(MapCategory.name);

    const handleSave = () => {
        onSave({ ...MapCategory, name: editedName });
        setIsEditing(false);
    };
    const backEdit = () => {
        setEditedName(originalName); 
        setIsEditing(false);
    }
    const handleEditClick = () => {
        setOriginalName(MapCategory.name); 
        setIsEditing(true);
    };
    return (
        <>
        <li ref={(node) => dragRef(dropRef(node))}>
        <div className='order-type__item'>
            {isEditing ? (
                  <div className='order-type__edit'>
                  <div className="order-type__edit-top">
                      <p className="order-type__edit-title">Редактирование категории</p>
                      <button onClick={backEdit}  className="order-type__edit-back">
                      Отменить
                      </button>
                  </div>
                  <p className="order-type__edit-input__name">Название *</p>
                <input
                    value={editedName}
                    onChange={(e) => setEditedName(e.target.value)}
                />
                </div>
            ) : (
                <p className='order-type__name'>{MapCategory.name}</p>
            )}
                    {isEditing ? (
                        <button className='settings__change settings__change_save' onClick={handleSave}></button>
                    ) : (
                        <button className='settings__change settings__change_edit' onClick={handleEditClick}></button>
                    )}
                    <button className='settings__change settings__change_trash' onClick={() => onDelete(MapCategory.id)}></button>
                </div>
        </li>
        </>
    );
};


const MapCategoryList: React.FC = () => {

    const [MapCategorys, setMapCategorys] = useState<MapCategory[]>([
        { id: 1, name: 'Клиники', order: 1 },
        { id: 2, name: 'Области', order: 2 },
        { id: 3, name: 'Бригады', order: 3 },
    ]);
    const [newMapCategoryName, setNewMapCategoryName] = useState('');
    const handleAddMapCategory = () => {
        if (newMapCategoryName.trim()) {
            const newMapCategory: MapCategory = {
                id: MapCategorys.length + 1,
                name: newMapCategoryName,
                order: MapCategorys.length + 1,
            };
            setMapCategorys([...MapCategorys, newMapCategory]);
            setNewMapCategoryName('');
        }
    };

    const handleDeleteMapCategory = (id: number) => {
        setMapCategorys(MapCategorys.filter((MapCategory) => MapCategory.id !== id));
    };

    const handleSaveMapCategory = (MapCategory: MapCategory) => {
        setMapCategorys(
            MapCategorys.map((item) =>
                item.id === MapCategory.id ? { ...item, name: MapCategory.name } : item
            )
        );
    };

    const moveMapCategory = (dragIndex: number, hoverIndex: number) => {
        const reorderedMapCategorys = [...MapCategorys];
        const [draggedMapCategory] = reorderedMapCategorys.splice(dragIndex, 1);
        reorderedMapCategorys.splice(hoverIndex, 0, draggedMapCategory);
        setMapCategorys(reorderedMapCategorys);
    };

    return (
          
        <DndProvider backend={HTML5Backend}>
        <section className='settings'>
            <div className="container container-small">
                <div className="section-top">
                    <h1 className="section-title">
                    Настройки карты
                    </h1>
                    <div className="settings__btn">
                        <button className="standart-btn purple-btn">
                        Сохранить изменения
                        </button>
                        <button className="standart-btn opacity-btn">
                        Отменить
                        </button>
                    </div>
                </div>

                <div className='order-type settings__inner'>
                    <h2 className='section-title settings__inner-title'>
                    Категории
                    </h2>
                {/* Этот код потом раскомментировать
                {loading ? (
                       <div className='loading-icon'>
                            <div></div>
                            </div>
                ) : error ? (
                    <p className='order-type__error'>Ошибка: {error}</p>
                ) : ( */}
                    <ul className='order-type__list'>
                        {MapCategorys.map((MapCategory, index) => (
                            <MapCategoryItem
                                key={MapCategory.id}
                                MapCategory={MapCategory}
                                index={index}
                                moveMapCategory={moveMapCategory}
                                onDelete={handleDeleteMapCategory}
                                onSave={handleSaveMapCategory}
                            />
                        ))}
                    </ul>
                {/* )}*/}
                    <input className='standart-input'
                        value={newMapCategoryName}
                        onChange={(e) => setNewMapCategoryName(e.target.value)}
                    />
                      
                    <button className='order-type__btn' onClick={handleAddMapCategory}>
                    Добавить категорию
                    </button>
                    </div>
         
            </div>
            </section>
        </DndProvider>

    );
};

export default MapCategoryList;
