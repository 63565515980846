import React, { useState, useCallback, useEffect } from 'react';
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

interface ImageUploaderProps {
  initialImages?: string[];
}

function ImageUploader({ initialImages }: ImageUploaderProps) {
  const [selectedImages, setSelectedImages] = useState<string[]>(initialImages || []); // Инициализация с initialImages
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleSetSelectedImages = useCallback((newImages: string[]) => {
    setSelectedImages(prevImages => [...prevImages, ...newImages]);
  }, []);


  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      const newImageUrls: string[] = [];

      files.forEach(file => {
        const reader = new FileReader();

        reader.onload = (e) => {
          const target = e.target;
          if (target && target.result) {
             newImageUrls.push(target.result as string);
             if(newImageUrls.length === files.length){
               handleSetSelectedImages(newImageUrls)
             }
          }
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const handleRemoveImage = useCallback((indexToRemove: number) => {
    setSelectedImages(prevImages => prevImages.filter((_, index) => index !== indexToRemove));
  }, []);


  const handleDownloadImage = (imageUrl: string, index: number) => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = `image_${index + 1}.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <label className='image-uploader__label'><input type="file" multiple onChange={handleImageChange} accept="image/*" /><span>Загрузить файл</span></label>


      <div className='image-uploader__wrapper'>
        {selectedImages.map((imageUrl, index) => (
          <div key={index} className='image-uploader__image'>
            <img
              src={imageUrl}
              alt={`Preview ${index}`}
              onClick={() => Fancybox.show([{ src: imageUrl, }])} // Указываем imageUrl
            />
            <button
              className='image-uploader__image-delete'
              onClick={() => handleRemoveImage(index)}
            >

            </button>
            <button
               className='image-uploader__image-download'
              onClick={() => handleDownloadImage(imageUrl, index)}
            >

            </button>
          </div>
        ))}
      </div>

    </>
  );
}

export default ImageUploader;