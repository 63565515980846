import axiosInstance from './axiosConfig';
import {
    WorkOrder,
    CreateWorkOrderDto,
    UpdateWorkOrderDto,
} from '../redux/workOrder/types';

const workOrdersApi = {
    /**
     * Fetch all work orders.
     */
    fetchAllWorkOrders: async (): Promise<WorkOrder[]> => {
        const response = await axiosInstance.get('/workorders');
        return response.data;
    },

    /**
     * Fetch a single work order by ID.
     */
    fetchWorkOrderById: async (id: number): Promise<WorkOrder> => {
        const response = await axiosInstance.get(`/workorders/${id}`);
        return response.data;
    },

    /**
     * Create a new work order (based on a template).
     */
    createWorkOrder: async (data: CreateWorkOrderDto): Promise<WorkOrder> => {
        const response = await axiosInstance.post('/workorders', data);
        return response.data;
    },

    /**
     * Update an existing work order.
     */
    updateWorkOrder: async (
        id: number,
        data: UpdateWorkOrderDto
    ): Promise<WorkOrder> => {
        const response = await axiosInstance.patch(`/workorders/${id}`, data);
        return response.data;
    },

    /**
     * Delete a work order.
     */
    deleteWorkOrder: async (id: number): Promise<{ message: string }> => {
        const response = await axiosInstance.delete(`/workorders/${id}`);
        return response.data;
    },
};

export default workOrdersApi;
