// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../img/icons/help-circle.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-container {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin-left: 8px;
}
.dialog-container::before {
  display: block;
  content: "";
  width: 16px;
  position: absolute;
  pointer-events: none;
  inset: 0;
  height: 16px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center center no-repeat;
}

.dialog-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
}

.dialog-tooltip {
  position: absolute;
  top: -20px;
  z-index: 1000;
  min-width: 320px;
  max-width: 320px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 12px;
  font-size: 12px;
}

.dialog-tooltip.right {
  left: 24px;
}

.dialog-tooltip.left {
  right: 0;
}

.dialog-content > span {
  font-weight: 600;
  color: #344054;
  margin-bottom: 6px;
}
.dialog-content > p {
  color: #667085;
}`, "",{"version":3,"sources":["webpack://./src/components/Dialog/Dialog.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,qBAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;EACA,gBAAA;AACJ;AAAI;EACI,cAAA;EACA,WAAA;EACA,WAAA;EACA,kBAAA;EACA,oBAAA;EACA,QAAA;EACA,YAAA;EACA,2EAAA;AAER;;AAGA;EACI,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,UAAA;EACA,aAAA;EACA,gBAAA;EACA,gBAAA;EACA,yBAAA;EACA,kBAAA;EACA,wCAAA;EACA,aAAA;EACA,eAAA;AAAJ;;AAGA;EACI,UAAA;AAAJ;;AAGA;EACI,QAAA;AAAJ;;AAII;EACG,gBAAA;EACA,cAAA;EACA,kBAAA;AADP;AAGI;EACI,cAAA;AADR","sourcesContent":[".dialog-container {\n    position: relative;\n    display: inline-block;\n    width: 16px;\n    height: 16px;\n    flex-shrink: 0;\n    margin-left: 8px;\n    &::before {\n        display: block;\n        content: '';\n        width: 16px;\n        position: absolute;\n        pointer-events: none;\n        inset: 0;\n        height: 16px;\n        background: url(\"../../img/icons/help-circle.svg\") center center no-repeat;\n\n    }\n}\n\n.dialog-icon {\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 16px;\n    height: 16px;\n}\n\n.dialog-tooltip {\n    position: absolute;\n    top: -20px;\n    z-index: 1000;\n    min-width: 320px;\n    max-width: 320px;\n    background-color: #ffffff;\n    border-radius: 8px;\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);\n    padding: 12px;\n    font-size: 12px;\n}\n\n.dialog-tooltip.right {\n    left: 24px;\n}\n\n.dialog-tooltip.left {\n    right: 0;\n}\n\n.dialog-content {\n    &>span {\n       font-weight: 600;\n       color: #344054;\n       margin-bottom: 6px;\n    }\n    &>p {\n        color: #667085;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
