import axiosInstance from './axiosConfig';

// src/api/resolutionsApi.ts
export interface ResolutionData {
    name: string;
    noteRequired: boolean;
    type: 'SUCCESSFUL' | 'UNSUCCESSFUL'; // Backend expects uppercase
}

// Get all resolutions
export const fetchResolutions = async () => {
    const response = await axiosInstance.get('/resolutions');
    return response.data;
};

// Get a single resolution by ID
export const fetchResolutionById = async (id: number) => {
    const response = await axiosInstance.get(`/resolutions/${id}`);
    return response.data;
};

// Convert `type` to uppercase only in the API call
export const createResolution = async (data: ResolutionData) => {
    const response = await axiosInstance.post('/resolutions', {
        ...data,
        type: data.type.toUpperCase(), // Convert type to uppercase
    });
    return response.data;
};

export const updateResolution = async (id: number, data: ResolutionData) => {
    const response = await axiosInstance.patch(`/resolutions/${id}`, {
        ...data,
        type: data.type.toUpperCase(), // Convert type to uppercase
    });
    return response.data;
};

// Delete a resolution by ID
export const deleteResolution = async (id: number) => {
    const response = await axiosInstance.delete(`/resolutions/${id}`);
    return response.data;
};
