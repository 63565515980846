import React, { useState, useCallback, useMemo, ReactNode } from 'react';
import './FilterSidebar.scss';
import Accordion from '../Accordion/AccordionItem';

interface ListObject {
    items: string[];
    title?: string;
}

interface CheckboxListProps {
    listObjects?: ListObject[];
    name: string;
    showResetButton?: boolean;
    showSearchInput?: boolean;
    children?: ReactNode;
    ExclusiveCheckboxes?: boolean;
}

const CheckboxList: React.FC<CheckboxListProps> = ({
    listObjects,
    name,
    showResetButton = false,
    showSearchInput = false,
    children,
    ExclusiveCheckboxes = false,
}) => {
    const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>({});
    const [searchTerm, setSearchTerm] = useState('');

    const handleCheckboxChange = useCallback((index: string) => {
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [index]: !prevCheckedItems[index],
        }));
    }, []);

    const handleReset = useCallback(() => {
        setCheckedItems({});
    }, []);

    const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    }, []);

    const filteredListObjects = useMemo(() => {
        if (!listObjects) {
            return [];
        }
        if (!searchTerm) {
            return listObjects;
        }
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        return listObjects.map((listObject) => ({
            ...listObject,
            items: listObject.items.filter((item) =>
                item.toLowerCase().includes(lowerCaseSearchTerm)
            ),
        }));
    }, [listObjects, searchTerm]);

    const activeCheckboxCount = useMemo(() => {
        return Object.values(checkedItems).filter(Boolean).length;
    }, [checkedItems]);

    return (
        <div className={`sidebar__filter${activeCheckboxCount > 0 ? ` active` : ''} ${ExclusiveCheckboxes ? 'checkbox-parent' : ''}`}>
            <Accordion
                title={name}
                customContent={showResetButton && (
                    <button
                        className={`sidebar__filter-refresh all-refresh-refresh ${activeCheckboxCount > 0 ? 'active' : ''}`}
                        onClick={handleReset}
                        disabled={activeCheckboxCount === 0}
                    >
                        Сбросить {activeCheckboxCount > 0 ? `${activeCheckboxCount}` : ''}
                    </button>
                )}
            >
                {showSearchInput && (
                    <label className='sidebar__filter-search'>
                        <input
                            type="text"
                            placeholder="Поиск"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                    </label>
                )}
                {filteredListObjects && filteredListObjects.length > 0 && (
                    <ul className='sidebar__filter-list sidebar__filter-list_maxheight'>
                        {filteredListObjects.map((listObject, index) => (
                            <div key={index}>
                                <ul>
                                    {listObject.title && <li className='sidebar__filter-list__head'>{listObject.title}</li>}
                                    {listObject.items.map((item, itemIndex) => {
                                        const checkboxKey = `${index}-${itemIndex}`;
                                        return (
                                            <li key={checkboxKey}>
                                                <label className='custom-checkbox'>
                                                    <input
                                                        type="checkbox"
                                                        checked={!!checkedItems[checkboxKey]}
                                                        onChange={() => handleCheckboxChange(checkboxKey)}
                                                    />
                                                    <span></span>
                                                    {item}
                                                </label>
                                                
                                                {checkedItems[checkboxKey] && (
                                                    <button
                                                        className="sidebar__filter-apply"
                                                        onClick={() => {} /* Не делаем ничего по клику */}
                                                    >
                                                        Применить
                                                    </button>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        ))}
                    </ul>
                )}
                {children && children}
            </Accordion>
        </div>
    );
};

export default CheckboxList;
