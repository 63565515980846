import axiosInstance from './axiosConfig'; // Use axiosInstance from the config file

export const getWorkOrders = async () => {
    const response = await axiosInstance.get('/workorders');
    return response.data;
};

export const getWorkOrderById = async (id: number) => {
    const response = await axiosInstance.get(`/workorders/${id}`);
    return response.data;
};

export const createWorkOrder = async (data: { description: string; customer: string; address: string; latitude: number; longitude: number }) => {
    const response = await axiosInstance.post('/workorders', data);
    return response.data;
};

// src/api/workOrderApi.ts
export const updateWorkOrder = async (
    id: number,
    data: { description: string; customer: string; address: string; latitude: number; longitude: number }
) => {
    // Simply pass the data object directly without 'id'
    const response = await axiosInstance.put(`/workorders/${id}`, data);
    return response.data;
};

