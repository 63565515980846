import axiosInstance from './axiosConfig';
import {CreateLegalEntityPayload, UpdateLegalEntityPayload} from '../redux/legalEntity/legalEntitiesTypes';

// Fetch all legal entities
export const getLegalEntities = async () => {
    const response = await axiosInstance.get('/legal-entities');
    return response.data;
};

// Fetch a single legal entity by ID
export const getLegalEntityById = async (id: number) => {
    const response = await axiosInstance.get(`/legal-entities/${id}`);
    return response.data;
};

// Create a new legal entity
export const createLegalEntity = async (data: CreateLegalEntityPayload) => {
    const response = await axiosInstance.post('/legal-entities', data);
    return response.data;
};

// Update an existing legal entity
export const updateLegalEntity = async (id: number, data: UpdateLegalEntityPayload) => {
    const payload = Object.fromEntries(
        Object.entries(data).filter(([key, value]) => value !== undefined && value !== '')
    );
    const response = await axiosInstance.patch(`/legal-entities/${id}`, payload);
    return response.data;
};

// Delete a legal entity
export const deleteLegalEntity = async (id: number) => {
    const response = await axiosInstance.delete(`/legal-entities/${id}`);
    return response.data;
};
