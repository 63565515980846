import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAreasApi, createAreaApi, updateAreaApi, deleteAreaApi, reorderAreasApi } from '../../api/areaApi';
import { Area } from './areaTypes';

export const fetchOrderedAreas = createAsyncThunk<Area[]>(
    'area/fetchOrderedAreas',
    async (_, { rejectWithValue }) => {
        try {
            return await fetchAreasApi();
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Failed to fetch ordered areas');
        }
    }
);

export const createArea = createAsyncThunk<Area, { name: string }>(
    'area/createArea',
    async (area, { rejectWithValue }) => {
        try {
            return await createAreaApi(area);
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Failed to create area');
        }
    }
);

export const reorderAreas = createAsyncThunk<number[], number[]>(
    'area/reorderAreas',
    async (orderedIds, { rejectWithValue }) => {
        try {
            console.log("Dispatching orderedIds:", orderedIds); // ✅ Debugging
            await reorderAreasApi(orderedIds);
            return orderedIds;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Failed to reorder areas');
        }
    }
);

export const updateArea = createAsyncThunk<Area, { id: number; area: Partial<Area> }>(
    'area/updateArea',
    async ({ id, area }, { rejectWithValue }) => {
        try {
            return await updateAreaApi(id, area);
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Failed to update area');
        }
    }
);

export const deleteArea = createAsyncThunk<number, number>(
    'area/deleteArea',
    async (id, { rejectWithValue }) => {
        try {
            await deleteAreaApi(id);
            return id;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Failed to delete area');
        }
    }
);
