import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    fetchTemplatesThunk,
    fetchTemplateByIdThunk,
    createTemplateThunk,
    updateTemplateThunk,
    deleteTemplateThunk,
} from "./templateThunks";
import { Template } from "./types";

interface TemplateState {
    templates: Template[];
    selectedTemplate: Template | null;
    status: "idle" | "loading" | "succeeded" | "failed";
    error: string | null;
}

const initialState: TemplateState = {
    templates: [],
    selectedTemplate: null,
    status: "idle",
    error: null,
};

const templateSlice = createSlice({
    name: "templates",
    initialState,
    reducers: {
        clearTemplateError(state) {
            state.error = null;
        },
        clearSelectedTemplate(state) {
            state.selectedTemplate = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTemplatesThunk.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchTemplatesThunk.fulfilled, (state, action: PayloadAction<Template[]>) => {
                state.templates = action.payload;
                state.status = "succeeded";
            })
            .addCase(fetchTemplatesThunk.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(fetchTemplateByIdThunk.fulfilled, (state, action: PayloadAction<Template>) => {
                state.selectedTemplate = action.payload;
            })
            .addCase(createTemplateThunk.fulfilled, (state, action: PayloadAction<Template>) => {
                state.templates.push(action.payload);
            })
            .addCase(updateTemplateThunk.fulfilled, (state, action: PayloadAction<Template>) => {
                const index = state.templates.findIndex((t) => t.id === action.payload.id);
                if (index !== -1) {
                    state.templates[index] = action.payload;
                }
                if (state.selectedTemplate?.id === action.payload.id) {
                    state.selectedTemplate = action.payload;
                }
            })
            .addCase(deleteTemplateThunk.fulfilled, (state, action: PayloadAction<number>) => {
                state.templates = state.templates.filter((t) => t.id !== action.payload);
                if (state.selectedTemplate?.id === action.payload) {
                    state.selectedTemplate = null;
                }
            });
    },
});

export const { clearTemplateError, clearSelectedTemplate } = templateSlice.actions;
export default templateSlice.reducer;
