import React from 'react';
import TeamLocation from '../components/TeamLocation/TeamLocation';
import TeamsList from "../components/Teams/TeamsList"; // Adjusted import

export const Teams: React.FC = () => {
    return (
        <div>
            <TeamsList/>
            <TeamLocation />
        </div>
    );
};
