import React, { useState, useEffect, useRef } from 'react';
import './CustomSelect.scss';

// Типы данных
export interface Element {
  id: string;
  name: string;
  type: string;
  filter: string; // filterId
  isCompound?: boolean;
  compoundItems?: Element[];
}

export interface Filter {
  id: string;
  name: string;
}

interface SearchableMultiSelectProps {
  elements: Element[];
  filters: Filter[];
  onChange?: (selectedElements: Element[]) => void;
  initialSelectedIds?: string[]; 
}

const SearchableMultiSelect: React.FC<SearchableMultiSelectProps> = ({ elements, filters, initialSelectedIds = [], onChange }) => {
    const [selectedElements, setSelectedElements] = useState<Element[]>(() => {
        if (!initialSelectedIds || initialSelectedIds.length === 0) {

        }
        return elements.filter(element => initialSelectedIds.includes(element.id));
    });

  const [isOpen, setIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState<string | null>(null); 
  const [searchTerm, setSearchTerm] = useState<string>('');
  const selectRef = useRef<HTMLDivElement>(null); 
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node) && isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const filterElements = (element: Element) => {
    if (activeFilter === null) {
      return true;
    }
    return element.filter === activeFilter;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setIsOpen(true);
  };

  const handleFilterClick = (filterId: string | null) => {
    setActiveFilter(filterId);
    setSearchTerm('');
  };

  const handleElementClick = (element: Element) => {
    const isSelected = selectedElements.some((selected) => selected.id === element.id);
    if (isSelected) {
      setSelectedElements(selectedElements.filter((selected) => selected.id !== element.id));
    } else {
      setSelectedElements([...selectedElements, element]);
    }
  };

  const handleRemoveSelectedElement = (element: Element) => {
    setSelectedElements(selectedElements.filter((selected) => selected.id !== element.id));
  };
  const handleCompoundItemDelete = (itemId: string) => {
    const updatedSelectedElements = selectedElements.map((el) => {
      if (el.isCompound && el.compoundItems) {
        const updatedCompoundItems = el.compoundItems.filter(item => item.id !== itemId);
        if (updatedCompoundItems.length !== el.compoundItems.length) {
          return { ...el, compoundItems: updatedCompoundItems };
        }
      }
      return el;
    });

    const finalSelectedElements = updatedSelectedElements.filter((el) => {
      if (el.isCompound && el.compoundItems) {
        return el.compoundItems.length > 0;
      }
      return true; 
    });

    setSelectedElements(finalSelectedElements);
  };
  // Рендер-функции
  const renderSelectedElements = () => {
    return (
      <div className="custom-select-search__items">
        {selectedElements.map((element) => (
          <React.Fragment key={element.id}>
            {element.isCompound ? (
              <div className='custom-select-search__group'>
                <div className="custom-select-search__group-title">
                  {element.name}
                  <button onClick={() => handleRemoveSelectedElement(element)}></button>
                </div>
                <div className='custom-select-search__group-items'>
                  {element.compoundItems?.map((item) => (
                    <div key={item.id} className='custom-select-search__group-item'>
                      {item.name}
                      <button onClick={() => handleCompoundItemDelete(item.id)}></button>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className='custom-select-search__item'>
                {element.name}
                <button className="delete-item" onClick={() => handleRemoveSelectedElement(element)}></button>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };

  const renderFilterButtons = () => {
    return (
      <div className="filters">
        <FilterButton
          filter={{ id: 'all', name: 'Все' }}
          isActive={activeFilter === null}
          onClick={() => handleFilterClick(null)}
        />
        {filters.map((filter) => (
          <FilterButton
            key={filter.id}
            filter={filter}
            isActive={activeFilter === filter.id}
            onClick={() => handleFilterClick(filter.id)}
          />
        ))}
      </div>
    );
  };

  const renderElementList = () => {
    const filteredElements = elements
      .filter(filterElements)
      .filter((element) => element.name.toLowerCase().includes(searchTerm.toLowerCase()));
    const groupedElements = filters.map((filter) => ({
      filter,
      elements: filteredElements.filter((element) => element.filter === filter.id),
    }));

    return (
      <ul className="custom-select-search__list">
        {groupedElements.map((group) => (
          <React.Fragment key={group.filter.id}>
            {group.elements.length > 0 && (
              <>
                <li className="custom-select-search__list-filter__name">{group.filter.name}</li>
                {group.elements.map((element) => (
                  <ElementListItem
                    key={element.id}
                    element={element}
                    onClick={() => handleElementClick(element)}
                  />
                ))}
              </>
            )}
          </React.Fragment>
        ))}
      </ul>
    );
  };

  useEffect(() => {
    if (onChange) {
      onChange(selectedElements);
    }
  }, [selectedElements, onChange]);

  const handleAddButtonClick = () => {
    setIsOpen(true);
    setSearchTerm('');
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className="searchable-multi-select custom-select-search" ref={selectRef}>
      {renderSelectedElements()}
      {selectedElements.length > 0 && !isOpen ? (
      <button className="custom-select-search__add-more" onClick={handleAddButtonClick}>
            Выбрать ещё
          </button>
        ) : (
      <div className='custom-select-search__input'>
          <input
            type="text"
            placeholder="Поиск..."
            onChange={handleInputChange}
            value={searchTerm}
            onClick={() => setIsOpen(true)}
            ref={inputRef}
          />
           </div>
        )}
      {isOpen && (
        <div className="custom-select-search__dropdown">
          {renderFilterButtons()}
          {renderElementList()}
        </div>
      )}
    </div>
  );
};

const ElementListItem: React.FC<{ element: Element; onClick: () => void }> = ({ element, onClick }) => {
  return (
    <li onClick={onClick} className={`${element.type === 'brigada' ? 'custom-select-search__brigada' : ''}`}>
         {element.type === 'brigada' && element.isCompound && element.compoundItems ? (
                <div>
                    <span>{element.name}</span>
                    <p>
                    {element.compoundItems
                            .filter(item => item.type !== 'brigada')
                            .map(item => item.name)
                            .join(', ')}
                    </p>
                </div>
            ) : (
                    element.name
                )}
    </li>
  );
};

const FilterButton: React.FC<{ filter: Filter; isActive: boolean; onClick: () => void }> = ({ filter, isActive, onClick }) => {
  return (
    <button
      className={`filter-button ${isActive ? 'active' : ''}`}
      onClick={onClick}
    >
      {filter.name}
    </button>
  );
};

export default SearchableMultiSelect;