import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTeams, createTeam, updateTeam, deleteTeam, getTeamById } from '../../api/teamApi';
import { CreateTeamPayload, UpdateTeamPayload } from './teamTypes';

// Fetch all teams
export const fetchTeams = createAsyncThunk('teams/fetchTeams', async () => {
    return await getTeams();
});

// Fetch a team by ID
export const fetchTeamById = createAsyncThunk('teams/fetchTeamById', async (id: number) => {
    return await getTeamById(id);
});

// Create a new team
export const createNewTeam = createAsyncThunk('teams/createTeam', async (team: CreateTeamPayload) => {
    return await createTeam(team);
});

// Update an existing team
export const updateExistingTeam = createAsyncThunk('teams/updateTeam', async ({ id, data }: {
    id: number;
    data: UpdateTeamPayload;
}) => {
    return await updateTeam(id, data);
});

// Delete a team
export const deleteTeamById = createAsyncThunk('teams/deleteTeam', async (id: number) => {
    return await deleteTeam(id);
});
