import React, { useState, useRef, useCallback } from 'react';
import './FileUpload.scss';

interface UploadedFile {
    file: File;
    progress: number;
    isUploading: boolean;
    isUploaded: boolean;
    id: string;
}

const FileUpload: React.FC = () => {
    const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [error, setError] = useState<string | null>(null);


    const generateUniqueId = () => {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }

    const handleDrop = useCallback((event: React.DragEvent) => {
        event.preventDefault();
         setError(null);
        const files = Array.from(event.dataTransfer.files);
        handleFiles(files)
    }, []);

    const handleDragOver = (event: React.DragEvent) => {
        event.preventDefault();
    };

    const handleFileSelect = () => {
        fileInputRef.current?.click();
    };

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setError(null)
        const files = Array.from(event.target.files || []);
        handleFiles(files)
    };

      const isValidFileType = (file: File): boolean => {
          const allowedTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];
          return allowedTypes.includes(file.type) || file.name.toLowerCase().endsWith('.xlsx') || file.name.toLowerCase().endsWith('.csv') ;
      };

    const handleFiles = async (files: File[]) => {
        const validFiles = files.filter(file => isValidFileType(file));
        if (validFiles.length !== files.length) {
            setError('Можно загружать только файлы в формате .xlsx или .csv');
           return;
        }

        for(const file of validFiles) {
            const newFile: UploadedFile = {
                file,
                progress: 0,
                isUploading: true,
                isUploaded: false,
                id: generateUniqueId(),
            };
            setUploadedFiles((prevFiles) => [...prevFiles, newFile]);
            uploadFile(newFile);
        }
    };

     const uploadFile = async (uploadingFile: UploadedFile) => {
        const {file, id} = uploadingFile;
        const formData = new FormData();
        formData.append('file', file);

        try {
             const xhr = new XMLHttpRequest();
            xhr.open('POST', '/upload');

            xhr.upload.onprogress = (event) => {
                  const progress = event.lengthComputable ? (event.loaded / event.total) * 100 : 0;
                setUploadedFiles((prevFiles) =>
                     prevFiles.map(f => f.id === id ? {...f, progress} : f)
                );
            };

            xhr.onload = () => {
                 if (xhr.status >= 200 && xhr.status < 300) {
                      setUploadedFiles((prevFiles) =>
                          prevFiles.map(f => f.id === id ? {...f, isUploaded: true, isUploading: false } : f)
                      );
                } else {
                    setUploadedFiles((prevFiles) =>
                        prevFiles.map(f => f.id === id ? {...f, isUploading: false} : f)
                    );
                  console.error('Upload failed', xhr.statusText);
                }
             };


            xhr.onerror = () => {
                setUploadedFiles((prevFiles) =>
                    prevFiles.map(f => f.id === id ? {...f, isUploading: false} : f)
                );
                 console.error('Upload failed');
            };
           xhr.send(formData);

        } catch (error) {
           console.error('Error during upload:', error);
        }
    };


    const handleRemoveFile = (id: string) => {
        setUploadedFiles((prevFiles) => prevFiles.filter(f => f.id !== id));
    };

    const handleCancelUploads = () => {
         // Тут должна быть логика отмены для каждого файла (если это необходимо)
          setUploadedFiles([]);
          setError(null);
    };

    const formatFileSize = (size: number): string => {
        if (size < 1024) {
            return `${size} B`;
        } else if (size < 1024 * 1024) {
            return `${(size / 1024).toFixed(2)} KB`;
        } else {
            return `${(size / (1024 * 1024)).toFixed(2)} MB`;
        }
    };


    return (
        <>
             <div
                className="file-upload-container"
                 onDrop={handleDrop}
                 onDragOver={handleDragOver}
             >
                <div className="upload-area">
                    <button className="upload-button" onClick={handleFileSelect}>
                        Кликните сюда
                    </button>
                    или перетащите файл <br />
                    XLSX или CSV
                    <input
                        type="file"
                        multiple
                        ref={fileInputRef}
                        onChange={handleFileInputChange}
                        style={{ display: 'none' }}
                    />
                 </div>
                 {error && <div className="upload-error">{error}</div>}
            </div>
              <div className="upload-list">
                {uploadedFiles.map((uploadedFile) => (
                <div className="upload-item" key={uploadedFile.id}>
                    <div className="upload-item__content">
                            <span className="upload-item__name">{uploadedFile.file.name}</span>
                            <span className="upload-item__size">({formatFileSize(uploadedFile.file.size)})</span>
                            {uploadedFile.progress === 100 ? (
                                    <span className="upload-item__status upload-item__status--success"></span>
                                ) : ''}
                                 <div className="upload-item__progress">
                                        <div className="upload-item__progress-bar" style={{ width: `${uploadedFile.progress}%`}}></div>
                                          <span className="upload-item__progress-percent">{uploadedFile.progress.toFixed(0)}%</span>
                                   </div>
                               
                        </div>
                    <button className="upload-item__remove" onClick={() => handleRemoveFile(uploadedFile.id)}>
                        
                    </button>
                </div>
            ))}
          </div>
            {uploadedFiles.length > 0 &&
            <div className="upload__btns">
                    <button className="standart-btn opacity-btn upload__btn" onClick={handleCancelUploads}>
                         Отменить
                    </button>
                    <button className="standart-btn purple-btn upload__btn">
                    Загрузить
                    </button>
              </div>
           }
       </>
    );
};

export default FileUpload;