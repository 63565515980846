import React, { useState, useEffect, useCallback } from "react";
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import qrImage from '../../img/check/qr.jpg'; 
import './QRCode.scss'
import PriceInput from "../InputPrice/InputPrice";
interface QRCodeGeneratorProps {
    initialQRCode?: string; 
    initialEmail?: string; 
    initialSelect?: string; 
    initialCost?: string; 
}

const QRCodeGenerator: React.FC<QRCodeGeneratorProps> = ({
    initialQRCode,
    initialEmail,
    initialSelect,
    initialCost
}) => {
    
    const [email, setEmail] = useState(initialEmail ?? "");
    const [recipient, setRecipient] = useState(initialSelect ?? "");
    const [cost, setCost] = useState(initialCost ?? "");

    const [isQRCodeVisible, setIsQRCodeVisible] = useState(!!initialQRCode);
    const [qrCodeImage, setQrCodeImage] = useState<string | null>(initialQRCode || null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const [paymentStatus, setPaymentStatus] = useState<string | null>(null);
    const [buttonClass, setButtonClass] = useState<string>("");
    const [isCheckingPayment, setIsCheckingPayment] = useState(false); 
    useEffect(() => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isEmailValid = emailRegex.test(email);
        const isRecipientValid = recipient !== "Не указано";
        const isCostValid = cost.trim() !== "" && cost !== "";

        setIsButtonDisabled(!(isEmailValid && isRecipientValid && isCostValid));
    }, [email, recipient, cost]);

    const generateQRCode = useCallback(() => {
        if (isButtonDisabled) return;

        setIsButtonDisabled(true);

        setTimeout(() => {
            setQrCodeImage(qrImage);
            setIsQRCodeVisible(true);
            setIsButtonDisabled(false);
        }, 1500);
    }, [isButtonDisabled]);

    const handleDownload = () => {
        if (!qrCodeImage) return;

        const link = document.createElement("a");
        link.href = qrCodeImage;
        link.download = "qr_code.png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const checkPaymentStatus = () => {
        if (isCheckingPayment) return;
        setIsCheckingPayment(true);
        setPaymentStatus("Проверяем...");
        setButtonClass("checked");

        setTimeout(() => {
            const statuses = [
                { text: "Успешно", className: "ok" },
                { text: "Ожидает", className: "waiting" },
                { text: "Неуспешно", className: "error" }
            ];
            
            const randomStatus = statuses[Math.floor(Math.random() * statuses.length)];
            setPaymentStatus(randomStatus.text);
            setButtonClass(randomStatus.className);
            setTimeout(() => {
                setPaymentStatus(null);
                setButtonClass("");
                setIsCheckingPayment(false);
            }, 3000);
        }, 3000);
    };

    return (
        <>
            {/* Email */}
            <div className="jobs-popup__content-item">
                <p className="jobs-popup__content-item__title">Email для чека</p>
                <input
                    type="text"
                    placeholder="mail@bk.ru"
                    className="standart-input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>

            {/* Получатель оплаты */}
            <div className="jobs-popup__content-item">
                <p className="jobs-popup__content-item__title">Кто получатель оплаты (юр. лицо)</p>
                <select
                    className="standart-select"
                    value={recipient}
                    onChange={(e) => setRecipient(e.target.value)}
                >
                    <option value="Не указано">Выберите организацию</option>
                    <option value="Вариант 1">Вариант 1</option>
                    <option value="Вариант 2">Вариант 2</option>
                    <option value="Вариант 3">Вариант 3</option>
                </select>
            </div>

            {/* Стоимость */}
            <div className="jobs-popup__content-item">
                <p className="jobs-popup__content-item__title">Общая стоимость услуг</p>
                
                <PriceInput
                    initialValue={cost}
                    onChange={(value) => setCost(value)}
                />
            </div>

            {/* Кнопка генерации */}
            <button className="standart-btn jobs-popup__qr-code" disabled={isButtonDisabled} onClick={generateQRCode}>
                Сгенерировать QR-код
            </button>

            {/* Отображение QR-кода */}
            {isQRCodeVisible && qrCodeImage && (
                <div className="jobs-popup__qr-code-container">
                    <div className="jobs-popup__qr-code__img">
                        <img src={qrCodeImage} alt="QR Code" onClick={() => Fancybox.show([{ src: qrCodeImage }])} />
                    </div>
                    <div className="jobs-popup__qr-code__btns">
                        <button className="standart-btn opacity-btn jobs-popup__qr-code__btn-download" onClick={handleDownload}>
                            Скачать файл
                        </button>
                        <button className={`standart-btn purple-btn jobs-popup__qr-code__btn-check ${buttonClass}`} onClick={checkPaymentStatus} disabled={isCheckingPayment} >
                            {paymentStatus || "Проверить оплату"}
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default QRCodeGenerator;
