import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { fetchResolutionsThunk } from "../../redux/resolutions/resolutionsThunks";
import { ResolutionType, Resolution as TemplateResolution } from "../../redux/templates/types";
import Dialog from "../Dialog/Dialog";

interface ResolutionsProps {
    selectedResolutions: TemplateResolution[];
    onUpdateResolutions: (resolutions: TemplateResolution[]) => void;
    readOnly?: boolean;
}

export const Resolutions: React.FC<ResolutionsProps> = ({
                                                            selectedResolutions,
                                                            onUpdateResolutions,
                                                            readOnly = false,
                                                        }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { resolutions, status } = useSelector((state: RootState) => state.resolutions);

    useEffect(() => {
        if (status === "idle") {
            dispatch(fetchResolutionsThunk());
        }
    }, [dispatch, status]);

    const toggleResolution = (id: number) => {
        const isSelected = selectedResolutions.some(res => res.resolutionId === id);
        const resolution = resolutions.find(res => res.id === id);
        if (!resolution) return;

        const updated: TemplateResolution[] = isSelected
            ? selectedResolutions.filter(res => res.resolutionId !== id)
            : [
                ...selectedResolutions,
                {
                    resolutionId: resolution.id,
                    type: resolution.type as ResolutionType,
                    name: resolution.name,
                },
            ];

        onUpdateResolutions(updated);
    };

    const renderResolutionCheckbox = (res: typeof resolutions[number]) => (
        <label key={res.id} className="custom-checkbox">
            <input
                type="checkbox"
                checked={selectedResolutions.some(r => r.resolutionId === res.id)}
                onChange={() => toggleResolution(res.id)}
                disabled={readOnly}
            />
            <span></span>
            {res.name}{" "}
            <em style={{ fontSize: "0.85em", opacity: 0.6 }}>
                ({res.type === ResolutionType.SUCCESSFUL ? "Успешная" : "Неуспешная"})
            </em>
        </label>
    );

    return (
        <div className="template-resolution">
            <div className="template-resolution__title">
                Возможные резолюции
                <Dialog>
                    <p>Исполнитель выбирает резолюцию, когда завершает наряд.</p>
                </Dialog>
            </div>

            <div className="template-resolution__block">
                <p className="template-resolution__item-title">Успешные резолюции</p>
                {resolutions
                    .filter((res) => res.type === ResolutionType.SUCCESSFUL)
                    .map(renderResolutionCheckbox)}
            </div>

            <div className="template-resolution__block">
                <p className="template-resolution__item-title">Неуспешные резолюции</p>
                {resolutions
                    .filter((res) => res.type === ResolutionType.UNSUCCESSFUL)
                    .map(renderResolutionCheckbox)}
            </div>
        </div>
    );
};
