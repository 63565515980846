import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../redux/store';
import { fetchOrderedOrderTypes, createOrderType, updateOrderType, deleteOrderType, reorderOrderTypes } from '../../redux/orderType/orderTypeThunks';
import { OrderType } from '../../redux/orderType/types';
import { setOrderTypesOrder } from '../../redux/orderType/orderTypeSlice';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './OrderTypeList.scss'

const ITEM_TYPE = 'OrderType';

interface OrderTypeItemProps {
    orderType: OrderType;
    index: number;
    moveOrderType: (dragIndex: number, hoverIndex: number) => void;
    onDelete: (id: number) => void;
    onSave: (orderType: OrderType) => void;
}

const OrderTypeItem: React.FC<OrderTypeItemProps> = ({ orderType, index, moveOrderType, onDelete, onSave }) => {
    const [{ isDragging }, dragRef] = useDrag({
        type: ITEM_TYPE,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, dropRef] = useDrop({
        accept: ITEM_TYPE,
        hover: (item: { index: number }) => {
            if (item.index !== index) {
                moveOrderType(item.index, index);
                item.index = index;
            }
        },
    });

    const [isEditing, setIsEditing] = useState(false);
    const [editedName, setEditedName] = useState(orderType.name);
     const [originalName, setOriginalName] = useState(orderType.name);

    const handleSave = () => {
        onSave({ ...orderType, name: editedName });
        setIsEditing(false);
    };
    const backEdit = () => {
        setEditedName(originalName); 
        setIsEditing(false);
    }
    const handleEditClick = () => {
        setOriginalName(orderType.name); 
        setIsEditing(true);
    };
    return (
        <>
        <li ref={(node) => dragRef(dropRef(node))}>
        <div className='order-type__item'>
            {isEditing ? (
                  <div className='order-type__edit'>
                  <div className="order-type__edit-top">
                      <p className="order-type__edit-title">Редактирование участка</p>
                      <button onClick={backEdit}  className="order-type__edit-back">
                      Отменить
                      </button>
                  </div>
                  <p className="order-type__edit-input__name">Название *</p>
                <input
                    value={editedName}
                    onChange={(e) => setEditedName(e.target.value)}
                />
                </div>
            ) : (
                <p className='order-type__name'>{orderType.name}</p>
            )}
                    {isEditing ? (
                        <button className='settings__change settings__change_save' onClick={handleSave}></button>
                    ) : (
                        <button className='settings__change settings__change_edit' onClick={handleEditClick}></button>
                    )}
                    <button className='settings__change settings__change_trash' onClick={() => onDelete(orderType.id)}></button>
                </div>
        </li>
        </>
    );
};


const OrderTypeList: React.FC = () => {

    const [orderTypes, setOrderTypes] = useState<OrderType[]>([
        { id: 1, name: 'Первичный выезд', order: 1 },
        { id: 2, name: 'Повторный выезд', order: 2 },
        { id: 3, name: 'Есть в базе', order: 3 },
        { id: 4, name: 'Партнерский выезд', order: 4 },
        { id: 5, name: 'Стационар доп. продажа', order: 5 },
        { id: 6, name: 'Стационар продление', order: 6 },
    ]);
    const [newOrderTypeName, setNewOrderTypeName] = useState('');
    const handleAddOrderType = () => {
        if (newOrderTypeName.trim()) {
            const newOrderType: OrderType = {
                id: orderTypes.length + 1,
                name: newOrderTypeName,
                order: orderTypes.length + 1,
            };
            setOrderTypes([...orderTypes, newOrderType]);
            setNewOrderTypeName('');
        }
    };

    const handleDeleteOrderType = (id: number) => {
        setOrderTypes(orderTypes.filter((orderType) => orderType.id !== id));
    };

    const handleSaveOrderType = (orderType: OrderType) => {
        setOrderTypes(
            orderTypes.map((item) =>
                item.id === orderType.id ? { ...item, name: orderType.name } : item
            )
        );
    };

    const moveOrderType = (dragIndex: number, hoverIndex: number) => {
        const reorderedOrderTypes = [...orderTypes];
        const [draggedOrderType] = reorderedOrderTypes.splice(dragIndex, 1);
        reorderedOrderTypes.splice(hoverIndex, 0, draggedOrderType);
        setOrderTypes(reorderedOrderTypes);
    };


    /*  
    const dispatch = useDispatch<AppDispatch>();
    const { orderTypes, loading, error } = useSelector((state: RootState) => state.orderType);
    const [newOrderTypeName, setNewOrderTypeName] = useState('');
     useEffect(() => {
         dispatch(fetchOrderedOrderTypes());
     }, [dispatch]);
    
    const handleAddOrderType = () => {
        if (newOrderTypeName.trim()) {
            dispatch(createOrderType({ name: newOrderTypeName }));
            setNewOrderTypeName('');
        }
    };

    const handleDeleteOrderType = (id: number) => {
        dispatch(deleteOrderType(id));
    };

    const handleSaveOrderType = (orderType: OrderType) => {
        dispatch(updateOrderType({ id: orderType.id, orderType: { name: orderType.name } }));
    };

    const moveOrderType = (dragIndex: number, hoverIndex: number) => {
        const reorderedOrderTypes = [...orderTypes];
        const [draggedOrderType] = reorderedOrderTypes.splice(dragIndex, 1);
        reorderedOrderTypes.splice(hoverIndex, 0, draggedOrderType);
        dispatch(setOrderTypesOrder(reorderedOrderTypes));
        const orderedIds = reorderedOrderTypes.map((orderType) => orderType.id);
        dispatch(reorderOrderTypes(orderedIds));
    };
*/
    return (
          
        <DndProvider backend={HTML5Backend}>
        <section className='settings'>
            <div className="container container-small">
                <div className="section-top">
                    <h1 className="section-title">
                    Настройки
                    </h1>
                    <div className="settings__btn">
                        <button className="standart-btn purple-btn">
                        Сохранить изменения
                        </button>
                        <button className="standart-btn opacity-btn">
                        Отменить
                        </button>
                    </div>
                </div>

                <div className='order-type settings__inner'>
                    <h2 className='section-title settings__inner-title'>
                    Типы нарядов
                    </h2>
                {/* Этот код потом раскомментировать
                {loading ? (
                       <div className='loading-icon'>
                            <div></div>
                            </div>
                ) : error ? (
                    <p className='order-type__error'>Ошибка: {error}</p>
                ) : ( */}
                    <ul className='order-type__list'>
                        {orderTypes.map((orderType, index) => (
                            <OrderTypeItem
                                key={orderType.id}
                                orderType={orderType}
                                index={index}
                                moveOrderType={moveOrderType}
                                onDelete={handleDeleteOrderType}
                                onSave={handleSaveOrderType}
                            />
                        ))}
                    </ul>
                {/* )}*/}
                    <input className='standart-input'
                        value={newOrderTypeName}
                        onChange={(e) => setNewOrderTypeName(e.target.value)}
                    />
                      
                    <button className='order-type__btn' onClick={handleAddOrderType}>
                    Добавить тип наряда
                    </button>
                    </div>
         
            </div>
            </section>
        </DndProvider>

    );
};

export default OrderTypeList;
