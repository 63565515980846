import React from 'react';
import ResolutionsList from "../components/Resolutions/ResolutionsList";

export const Resolutions = () => {
    return (
        <>
            <ResolutionsList />
        </>
    );
};
