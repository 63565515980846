import React, { useState, useCallback } from 'react';

interface GeneratedFile {
    fileName: string;
    progress: number;
    size: number;
    id: string;
    isError: boolean; 
}

const FileUpload: React.FC = () => {
    const [generatedFiles, setGeneratedFiles] = useState<GeneratedFile[]>([]);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const generateUniqueId = () => {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    };

    const openPopup = () => setPopupOpen(true);

    const closePopup = () => {
        setGeneratedFiles([]);  
        setPopupOpen(false);   
    };

    // Генерация успешного файла
    const generateXMLFile = (isError: boolean) => {
        const fileName = isError ? 'error_generated_file.xml' : 'generated_file.xml';
        const xmlContent = isError
            ? '<?xml version="1.0" encoding="UTF-8"?>\n<error>\n  <message>Выгрузка неуспешна. Можно выгрузить не больше 100000 нарядов</message>\n</error>'
            : '<?xml version="1.0" encoding="UTF-8"?>\n<files>\n  <file>\n    <name>Test File</name>\n    <size>12345</size>\n  </file>\n</files>';

        const blob = new Blob([xmlContent], { type: 'application/xml' });

        const newFile: GeneratedFile = {
            fileName,
            progress: 0,
            size: blob.size,
            id: generateUniqueId(),
            isError: isError, 
        };

        setGeneratedFiles((prevFiles) => [...prevFiles, newFile]);
        if (isError) {
            setError('Выгрузка неуспешна. Можно выгрузить не больше 100000 нарядов');
            return;
        }
        simulateFileDownload(newFile, blob);
    };

    // Имитируем процесс загрузки файла
    const simulateFileDownload = (file: GeneratedFile, blob: Blob) => {
        let progress = 0;
        const interval = setInterval(() => {
            if (progress < 100) {
                progress += 10;
                setGeneratedFiles((prevFiles) =>
                    prevFiles.map(f => f.id === file.id ? { ...f, progress } : f)
                );
            } else {
                clearInterval(interval);
            }
        }, 200); 
    };

    const handleRemoveFile = (id: string) => {
        setGeneratedFiles((prevFiles) => prevFiles.filter(f => f.id !== id));
    };

    const formatFileSize = (size: number): string => {
        if (size < 1024) {
            return `${size} B`;
        } else if (size < 1024 * 1024) {
            return `${(size / 1024).toFixed(2)} KB`;
        } else {
            return `${(size / (1024 * 1024)).toFixed(2)} MB`;
        }
    };
    const handleDownloadFile = (file: GeneratedFile) => {
        const fileContent = '<?xml version="1.0" encoding="UTF-8"?>\n<files>\n  <file>\n    <name>Test File</name>\n    <size>12345</size>\n  </file>\n</files>';
        const blob = new Blob([fileContent], { type: 'application/xml' });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = file.fileName;
        link.click();
    };


    return (
        <>
            <button
                className="standart-btn excel-popover__btn"
                onClick={() => { openPopup(); generateXMLFile(false); }}
            >
                Выгрузить все поля нарядов
            </button>
            <button
                className="standart-btn excel-popover__btn"
                onClick={() => { openPopup(); generateXMLFile(true); }}
            >
                Выгрузить только колонки таблицы
            </button>

            {/* Всплывающее окно */}
            {isPopupOpen && (
                <div className="popup" data-target='save-all-jobs'>
                    <div className="popup__body">
                        <div className="popup__content">
                            <p className='popup__title'>Экспорт таблицы</p>
                            <button className="close-popup" onClick={closePopup}></button>
                            <div className="upload-list">
                                {generatedFiles.map((generatedFile) => (
                                    <div className={`upload-item ${generatedFile.isError ? 'error' : ''}`} key={generatedFile.id}>
                                        <div className="upload-item__content">
                                            {generatedFile.isError ? (
                                                <>
                                                    <div className="upload-item__error-message">
                                                        Выгрузка неуспешна. Можно выгрузить не больше 100000 нарядов
                                                    </div>
                                                </>
                                            ) : ''}

                                            <span className="upload-item__name">{generatedFile.fileName}</span>
                                            {!generatedFile.isError ? (
                                                <>
                                                    <span className="upload-item__size">({formatFileSize(generatedFile.size)})</span>
                                                    <div className="upload-item__progress">
                                                        <div className="upload-item__progress-bar" style={{ width: `${generatedFile.progress}%` }}></div>
                                                        <span className="upload-item__progress-percent">{generatedFile.progress}%</span>
                                                    </div>
                                                </>
                                            ) : ''}
                                        </div>

                                        {!generatedFile.isError ? (
                                            <>
                                                {generatedFile.progress === 100 ? (
                                                    <button
                                                        className="upload-item__save"
                                                        onClick={() => handleDownloadFile(generatedFile)}
                                                    >
                                                        
                                                    </button>
                                                ) : (
                                                    <button className="upload-item__save" disabled>
                                                        
                                                    </button>
                                                )}
                                            </>
                                        ) : (
                                            <button
                                                className="upload-item__remove"
                                                onClick={() => handleRemoveFile(generatedFile.id)}
                                            >
                                                
                                            </button>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FileUpload;
