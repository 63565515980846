import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          height: '44px', // Задает высоту контейнера TextField
          '& .MuiInputBase-root': {
            height: '44px', // Задает высоту InputBase (включает поля Input, Select)
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#6941C6', //  Ваш основной цвет
      light: '#8d6adf', //  Светлый оттенок (опционально)
      dark: '#1976d2',  //  Темный оттенок (опционально)
      contrastText: '#fff', //  Цвет текста, контрастный основному цвету
    },
    secondary: {
      main: '#f50057', //  Ваш вторичный цвет
      light: '#ff4081',
      dark: '#c51162',
      contrastText: '#fff',
    },
    // Добавьте другие цвета, которые хотите изменить
    background: {
      default: '#fafafa', //  Цвет фона по умолчанию
      paper: '#fff',    //  Цвет фона для компонентов Paper
    },
    text: {
      primary: '#333', //  Основной цвет текста
      secondary: '#333', // Вторичный цвет текста
    },
    error: {
        main: '#d32f2f'
    },
    warning: {
        main: '#ed6c02'
    },
    info: {
        main: '#2979ff'
    },
    success: {
        main: '#2e7d32'
    },
  },
 
});

export default theme; // Export the theme