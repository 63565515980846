import React, { useState, ReactNode, cloneElement, Children, isValidElement, ReactElement, useEffect } from 'react';
import './Tabs.scss';

interface TabProps {
  label: string;
  children: ReactNode;
}

interface TabsProps {
  children: ReactNode;
}

const Tab: React.FC<TabProps> = ({ label, children }) => {
  return (
    <>
     {children}
    </>
  );
};

const Tabs: React.FC<TabsProps> = ({ children }) => {
  const [activeTab, setActiveTab] = useState<string | null>(null);

  const tabs = React.Children.toArray(children)
    .filter((child): child is ReactElement<TabProps> =>
      React.isValidElement(child) && (child as any).type === Tab
    ).map((child) => {
      const props = (child as ReactElement<TabProps>).props;
      return {
        label: props.label,
        content: child.props.children,
      };
    });
  useEffect(() => {
    if (tabs.length > 0 && activeTab === null) {
      setActiveTab(tabs[0].label);
    }
  }, [tabs, activeTab]);


  const handleTabClick = (label: string) => {
    setActiveTab(label);
  };

  return (
    <div className="tabs">
      <div className="tab-buttons">
        {tabs.map((tab) => (
          <button
            key={tab.label}
            className={`tab-button ${activeTab === tab.label ? 'active' : ''}`}
            onClick={() => handleTabClick(tab.label)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="tab-content">
        {tabs.map((tab) => (
          <div
            key={tab.label}
            className={`tab-panel ${activeTab === tab.label ? 'active' : ''}`}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export { Tabs, Tab };