import React, { useRef, useEffect } from 'react';

interface ExclusiveCheckboxesProps {
    children: React.ReactNode;
    defaultCheckedDataValue?: string; // Добавлен необязательный prop
}

const ExclusiveCheckboxes: React.FC<ExclusiveCheckboxesProps> = ({ children, defaultCheckedDataValue }) => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const wrapper = wrapperRef.current;

        if (wrapper) {
            const items = wrapper.querySelectorAll<HTMLInputElement>("input[type='checkbox']");
            const updateRefreshButtonState = () => {
                const checkRefreshParent = wrapper.closest(".checkbox-parent");
                if (checkRefreshParent) {
                    const checkRefresh = checkRefreshParent.querySelector<HTMLButtonElement>(".sidebar__filter-refresh");
                    const hasChecked = Array.from(items).some(item => item.checked);
                    if (checkRefresh) {
                        if (hasChecked) {
                            checkRefresh.classList.add("active");
                            checkRefresh.removeAttribute('disabled');
                            if (checkRefreshParent) {
                                checkRefreshParent.classList.add('active');
                            }

                        } else {
                            checkRefresh.classList.remove("active");
                            checkRefresh.setAttribute('disabled', '');
                            if (checkRefreshParent) {
                                checkRefreshParent.classList.remove('active');
                            }
                        }
                    }
                }
            };
            const clearCheckboxes = () => {
                items.forEach(item => {
                    item.checked = false;
                    item.dataset.checked = 'false';
                });
                updateRefreshButtonState();
            };

            const handleRefreshClick = (event: Event) => {
                event.preventDefault();
                clearCheckboxes();
            };
            items.forEach(item => {
                item.dataset.checked = 'false';
                // Если это указанный defaultCheckedDataValue, то ставим checked
                if (defaultCheckedDataValue && item.dataset.value === defaultCheckedDataValue) {
                    item.dataset.checked = 'true';
                    item.checked = true;
                }
                item.addEventListener('click', () => {
                    const isCurrentlyChecked = item.dataset.checked === 'true';
                    items.forEach(elem => {
                        elem.dataset.checked = 'false';
                        elem.checked = false;
                    });

                    if (isCurrentlyChecked) {
                    } else {
                        item.dataset.checked = 'true';
                        item.checked = true;
                    }
                    updateRefreshButtonState();
                });
            });

            const checkRefreshParent = wrapper.closest(".checkbox-parent");
            if (checkRefreshParent) {
                const checkRefresh = checkRefreshParent.querySelector<HTMLButtonElement>(".sidebar__filter-refresh");
                if (checkRefresh) {
                    checkRefresh.addEventListener('click', handleRefreshClick);
                }
            }
            updateRefreshButtonState();
            return () => {
                items.forEach(item => {
                    const newItem = item.cloneNode(true);
                    item.parentNode?.replaceChild(newItem, item);
                });
                const checkRefreshParent = wrapper.closest(".checkbox-parent");
                if (checkRefreshParent) {
                    const checkRefresh = checkRefreshParent.querySelector<HTMLButtonElement>(".sidebar__filter-refresh");
                    if (checkRefresh) {
                        const newRefresh = checkRefresh.cloneNode(true);
                        checkRefresh.parentNode?.replaceChild(newRefresh, checkRefresh);
                    }
                }


            };
        }
    }, [defaultCheckedDataValue]); 

    return (
        <div className="checkbox-wrapper" ref={wrapperRef}>
            {children}
        </div>
    );
};

export default ExclusiveCheckboxes;