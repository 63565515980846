// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-8fykvn-MuiMultiSectionDigitalClock-root .MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/DatePicker/DatePicker.scss"],"names":[],"mappings":"AACI;EACI,aAAA;AAAR","sourcesContent":[".css-8fykvn-MuiMultiSectionDigitalClock-root .MuiMultiSectionDigitalClockSection-root {\n    &::-webkit-scrollbar {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
