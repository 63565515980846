import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { fetchWorkOrders } from '../../redux/workOrder/workOrderThunks';
import { RootState, AppDispatch } from '../../redux/store';

const WorkOrders: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const workOrders = useSelector((state: RootState) => state.workOrder.workOrders);

    useEffect(() => {
        dispatch(fetchWorkOrders()); // Fetch all work orders when component mounts
    }, [dispatch]);

    const handleCreateWorkOrder = () => {
        navigate('/workorders/new');
    };

    const handleRowClick = (id: number) => {
        navigate(`/workorders/${id}`);
    };

    return (
        <div>
            <Button variant="contained" color="primary" onClick={handleCreateWorkOrder}>
                Create New Work Order
            </Button>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Customer</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {workOrders.map((order) => (
                        <TableRow key={order.id} onClick={() => handleRowClick(order.id)} style={{ cursor: 'pointer' }}>
                            <TableCell>{order.id}</TableCell>
                            <TableCell>{order.description}</TableCell>
                            <TableCell>{order.customer}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default WorkOrders;
