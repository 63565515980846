import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WorkOrder } from './types';
import {
    fetchAllWorkOrdersThunk,
    fetchWorkOrderByIdThunk,
    createWorkOrderThunk,
    updateWorkOrderThunk,
    deleteWorkOrderThunk,
} from './workOrderThunks';

interface WorkOrdersState {
    workOrders: WorkOrder[];
    currentWorkOrder: WorkOrder | null;
    loading: boolean;
    error: string | null;
}

const initialState: WorkOrdersState = {
    workOrders: [],
    currentWorkOrder: null,
    loading: false,
    error: null,
};

const workOrdersSlice = createSlice({
    name: 'workOrders',
    initialState,
    reducers: {
        clearCurrentWorkOrder(state) {
            state.currentWorkOrder = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllWorkOrdersThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllWorkOrdersThunk.fulfilled, (state, action: PayloadAction<WorkOrder[]>) => {
                state.workOrders = action.payload;
                state.loading = false;
            })
            .addCase(fetchAllWorkOrdersThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(fetchWorkOrderByIdThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchWorkOrderByIdThunk.fulfilled, (state, action: PayloadAction<WorkOrder>) => {
                state.currentWorkOrder = action.payload;
                state.loading = false;
            })
            .addCase(fetchWorkOrderByIdThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(createWorkOrderThunk.fulfilled, (state, action: PayloadAction<WorkOrder>) => {
                state.workOrders.push(action.payload);
            })
            .addCase(updateWorkOrderThunk.fulfilled, (state, action: PayloadAction<WorkOrder>) => {
                const index = state.workOrders.findIndex(order => order.id === action.payload.id);
                if (index !== -1) {
                    state.workOrders[index] = action.payload;
                }
            })
            .addCase(deleteWorkOrderThunk.fulfilled, (state, action: PayloadAction<number>) => {
                state.workOrders = state.workOrders.filter(order => order.id !== action.payload);
            });
    },
});

export const { clearCurrentWorkOrder } = workOrdersSlice.actions;
export default workOrdersSlice.reducer;
