// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiPickersPopper-root.MuiPopper-root .MuiDateRangeCalendar-root > div:not(.MuiDateRangeCalendar-monthContainer):first-child {
  display: none;
}

.MuiDateRangeCalendar-root > div:not(.MuiDateRangeCalendar-monthContainer):first-child {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/DatePicker/DateRange.scss"],"names":[],"mappings":"AACI;EACI,aAAA;AAAR;;AAGA;EACI,aAAA;AAAJ","sourcesContent":[".MuiPickersPopper-root.MuiPopper-root {\n    & .MuiDateRangeCalendar-root > div:not(.MuiDateRangeCalendar-monthContainer):first-child {\n        display: none;\n    }\n}\n.MuiDateRangeCalendar-root > div:not(.MuiDateRangeCalendar-monthContainer):first-child {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
