import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../api/axiosConfig'; // Axios instance
import { io, Socket } from 'socket.io-client';

// Global socket to maintain connection across the app
let socket: Socket | null = null;

// Helper function to initialize WebSocket for `EXECUTOR_ADMINISTRATOR`
const initializeWebSocket = (access_token: string) => {
    socket = io('https://api.alcovrach.tech', {
        query: { token: access_token },
        transports: ['websocket'],
        withCredentials: true,
        reconnectionAttempts: 5, // Retry a few times before failing
    });

    socket.on('connect', () => {
        console.log('WebSocket connected for location tracking');
    });

    socket.on('disconnect', (reason) => {
        console.log('WebSocket disconnected:', reason);
        if (reason === 'io server disconnect') {
            // Server explicitly disconnected; attempt reconnect
            socket?.connect();
        }
    });

    navigator.geolocation.watchPosition(
        (position) => {
            const { latitude, longitude } = position.coords;
            socket?.emit('sendLocation', { latitude, longitude });
        },
        (error) => console.error('Error fetching location:', error),
        { enableHighAccuracy: true, maximumAge: 0, timeout: 5000 }
    );
};

// Login Thunk with WebSocket initialization for `EXECUTOR_ADMINISTRATOR`
export const loginThunk = createAsyncThunk(
    'auth/login',
    async ({ email, password, rememberMe }: { email: string; password: string; rememberMe: boolean }) => {
        const response = await axiosInstance.post('/auth/login', { email, password, rememberMe });
        const { access_token, user } = response.data;

        if (rememberMe) {
            localStorage.setItem('token', access_token);
        } else {
            sessionStorage.setItem('token', access_token);
        }

        if (user.role === 'EXECUTOR_ADMINISTRATOR') {
            initializeWebSocket(access_token);
        }

        return { token: access_token, user };
    }
);

// Logout Thunk with WebSocket disconnection
export const logoutThunk = createAsyncThunk('auth/logout', async () => {
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');

    if (socket) {
        socket.io.opts.query = { ...socket.io.opts.query, logout: 'true' }; // Signal explicit logout
        socket.disconnect();
        socket = null;
        console.log('WebSocket disconnected on logout');
    }
});
