import { createAsyncThunk } from '@reduxjs/toolkit';
import workOrdersApi from '../../api/workOrderApi';
import { CreateWorkOrderDto, UpdateWorkOrderDto } from './types';

// Fetch all Work Orders
export const fetchAllWorkOrdersThunk = createAsyncThunk(
    'workOrders/fetchAll',
    async (_, { rejectWithValue }) => {
        try {
            return await workOrdersApi.fetchAllWorkOrders();
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to fetch Work Orders.');
        }
    }
);

// Fetch a Work Order by ID
export const fetchWorkOrderByIdThunk = createAsyncThunk(
    'workOrders/fetchById',
    async (id: number, { rejectWithValue }) => {
        try {
            return await workOrdersApi.fetchWorkOrderById(id);
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to fetch Work Order by ID.');
        }
    }
);

// Create a new Work Order
export const createWorkOrderThunk = createAsyncThunk(
    'workOrders/create',
    async (data: CreateWorkOrderDto, { rejectWithValue }) => {
        try {
            return await workOrdersApi.createWorkOrder(data);
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to create Work Order.');
        }
    }
);

// Update a Work Order
export const updateWorkOrderThunk = createAsyncThunk(
    'workOrders/update',
    async (
        { id, data }: { id: number; data: UpdateWorkOrderDto },
        { rejectWithValue }
    ) => {
        try {
            return await workOrdersApi.updateWorkOrder(id, data);
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to update Work Order.');
        }
    }
);

// Delete a Work Order
export const deleteWorkOrderThunk = createAsyncThunk(
    'workOrders/delete',
    async (id: number, { rejectWithValue }) => {
        try {
            await workOrdersApi.deleteWorkOrder(id);
            return id;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to delete Work Order.');
        }
    }
);
