import { createSlice } from '@reduxjs/toolkit';
import { fetchTeams, fetchTeamById, createNewTeam, updateExistingTeam, deleteTeamById } from './teamThunks';

export interface Team {
    id: number;
    name: string;
    leader: { id: number; fullName: string };
    members: { id: number; fullName: string }[];
    area: { id: number; name: string };
}

interface TeamState {
    teams: Team[];
    selectedTeam: Team | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: TeamState = {
    teams: [],
    selectedTeam: null,
    status: 'idle',
    error: null,
};

const teamSlice = createSlice({
    name: 'teams',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTeams.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTeams.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.teams = action.payload;
            })
            .addCase(fetchTeams.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Failed to fetch teams';
            })
            .addCase(fetchTeamById.fulfilled, (state, action) => {
                state.selectedTeam = action.payload;
            })
            .addCase(createNewTeam.fulfilled, (state, action) => {
                state.teams.push(action.payload);
            })
            .addCase(updateExistingTeam.fulfilled, (state, action) => {
                const index = state.teams.findIndex((team) => team.id === action.payload.id);
                if (index !== -1) {
                    state.teams[index] = action.payload;
                }
            })
            .addCase(deleteTeamById.fulfilled, (state, action) => {
                state.teams = state.teams.filter((team) => team.id !== action.meta.arg);
            });
    },
});

export default teamSlice.reducer;
