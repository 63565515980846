import React, { useState, useRef, useEffect } from 'react';
import { YMaps, Map, Placemark, Polygon, ZoomControl, FullscreenControl } from 'react-yandex-maps';
import MapPin from '../../img/map/map-pin.png';
import Users from '../../img/map/users.svg';
import SecondPin from '../../img/icons/document.svg';
// Интерфейсы для данных
interface Clinic {
    id: string;
    type: 'clinic';
    name: string;
    coordinates: [number, number]; 
    icon?: string; 
    text?: string; 
}

interface Brigade {
    id: string;
    type: 'brigade';
    name: string;
    coordinates: [number, number];
    text?: string; 
}

interface Area {
    id: string;
    type: 'area';
    name: string;
    coordinates: [number, number][]; 
    color?: string; 
    text?: string; 
}

type MapItem = Clinic | Brigade | Area;

interface CheckboxState {
    clinics: boolean;
    brigades: boolean;
    areas: boolean;
}
const MapComponent: React.FC = () => {
    const [checkboxes, setCheckboxes] = useState<CheckboxState>({
        clinics: false,
        brigades: false,
        areas: false,
    });

    const [mapItems, setMapItems] = useState<MapItem[]>([]);
    const [selectedItem, setSelectedItem] = useState<string | null>(null); 
    const mapRef = useRef<any>(null); 


    const [mapState, setMapState] = useState({
        center: [55.75, 37.57] as [number, number],
        zoom: 9,
    });

    const clinics: Clinic[] = [
        { id: 'clinic-1', type: 'clinic', name: 'Клиника', coordinates: [55.751244, 37.618423], icon: SecondPin, text: 'Тут какой-то текст про Москву или клинику' },
        { id: 'clinic-2', type: 'clinic', name: 'Гармония', coordinates: [47.222109, 39.718813], icon: MapPin, text: 'Тут какой-то текст'   },
        { id: 'clinic-3', type: 'clinic', name: 'Санкт-Петербург', coordinates: [59.938784, 30.314997], icon: MapPin, text: 'Тут какой-то текст'   },
        { id: 'clinic-4', type: 'clinic', name: 'Сызрань', coordinates: [53.155669, 48.474611], icon: MapPin, text: 'Тут какой-то текст'   },
        { id: 'clinic-5', type: 'clinic', name: 'Краснодар', coordinates: [45.035470, 38.975313], icon: MapPin, text: 'Тут какой-то текст'   },
        { id: 'clinic-6', type: 'clinic', name: 'Севастополь', coordinates: [44.616474, 33.525138], icon: MapPin, text: 'Тут какой-то текст'   },
        { id: 'clinic-7', type: 'clinic', name: 'Челябинск', coordinates: [55.159902, 61.402554], icon: MapPin, text: 'Тут какой-то текст'   },
        { id: 'clinic-8', type: 'clinic', name: 'Сочи', coordinates: [43.585472, 39.723098], icon: MapPin, text: 'Тут какой-то текст'  },
    ];

    const brigades: Brigade[] = [
        { id: 'brigade-1', type: 'brigade', name: 'Бригада 1', coordinates: [52.755814, 37.617614],  text: 'Тут какой-то текст'   },
        { id: 'brigade-2', type: 'brigade', name: 'Бригада 2', coordinates: [51.750014, 31.610014] },
        { id: 'brigade-3', type: 'brigade', name: 'Бригада 3', coordinates: [53.750014, 31.610014] },
        { id: 'brigade-4', type: 'brigade', name: 'Бригада 4', coordinates: [51.750014, 32.610014] },
        { id: 'brigade-5', type: 'brigade', name: 'Бригада 5', coordinates: [53.750014, 33.610014] },
        { id: 'brigade-6', type: 'brigade', name: 'Бригада 6', coordinates: [47.750014, 34.610014] },
        { id: 'brigade-7', type: 'brigade', name: 'Бригада 7', coordinates: [48.750014, 35.610014] },
        { id: 'brigade-8', type: 'brigade', name: 'Бригада 8', coordinates: [51.750014, 36.610014] },
    ];

    const areas: Area[] = [
        {
            id: 'area-1',
            type: 'area',
            name: 'Ростовская область',
            coordinates: [
                [47.31088330025887, 39.05155627539012],
                [46.85122674150802, 39.620098755858855],
                [46.892665129900635, 40.4028746347651],
                [47.25107304972674, 40.48801867773386],
                [47.697992395918234, 39.883770630858855],
                [47.31088330025887, 39.05155627539012],
            ],
            color: '#f970664b',
        },
        {
            id: 'area-2',
            type: 'area',
            name: 'Краснодарский край',
            coordinates: [
                [46.04914834220946, 38.19080858042088],
                [45.43375770860704, 37.464784578571134],
                [44.9053059618664, 37.52520938325864],
                [44.39949219119252, 38.53595157075864],
                [43.79779488728975, 39.711488680133634],
                [44.06773750532288, 41.26148729135838],
                [45.00671521140286, 41.288953111670885],
                [45.895940738208395, 40.6572392444834],
                [46.04914834220946, 38.19080858042088],
            ],
            color: '#f970664b',
        },
    ];
    useEffect(() => {
        const newMapItems: MapItem[] = [];

        if (checkboxes.clinics) {
            newMapItems.push(...clinics);
        }
        if (checkboxes.brigades) {
            newMapItems.push(...brigades);
        }
        if (checkboxes.areas) {
            newMapItems.push(...areas);
        }
        setMapItems(newMapItems);
    }, [checkboxes]);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setCheckboxes({ ...checkboxes, [name]: checked });
    };
    const handleItemClick = (itemId: string, itemType: MapItem['type'], coordinates?: [number, number] | [number, number][]) => {
        setSelectedItem(itemId);
    };
    const handleMapBoundsChange = (event: any) => {
        const newCenter = event.get('newCenter');
        const newZoom = event.get('newZoom');
    
        // ✅ Если panToItem() уже изменил центр, не обновляем state
        if (
            JSON.stringify(newCenter) === JSON.stringify(mapState.center) &&
            newZoom === mapState.zoom
        ) {
            return;
        }
    
        setMapState({ center: newCenter, zoom: newZoom });
    };

    
    const renderPlacemarks = () => {
        return mapItems.map((item) => {
            if (item.type === 'clinic' || item.type === 'brigade') {
                const iconSrc = (item.type === 'clinic' ? (item.icon || MapPin) : Users);
    
                // Добавляем защиту на случай, если не загружены нужные элементы
                return (
                    <Placemark
                        key={item.id}
                        geometry={item.coordinates}
                        options={{
                            iconLayout: 'default#imageWithContent',
                            iconImageHref: iconSrc, // Указываем иконку
                            iconImageSize: [18, 18],
                            iconImageOffset: [0, 0],
                            balloonCloseButton: true,
                            hideIconOnBalloonOpen: false,
                            iconContentLayout: window.ymaps?.templateLayoutFactory.createClass(
                                `<div class="custom-placemark ${item.type === 'clinic' ? 'custom-placemark-clinic' : 'custom-placemark-brigade'}">&nbsp;</div>`
                            ),
                        }}
                        properties={{
                            balloonContentHeader: ``,
                            balloonContentBody: `
                                <div class='custom-ymaps-baloon'>
                                    <img src="${iconSrc}" class="balloon-img ${item.type === 'clinic' ? 'custom-img-clinic' : 'custom-img-brigade'}" width="30" height="auto" />
                                    <strong>${item.name}</strong>
                                    ${item.text ? `<p>${item.text}</p>` : ''}
                                </div>`,
                        }}
                    />
                );
            }
            return null;
        });
    };
    
    
    const renderPolygons = () => {
        return mapItems.map((item) => {
            if (item.type === 'area') {
                return (
                    <Polygon
                        key={item.id}
                        geometry={[item.coordinates]} 
                        options={{
                            fillColor: item.color,
                            strokeColor: '#D92D20', 
                            strokeWidth: 4,
                            opacity: 0.7,
                        }}
                        onClick={() => handleItemClick(item.id, item.type, item.coordinates)} 
                    />
                );
            }
            return null;
        });
    };

    const panToItem = (itemId: string) => {
        if (!mapRef.current) {
            console.error("mapRef.current is not available");
            return;
        }
    
        const selectedMapItem = mapItems.find(item => item.id === itemId);
        if (!selectedMapItem) {
            console.error("No matching item found!");
            return;
        }
    
        let targetCoordinates: [number, number] | undefined;
    
        if (selectedMapItem.type === 'clinic' || selectedMapItem.type === 'brigade') {
            targetCoordinates = selectedMapItem.coordinates;
        }
    
        if (selectedMapItem.type === 'area' && Array.isArray(selectedMapItem.coordinates)) {
            targetCoordinates = selectedMapItem.coordinates[0]; 
        }
    
        if (targetCoordinates) {
            const currentZoom = mapState.zoom; 
            
            mapRef.current.panTo(targetCoordinates, { duration: 500 }).then(() => {
                setMapState(prev => ({
                    ...prev,
                    center: targetCoordinates!,
              
                }));
            });
    
            setTimeout(() => setSelectedItem(itemId), 200);
        } else {
            console.error("No valid coordinates found!");
        }
    };
    
    
    return (
        <div className="map">
            <div className="map__content">
                <div className="map__top">
                    <p className="map__top-title">Фильтры по категориям:</p>
                    <label className='custom-checkbox'>
                        <input
                            type="checkbox"
                            name="clinics"
                            checked={checkboxes.clinics}
                            onChange={handleCheckboxChange}
                        />
                        <span></span>
                        Клиники
                    </label>
                    <label className='custom-checkbox'>
                        <input
                            type="checkbox"
                            name="brigades"
                            checked={checkboxes.brigades}
                            onChange={handleCheckboxChange}
                        />
                        <span></span>
                        Бригады
                    </label>
                    <label className='custom-checkbox'>
                        <input
                            type="checkbox"
                            name="areas"
                            checked={checkboxes.areas}
                            onChange={handleCheckboxChange}
                        />
                        <span></span>
                        Области
                    </label>
                </div>
                <ul className="map__list">
                    {mapItems.map((item) => (
                        <li
                            key={item.id}
                            className={`${item.type === 'area' ? 'map__area' : ''}${item.type === 'clinic' ? 'map__clinic' : ''}${item.type === 'brigade' ? 'map__brigade' : ''}${selectedItem === item.id ? ' active' : ''}`}
                            onClick={() => {
                                handleItemClick(item.id, item.type,
                                    item.type === 'area' ? item.coordinates : item.coordinates);
                                panToItem(item.id); 
                            }}
                        >
                            {item.type === 'clinic' && (
                                <>
                                 {item.icon && <img src={item.icon} className="map__clinic-icon" />}
                                </>
                            )}
                             {item.type === 'brigade' && (
                                <>
                                <img src={Users} className="map__brigade-icon" />
                                </>

                                    )}
                            <div>
                            <p>{item.name}</p>
                            {item.type === 'area' && (
                                <>
                                <span>{item.coordinates[0]}</span>
                                </>
                            )}
                            {(item.type === 'brigade' || item.type === 'clinic') && (
                            <span>{item.text}</span>
                            )}
                            </div>
                           
                        </li>
                    ))}
                </ul>
            </div>

            {/* Map */}
            <div className="map-container">
                <YMaps query={{ apikey: 'ВАШ_API_КЛЮЧ' }}>
                    <Map
                        state={{ center: mapState.center, zoom: mapState.zoom }}
                        width="100%"
                        height="100%"
                        instanceRef={(instance) => {
                            mapRef.current = instance;
                        }}
                        modules={ [ 'geoObject.addon.balloon', 'geoObject.addon.hint', 'templateLayoutFactory', 'layout.ImageWithContent' ] }
                        onBoundsChange={handleMapBoundsChange} 
                   
                    
                    >
                        {renderPlacemarks()}
                        {renderPolygons()}
                        <ZoomControl options={{ float: 'right' }} />
                        <FullscreenControl options={{ float: 'left' }} />
                    </Map>
                </YMaps>
            </div>
        </div>
    );
};

// Перевод категорий
const categoryTranslations: { [key: string]: string } = {
    clinic: 'Клиника',
    brigade: 'Бригада',
    area: 'Область',
};

export default MapComponent;