import { createSlice } from '@reduxjs/toolkit';
import { fetchAreas, createNewArea } from './areaThunks';

export interface Area {
    id: number;
    name: string;
}

interface AreaState {
    areas: Area[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: AreaState = {
    areas: [],
    status: 'idle',
    error: null,
};

const areaSlice = createSlice({
    name: 'areas',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAreas.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAreas.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.areas = action.payload;
            })
            .addCase(fetchAreas.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Failed to fetch areas';
            })
            .addCase(createNewArea.fulfilled, (state, action) => {
                state.areas.push(action.payload);
            });
    },
});

export default areaSlice.reducer;
