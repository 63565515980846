import React, { useState, useEffect, useCallback } from 'react';
import './Jobs.scss'
import Search from './Search/Search';
import '../../styles/global.scss';
import BasicDateTimePicker from '../DatePicker/DateTimePicker';
import TimePicker from '../DatePicker/TimePicker';
import ExclusiveCheckboxes from '../ExclusiveCheckboxes/ExclusiveCheckboxes';
import Accordion from '../Accordion/AccordionItem';
import Dialog from '../Dialog/Dialog';
import LocationTracker from '../TeamLocation/LocationTracker';
import AddContact from '../AddContact/AddContact';
import ImageUploader from '../ImageUploader/ImageUploader';
import CustomSelect, { Filter, Element } from '../Select/CustomSelect';
import Medecine, { FilterMedecine, ElementMedecine } from "../Medecine/Medecine";
import Danger from '../../img/icons/danger.svg'; 
import QRCode from "../QRCode/QRCode";
import PriceInput from "../InputPrice/InputPrice";
import MapSearch from '../Map/MapSearch'; 
export default function Createjobs() {
      const [selectedElements, setSelectedElements] = useState<Element[]>([]);
    
      const elements: Element[] = [
        { id: '1', name: 'Григорий', type: 'item', filter: 'job' },
        { id: '2', name: 'Марк', type: 'item', filter: 'job' },
        { id: '3', name: 'Твен', type: 'item', filter: 'job' },
        { id: '4', name: 'Маркс', type: 'item', filter: 'job' },
        {
            id: '5',
            name: 'Ростов 1',
            type: 'brigada',
            filter: 'brigade',
            isCompound: true,
            compoundItems: [
                { id: '5-1', name: 'Ростов 1', type: 'brigada', filter: 'brigade' }, 
                { id: '5-2', name: 'Павел', type: 'item', filter: 'job' },  
                { id: '5-3', name: 'Ибрагим', type: 'item', filter: 'job' },  
            ],
        },
        {
            id: '6',
            name: 'Анадырь 1',
            type: 'brigada',
            filter: 'brigade',
            isCompound: true,
            compoundItems: [
                { id: '6-1', name: 'Анадырь 1', type: 'brigada', filter: 'brigade' }, 
                { id: '6-2', name: 'Павел 2', type: 'item', filter: 'job' }, 
                { id: '6-3', name: 'Михаил', type: 'item', filter: 'job' }, 
            ],
        },
        { id: '7', name: 'Григорий 2', type: 'item', filter: 'job' },
        { id: '8', name: 'Марк 2', type: 'item', filter: 'job' },
        { id: '10', name: 'Твен 2', type: 'item', filter: 'job' },
        { id: '11', name: 'Маркс 2', type: 'item', filter: 'job' },
        {
          id: '12',
          name: 'Ростов 2',
          type: 'brigada',
          filter: 'brigade',
          isCompound: true,
          compoundItems: [
              { id: '12-1', name: 'Ростов 2', type: 'brigada', filter: 'brigade' }, 
              { id: '12-2', name: 'Карен', type: 'item', filter: 'job' },  
              { id: '12-3', name: 'Алексей', type: 'item', filter: 'job' },  
              { id: '12-4', name: 'Григорий', type: 'item', filter: 'job' },  
              { id: '12-5', name: 'Афанасий', type: 'item', filter: 'job' },  
          ],
      },
    ];
      const filtersSelect: Filter[] = [
          { id: 'job', name: 'Исполнитель' },
          { id: 'brigade', name: 'Бригада' },
      ];
    

const ElementMedecine: ElementMedecine[] = [
    { id: '1', name: 'NaCl 0,9% 250мл, фл' },
    { id: '2', name: 'Gepato Pro 60 капсул'},
    { id: '3', name: 'Адреналин, амп',  filter: 'А-Ж' },
    { id: '4', name: 'Амитриптилин, амп',  filter: 'А-Ж' },
    { id: '5', name: 'Зактварени', filter: 'З-Н' },
    { id: '6', name: 'Ормарин', filter: 'О-У' },
    { id: '7', name: 'Ятирин',  filter: 'Ф-Я' },

];
  const filterMedecine: FilterMedecine[] = [
      { id: 'А-Ж', name: 'А-Ж' },
      { id: 'З-Н', name: 'З-Н' },
      { id: 'О-У', name: 'О-У' },
      { id: 'Ф-Я', name: 'Ф-Я' },
  ];
  const [MedecineItems, setMedecineItems] = useState<ElementMedecine[]>([]);
     const [mapVisible, setMapVisible] = useState(false);
    
        const toggleMap = () => {
            setMapVisible(prevVisible => !prevVisible);
        };

    const [email, setEmail] = useState('');
    const [recipient, setRecipient] = useState('Не указано');
    const [cost, setCost] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    useEffect(() => {
        const isEmailValid = emailRegex.test(email);
        const isRecipientValid = recipient !== 'Не указано';
        const isCostValid = cost.trim() !== '';
        if (isEmailValid && isRecipientValid && isCostValid) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [email, recipient, cost]);

    const [allData, setAllData] = useState<string[]>([
        'Андрей П.П. +7 900 999 99 00',
        'Павел Дуров +7 900 999 99 00',
        'Павел Пупкин +7 900 999 99 00',
        'По ф +7 900 999 99 00',
    ]);

    const [allData2, setAllData2] = useState<string[]>([
        'Лечение алкоголизма',
        'Вывод из запоя',
        'Капельница',
        'Капельница 2',
    ]);
    const [allData3, setAllData3] = useState<string[]>([
        'Иван Иванов +7 900 888 88 88',
        'Петр Петров +7 900 777 77 77',
        'Сергей Сергеев +7 900 666 66 66',
        'Алексей Алексеев +7 900 555 55 55',
    ]);

    const [searchResults, setSearchResults] = useState<string[]>([]);

    const handleSearch = (query: string, array: string[]) => {
        const results = array.filter(item =>
            item.toLowerCase().includes(query.toLowerCase())
        );
        setSearchResults(results);
    };

    const [data, setData] = useState([
      { text: "Павел", filter: 'Сотрудники' },
      { text: "Игорь", filter: 'Сотрудники' },
      { text: "Джеймс", filter: 'Сотрудники' },
      {
        text: 'Бригада 1',
        filter: 'Бригады',
        members: [
          { text: 'Павел Бригада', filter: 'Сотрудники' },
          { text: 'Виктор Бригада', filter: 'Сотрудники' },
        ],
      },
      {
        text: 'Бригада 2',
        filter: 'Бригады',
        members: [
          { text: 'Павел Бригада', filter: 'Сотрудники' },
          { text: 'Виктор Бригада', filter: 'Сотрудники' },
        ],
      },
      { text: "Елена", filter: 'Сотрудники' },
      { text: "Светлана", filter: 'Сотрудники' },
      { text: "Артур", filter: 'Сотрудники' },
      { text: "Григорий", filter: 'Сотрудники' },
      { text: "Елизавета", filter: 'Сотрудники' },
      { text: "Мария", filter: 'Сотрудники' },
      { text: "Ксения", filter: 'Сотрудники' },
    ]);
    const [text, setText] = useState<string>('');
     const [selectedValue, setSelectedValue] = useState<string>('');
     const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(event.target.value);
      };
    const handleChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedValue(event.target.value); 
    };
    const [inputValueID, setInputValueID] = useState<string>('');
    const handleChangeInputID = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValueID(event.target.value);
    };
     const [selectedValueType, setSelectedValueType] = useState('');
              const handleChangeSelectType = (event: React.ChangeEvent<HTMLSelectElement>) => {
                setSelectedValueType(event.target.value); 
              };

              const cancelButton = document.getElementById('cancel-button');
              const popupOverlay = document.getElementById('popup-close');
              const closePopupButton = document.getElementById('close-popup-button');
              
              if (cancelButton && popupOverlay && closePopupButton) {
                const openPopup = () => {
                  popupOverlay.style.display = 'flex';
                };
              
                // Функция для закрытия попапа
                const closePopup = () => {
                  popupOverlay.style.display = 'none';
                };
              
                // Назначаем обработчики событий
                cancelButton.addEventListener('click', openPopup);
                closePopupButton.addEventListener('click', closePopup);
              } else {
                console.error('Не удалось найти один или несколько элементов DOM');
              }
    return (
        <>
            <div className="jobs-popup">
                <div className="jobs-popup__top">
                    <h2 className='popup__title'>Новый наряд</h2>
                    <div className="jobs-popup__top-btns">
                        <button className="jobs-popup__top-btn standart-btn purple-btn">
                            Сохранить
                        </button>
                        <button className="jobs-popup__top-btn standart-btn opacity-btn" id='cancel-button'>
                             Закрыть
                        </button>
                        <div className="popup popup-close" id='popup-close' style={{display: 'none'}}>
                            <div className="popup__body">
                                <div className="popup__content">
                                <picture className='popup-close__icon'>
                                    <img src={Danger}/>
                                </picture>
                                <p className='popup-close__title'>Вы уверены что хотите отменитьсоздание этого наряда? </p>
                                <div className="popup-close__btns">
                                <button className="standart-btn opacity-btn" id='close-popup-button' >Вернуться</button>
                                <button className="standart-btn purple-btn"  data-close-button="true">
                                  Закрыть
                                </button>
                                </div>
                                </div>
                            </div>
                            </div>

                    </div>
                </div>
                <div className="jobs-popup__inner">
                    <div className="jobs-popup__content">
                        <div className="jobs-popup__content-wrapper">
                            <p className="jobs-popup__content-title">
                                Информация о наряде
                            </p>
                            <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>Тип наряда</p>
                                <select className='standart-select'
                                 value={selectedValueType}
                                 onChange={handleChangeSelectType}
                                 >
                                    <option value="Не указано" defaultChecked>Первичный выезд</option>
                                    <option value="Первичный выезд">Первичный выезд 2</option>
                                    <option value="Первичный выезд">Первичный выезд 3</option>
                                    <option value="Первичный выезд">Первичный выезд 4</option>
                                </select>
                            </div>
                            <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>Описание</p>
                                <textarea className='standart-textarea'  
                                value={text}
                                     onChange={handleChange}
                                    />
                            </div>
                            <div className="jobs-popup__time jobs-popup__content-item jobs-popup__content-item_flex">
                                <div className="calendar">
                                    <p className='jobs-popup__content-item__title'>Запланирован на</p>
                                    <BasicDateTimePicker />
                                </div>
                                <div className="calendar">
                                    <p className='jobs-popup__content-item__title'>Длительность</p>
                                    <TimePicker />
                                </div>
                            </div>
                            <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>Исполнитель</p>
                                <CustomSelect     
                                elements={elements}
                      filters={filtersSelect}
                       onChange={setSelectedElements}/>
                            </div>
                            <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>Диспетчер</p>
                                <select className='standart-select'
                                    value={selectedValue}
                                    onChange={handleChangeSelect}
                                    >
                                    <option value="Не указано" defaultChecked hidden>Не указано</option>
                                    <option value="Вариант 1">Вариант 1</option>
                                    <option value="Вариант 2">Вариант 2</option>
                                    <option value="Вариант 3">Вариант 3</option>
                                </select>
                            </div>
                            <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>ID</p>
                                <input type="text" className="standart-input" 
                                          value={inputValueID} 
                                          onChange={handleChangeInputID} 
                                          />
                            </div>
                            <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>Услуга</p>
                                <Search
                                 setSearchResults={setSearchResults}
                                    placeholder="Поиск"
                                    data={allData2} />
                            </div>
                            <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>Удаленность заявки</p>
                                <select className='standart-select'>
                                    <option value="Не указано" defaultChecked hidden>Не указано</option>
                                    <option value="Вариант 1">Вариант 1</option>
                                    <option value="Вариант 2">Вариант 2</option>
                                    <option value="Вариант 3">Вариант 3</option>
                                </select>
                            </div>
                            <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>Пациент</p>
                                <input type="text" className="standart-input" />
                            </div>
                            <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>Согласие</p>
                                <input type="text" className="standart-input" />
                            </div>
                            <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>Возраст</p>
                                <input type="text" className="standart-input" />
                            </div>
                            <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>Хр-е заболевания</p>
                                <input type="text" className="standart-input" />
                            </div>
                            <ExclusiveCheckboxes>
                                <div className="jobs-popup__content-item jobs-popup__content-item_flex">
                                    <p className='jobs-popup__content-item__title'>Пол пациента:</p>
                                    <div className='jobs-popup__content-item_flex jobs-popup__content-item_flex_small-gap'><label className='custom-checkbox'><input type="checkbox" /><span></span> Мужчина</label></div>
                                    <div className='jobs-popup__content-item_flex jobs-popup__content-item_flex_small-gap'><label className='custom-checkbox'><input type="checkbox" /><span></span>Женщина</label> </div>

                                </div>
                            </ExclusiveCheckboxes>
                            <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>Статус</p>
                                <select className='standart-select'>
                                    <option value="Не указано" defaultChecked>Не выбрано</option>
                                    <option value="Вариант 1">Вариант 1</option>
                                    <option value="Вариант 2">Вариант 2</option>
                                    <option value="Вариант 3">Вариант 3</option>
                                </select>
                            </div>
                            <ExclusiveCheckboxes>
                                <div className="jobs-popup__content-item jobs-popup__content-item_flex">
                                    <p className='jobs-popup__content-item__title'>Продажа стационара Контроль:</p>
                                    <div className='jobs-popup__content-item_flex jobs-popup__content-item_flex_small-gap'><label className='custom-checkbox'><input type="checkbox" /><span></span> Да</label></div>
                                    <div className='jobs-popup__content-item_flex jobs-popup__content-item_flex_small-gap'><label className='custom-checkbox'><input type="checkbox" /><span></span> Нет</label></div>
                                </div>
                            </ExclusiveCheckboxes>
                            <ExclusiveCheckboxes>
                                <div className="jobs-popup__content-item jobs-popup__content-item_flex">
                                    <p className='jobs-popup__content-item__title'>Передали партнеру после слета:</p>
                                    <div className='jobs-popup__content-item_flex jobs-popup__content-item_flex_small-gap'><label className='custom-checkbox'><input type="checkbox" /><span></span> Да</label></div>
                                    <div className='jobs-popup__content-item_flex jobs-popup__content-item_flex_small-gap'><label className='custom-checkbox'><input type="checkbox" /><span></span> Нет</label></div>
                                </div>
                            </ExclusiveCheckboxes>
                            <ExclusiveCheckboxes>
                                <div className="jobs-popup__content-item jobs-popup__content-item_flex">
                                    <p className='jobs-popup__content-item__title'>Обработано кассой:</p>
                                    <div className='jobs-popup__content-item_flex jobs-popup__content-item_flex_small-gap'><label className='custom-checkbox'><input type="checkbox" /><span></span> Да</label></div>
                                    <div className='jobs-popup__content-item_flex jobs-popup__content-item_flex_small-gap'><label className='custom-checkbox'><input type="checkbox" /><span></span> Нет</label></div>
                                </div>
                            </ExclusiveCheckboxes>
                            <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>Внешний идентификатор</p>
                                <input type="text" className="standart-input" />
                            </div>
                            <div className='jobs-popup__implementer'>
                            <Accordion title="Отчет исполнителя">
                            <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>Администратор</p>
                                <select className='standart-select'>
                                    <option value="Не указано" defaultChecked hidden>Не указано</option>
                                    <option value="Вариант 1">Вариант 1</option>
                                    <option value="Вариант 2">Вариант 2</option>
                                    <option value="Вариант 3">Вариант 3</option>
                                </select>
                            </div>
                            <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>Фельдшер</p>
                                <select className='standart-select'>
                                    <option value="Не указано" defaultChecked hidden>Не указано</option>
                                    <option value="Вариант 1">Вариант 1</option>
                                    <option value="Вариант 2">Вариант 2</option>
                                    <option value="Вариант 3">Вариант 3</option>
                                </select>
                            </div>
                            <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>Итоговая стоимость</p>
                                <PriceInput initialValue='50.00' />
                            </div>
                            <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>Зум психотерапия онлайн</p>
                                <PriceInput initialValue='150.00' />
                            </div>
                            <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>БАДы</p>
                                <PriceInput initialValue='0.00' />
                            </div>
                            <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>Комментарий</p>
                                <textarea className='standart-textarea' placeholder='Добавить комментарий...'>
                                </textarea>
                            </div>
                            <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>Предоплата</p>
                                <PriceInput initialValue='20.00' />
                            </div>
                            <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>Состояние статус</p>
                                <select className='standart-select'>
                                    <option value="Не указано" defaultChecked hidden>Не указано</option>
                                    <option value="Вариант 1">Вариант 1</option>
                                    <option value="Вариант 2">Вариант 2</option>
                                    <option value="Вариант 3">Вариант 3</option>
                                </select>
                            </div>
                            <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>Комплексная Терапия</p>
                                <select className='standart-select'>
                                    <option value="Не указано" defaultChecked hidden>Не указано</option>
                                    <option value="Вариант 1">Вариант 1</option>
                                    <option value="Вариант 2">Вариант 2</option>
                                    <option value="Вариант 3">Вариант 3</option>
                                </select>
                            </div>
                            <ExclusiveCheckboxes>
                                <div className="jobs-popup__content-item jobs-popup__content-item_flex">
                                    <p className='jobs-popup__content-item__title'>Получено согласие на обработку персональных данных:</p>
                                    <div className='jobs-popup__content-item_flex jobs-popup__content-item_flex_small-gap'><label className='custom-checkbox'><input type="checkbox" /><span></span> Да</label></div>
                                    <div className='jobs-popup__content-item_flex jobs-popup__content-item_flex_small-gap'><label className='custom-checkbox'><input type="checkbox" /><span></span> Нет</label></div>
                                </div>
                            </ExclusiveCheckboxes>
                            <ExclusiveCheckboxes>
                                <div className="jobs-popup__content-item jobs-popup__content-item_flex">
                                    <p className='jobs-popup__content-item__title'>Получено согласие на медицинское вмешательство:</p>
                                    <div className='jobs-popup__content-item_flex jobs-popup__content-item_flex_small-gap'><label className='custom-checkbox'><input type="checkbox" /><span></span> Да</label></div>
                                    <div className='jobs-popup__content-item_flex jobs-popup__content-item_flex_small-gap'><label className='custom-checkbox'><input type="checkbox" /><span></span> Нет</label></div>
                                </div>
                            </ExclusiveCheckboxes>
                            <ExclusiveCheckboxes>
                                <div className="jobs-popup__content-item jobs-popup__content-item_flex">
                                    <p className='jobs-popup__content-item__title'>Заключен договор на оказание медицинских услуг:</p>
                                    <div className='jobs-popup__content-item_flex jobs-popup__content-item_flex_small-gap'><label className='custom-checkbox'><input type="checkbox" /><span></span> Да</label></div>
                                    <div className='jobs-popup__content-item_flex jobs-popup__content-item_flex_small-gap'><label className='custom-checkbox'><input type="checkbox" /><span></span> Нет</label></div>
                                </div>
                            </ExclusiveCheckboxes>
                            <ExclusiveCheckboxes>
                                <div className="jobs-popup__content-item jobs-popup__content-item_flex">
                                    <p className='jobs-popup__content-item__title'>Подписано уведомление о последствиях не исполнения указаний (рекомендаций):</p>
                                    <div className='jobs-popup__content-item_flex jobs-popup__content-item_flex_small-gap'><label className='custom-checkbox'><input type="checkbox" /><span></span> Да</label></div>
                                    <div className='jobs-popup__content-item_flex jobs-popup__content-item_flex_small-gap'><label className='custom-checkbox'><input type="checkbox" /><span></span> Нет</label></div>
                                </div>
                            </ExclusiveCheckboxes>
                            <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>Состояние статус</p>
                                <select className='standart-select'>
                                    <option value="Не указано" defaultChecked hidden>Не указано</option>
                                    <option value="Вариант 1">Вариант 1</option>
                                    <option value="Вариант 2">Вариант 2</option>
                                    <option value="Вариант 3">Вариант 3</option>
                                </select>
                            </div>
                            <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>Выберите препараты</p>
                                <Medecine
                                         elements={ElementMedecine}
                                         filters={filterMedecine}
                                          onChange={setMedecineItems}
                                          />
                            </div>
                            <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>Пройдено по наряду</p>
                                <div className="jobs-popup__content-item_flex">
                                <div className="jobs-popup__map-input">
                                <input type="text" className="standart-input" placeholder='0 км'/>
                                </div>
                                <button   onClick={toggleMap} className="jobs-popup__map-btn">
                                Показать карту
                                </button>
                             
                                </div>
                                {mapVisible && (
                                    <div className='jobs-popup__map'>
                                        <LocationTracker />
                                    </div>
                                )}
                             
                               
                                
                            </div>
                            </Accordion>
                            <Accordion title="Оплата по QR-коду">
                            <QRCode  />
                    <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>Чек подтверждения</p>
                                <div className="jobs-popup__file-uploader">
                                    <ImageUploader  />
                                    </div>
                            </div>

                            <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>Предоплаченный повтор чек</p>
                                <div className="jobs-popup__file-uploader">
                                    <ImageUploader />
                                    </div>
                            </div>
                            <div className="jobs-popup__content-item">
                                <p className='jobs-popup__content-item__title'>Статус оплаты QR</p>
                                <textarea className='standart-textarea' placeholder='Добавить комментарий...'>
                                </textarea>
                            </div>
                            </Accordion>
                            </div>


                        </div>

                    </div>
                    <div className="jobs-popup__client">
                        <p className="jobs-popup__client-title">
                            Данные клиента
                        </p>
                        <div className="jobs-popup__client-item">
                            <p className='jobs-popup__client-item__title'>Клиент или объект</p>
                            <div className="search-wrapper">
                                <Search
                                   isClient={true}
                                  setSearchResults={setSearchResults}
                                    placeholder="Поиск"
                                    data={allData} />
                            </div>

                        </div>
                        <div className="jobs-popup__client-item">
                            <div className="jobs-popup__client-item_flex jobs-popup__client-item_flex_2">
                                <div>
                                    <p className='jobs-popup__client-item__title'>Имя</p>
                                    <input type='text' placeholder='Иван' className='standart-input' />

                                </div>
                                <div>
                                    <p className='jobs-popup__client-item__title'>Телефон</p>
                                    <input type='tel' placeholder='+7' className='standart-input' />

                                </div>
                            </div>
                            <p className='jobs-popup__client-small'>* На номер основного контакта приходят СМС-уведомления по наряду, если они используются</p>
                        </div>
                        <div className="jobs-popup__client-item">
                            <p className='jobs-popup__client-item__title'>Адрес</p>
                          <MapSearch />
                       
                        </div>
                        <div className="jobs-popup__client-item_flex jobs-popup__client-item_flex_3">
                            <div>
                                <p className='jobs-popup__client-item__title'>Квартира/офис</p>
                                <input type='text' className='standart-input' />
                            </div>
                            <div>
                                <p className='jobs-popup__client-item__title'>Этаж</p>
                                <input type='text' className='standart-input' />
                            </div>
                            <div>
                                <p className='jobs-popup__client-item__title'>Подъезд</p>
                                <input type='text' className='standart-input' />
                            </div>
                        </div>
                        <div className="jobs-popup__client-item">
                            <div className='jobs-popup__client-item__title'>Участок 
                                <Dialog>
                                   <span>Участки</span>
                                   <p>Это группа адресов, обслуживаемых одной командой. Обычно это город или регион. Укажите участок в наряде, чтобы сотрудники с подходящим участком были первыми в списке. Загрузите GeoJSON-файл для автоматического выбора участка.</p>
                                </Dialog></div>
                            <select className='standart-select'>
                                <option value="Не указано" defaultChecked hidden>Не указано</option>
                                <option value="Вариант 1">Вариант 1</option>
                                <option value="Вариант 2">Вариант 2</option>
                                <option value="Вариант 3">Вариант 3</option>
                            </select>
                        </div>
                        <div className="jobs-popup__client-item">
                            <p className='jobs-popup__client-item__title'>Описание адреса</p>
                            <textarea className='standart-textarea' placeholder='Добавить описание...'>
                            </textarea>
                        </div>
                        <AddContact />
                    </div>
                </div>
            </div>
        </>
    )
}