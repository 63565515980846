import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    getLegalEntities,
    getLegalEntityById,
    createLegalEntity,
    updateLegalEntity,
    deleteLegalEntity,
} from '../../api/legalEntityApi';
import { CreateLegalEntityPayload, UpdateLegalEntityPayload } from './legalEntitiesTypes';

// Fetch all legal entities
export const fetchLegalEntities = createAsyncThunk('legalEntities/fetchAll', async () => {
    return await getLegalEntities();
});

// Fetch a legal entity by ID
export const fetchLegalEntityById = createAsyncThunk('legalEntities/fetchById', async (id: number) => {
    return await getLegalEntityById(id);
});

// Create a new legal entity
export const createNewLegalEntity = createAsyncThunk('legalEntities/create', async (data: CreateLegalEntityPayload) => {
    return await createLegalEntity(data);
});

// Update an existing legal entity
export const updateExistingLegalEntity = createAsyncThunk(
    'legalEntities/update',
    async ({ id, data }: { id: number; data: UpdateLegalEntityPayload }) => {
        return await updateLegalEntity(id, data);
    }
);

// Delete a legal entity
export const deleteLegalEntityById = createAsyncThunk('legalEntities/delete', async (id: number) => {
    return await deleteLegalEntity(id);
});
