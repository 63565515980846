import axiosInstance from './axiosConfig';
import { ValueList } from '../redux/valueList/types';

// Fetch all value lists
export const fetchValueListsApi = async (): Promise<ValueList[]> => {
    const response = await axiosInstance.get<ValueList[]>('/value-list');
    return response.data;
};

// Fetch a single value list by ID
export const fetchValueListByIdApi = async (id: number): Promise<ValueList | null> => {
    try {
        const response = await axiosInstance.get<ValueList>(`/value-list/${id}`);
        return response.data;
    } catch (error: any) {
        if (error.response?.status === 404) return null; // Handle not found
        throw error;
    }
};

// Create a new value list
export const createValueListApi = async (data: { name: string; options: string[] }): Promise<ValueList> => {
    const response = await axiosInstance.post<ValueList>('/value-list', data);
    return response.data;
};

// Update an existing value list
export const updateValueListApi = async (
    id: number,
    data: Partial<{ name: string; options: string[] }>
): Promise<ValueList> => {
    if (data.options && new Set(data.options).size !== data.options.length) {
        throw new Error('Duplicate options are not allowed.');
    }

    const response = await axiosInstance.patch<ValueList>(`/value-list/${id}`, data);
    return response.data;
};

// Delete a value list
export const deleteValueListApi = async (id: number): Promise<void> => {
    await axiosInstance.delete(`/value-list/${id}`);
};

// Reorder the options in a value list
export const reorderValueListOptionsApi = async (id: number, orderedOptions: string[]): Promise<ValueList> => {
    if (new Set(orderedOptions).size !== orderedOptions.length) {
        throw new Error('Duplicate options are not allowed.');
    }

    const response = await axiosInstance.patch<ValueList>(`/value-list/${id}/reorder`, { options: orderedOptions });
    return response.data;
};
