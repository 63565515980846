import axiosInstance from './axiosConfig';
import {
    CreateClientDto,
    UpdateClientDto,
    Client,
    GeocodeAddressDto,
    AddressDto,
} from '../redux/clients/types';

// ✅ Get all clients
export const fetchClients = async (): Promise<Client[]> => {
    const response = await axiosInstance.get<Client[]>('/clients');
    return response.data;
};

// ✅ Search clients by name or phone
export const searchClients = async (query: string): Promise<Client[]> => {
    if (!query.trim()) {
        throw new Error('Query parameter is required');
    }
    const response = await axiosInstance.get<Client[]>(`/clients/search`, {
        params: { query },
    });
    return response.data;
};

// ✅ Get client by ID
export const fetchClientById = async (id: number): Promise<Client> => {
    const response = await axiosInstance.get<Client>(`/clients/${id}`);
    return response.data;
};

// ✅ Create a new client
export const createClient = async (clientData: CreateClientDto): Promise<Client> => {
    const response = await axiosInstance.post<Client>('/clients', clientData);
    return response.data;
};

// ✅ Update existing client
export const updateClient = async (
    id: number,
    clientData: UpdateClientDto
): Promise<Client> => {
    const response = await axiosInstance.put<Client>(`/clients/${id}`, clientData);
    return response.data;
};

// ✅ Delete a client
export const deleteClient = async (id: number): Promise<{ message: string }> => {
    const response = await axiosInstance.delete<{ message: string }>(`/clients/${id}`);
    return response.data;
};

// ✅ Reverse geocode by coordinates (latitude + longitude → address)
export const reverseGeocode = async (
    latitude: number,
    longitude: number
): Promise<AddressDto> => {
    const response = await axiosInstance.get<AddressDto>(
        `/clients/geocode/address`,
        { params: { latitude, longitude } }
    );
    return response.data;
};

// ✅ Geocode address (city + street + building → lat/long)
export const geocodeAddress = async (
    addressData: GeocodeAddressDto
): Promise<{ latitude: number; longitude: number }> => {
    const response = await axiosInstance.get<{ latitude: number; longitude: number }>(
        `/clients/geocode/address`,
        { params: addressData }
    );
    return response.data;
};
