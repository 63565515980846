// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hide-content {
  display: none;
}
.hide-content.active {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/Accordion/AccordionItem.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAAE;EACE,cAAA;AAEJ","sourcesContent":[".hide-content {\n  display: none;\n  &.active {\n    display: block;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
