import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    fetchResolutionsThunk,
    fetchResolutionByIdThunk,
    createResolutionThunk,
    updateResolutionThunk,
    deleteResolutionThunk,
} from './resolutionsThunks';

export interface Resolution {
    id: number;
    name: string;
    type: 'SUCCESSFUL' | 'UNSUCCESSFUL';
    requiresNote: boolean;
}

interface ResolutionsState {
    resolutions: Resolution[];
    selectedResolution: Resolution | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    loadingAction: boolean;
}

const initialState: ResolutionsState = {
    resolutions: [],
    selectedResolution: null,
    status: 'idle',
    error: null,
    loadingAction: false, // Tracks individual loading states for API actions
};

const resolutionsSlice = createSlice({
    name: 'resolutions',
    initialState,
    reducers: {
        clearResolutionError(state) {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchResolutionsThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchResolutionsThunk.fulfilled, (state, action: PayloadAction<Resolution[]>) => {
                state.resolutions = action.payload;
                state.status = 'succeeded';
            })
            .addCase(fetchResolutionsThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            .addCase(fetchResolutionByIdThunk.fulfilled, (state, action: PayloadAction<Resolution>) => {
                state.selectedResolution = action.payload;
            })
            .addCase(createResolutionThunk.pending, (state) => {
                state.loadingAction = true;
            })
            .addCase(createResolutionThunk.fulfilled, (state, action: PayloadAction<Resolution>) => {
                state.resolutions.push(action.payload);
                state.loadingAction = false;
            })
            .addCase(updateResolutionThunk.pending, (state) => {
                state.loadingAction = true;
            })
            .addCase(updateResolutionThunk.fulfilled, (state, action: PayloadAction<Resolution>) => {
                const index = state.resolutions.findIndex((res) => res.id === action.payload.id);
                if (index !== -1) state.resolutions[index] = action.payload;
                state.loadingAction = false;
            })
            .addCase(deleteResolutionThunk.pending, (state) => {
                state.loadingAction = true;
            })
            .addCase(deleteResolutionThunk.fulfilled, (state, action: PayloadAction<number>) => {
                state.resolutions = state.resolutions.filter((res) => res.id !== action.payload);
                state.loadingAction = false;
            });
    },
});

export const { clearResolutionError } = resolutionsSlice.actions;
export default resolutionsSlice.reducer;
