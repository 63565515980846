// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../img/icons/trash.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.medecine__item {
  position: relative;
}

.medecine__items {
  padding: 12px;
  background-color: #F2F4F7;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.medecine__title {
  font-size: 14px;
  font-weight: 600;
  color: #101828;
  display: block;
  margin-bottom: 24px;
}

.medecine__item {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}
.medecine__item .standart-select {
  max-width: calc(100% - 50px);
}
.medecine__item .delete-item {
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.medecine__item .delete-item::before {
  display: block;
  content: "";
  width: 20px;
  height: 20px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center center no-repeat;
}
@media (hover: hover) {
  .medecine__item .delete-item:hover {
    border-color: #6941C6;
  }
}

.medecine__name {
  display: block;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Medecine/Medecine.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;AAAF;;AAEA;EACE,aAAA;EACA,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;AACF;;AACA;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,cAAA;EACA,mBAAA;AAEF;;AAAA;EACE,aAAA;EACA,eAAA;EACA,QAAA;AAGF;AAFE;EACE,4BAAA;AAIJ;AAFE;EACE,WAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;EACA,yBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AAIJ;AAHI;EACE,cAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,2EAAA;AAKN;AAHI;EACE;IACE,qBC3CC;EDgDP;AACF;;AAAA;EACE,cAAA;EACA,WAAA;AAGF","sourcesContent":["@import '../../styles/variables';\n.medecine__item {\n  position: relative;\n}\n.medecine__items {\n  padding: 12px ;\n  background-color: #F2F4F7;\n  border-radius: 8px;\n  display: flex;\n  flex-direction: column;\n}\n.medecine__title {\n  font-size: 14px;\n  font-weight: 600;\n  color: #101828;\n  display: block;\n  margin-bottom: 24px;\n}\n.medecine__item {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 6px;\n  & .standart-select {\n    max-width: calc(100% - 50px);\n  }\n  & .delete-item {\n    width: 44px;\n    height: 44px;\n    flex-shrink: 0;\n    border-radius: 8px;\n    border: 1px solid #D0D5DD;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: #fff;\n    &::before {\n      display: block;\n      content: '';\n      width: 20px;\n      height: 20px;\n      background: url(\"../../img/icons/trash.svg\") center center no-repeat;\n    }\n    @media (hover:hover) {\n      &:hover {\n        border-color: $purple;\n      }\n    }\n  }\n\n}\n.medecine__name {\n  display: block;\n  width: 100%;\n}","// src/styles/variables.scss\n$purple: #6941C6;\n$secondary-color: #F9F5FF;\n$black: #101828;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
