import React, { useState, ChangeEvent } from 'react';

// Interfaces
interface Clinic {
  id: string;
  category: 'clinic';
  name: string;
  coordinates: string;
  text: string;
  icon: File | null;
}

interface Brigade {
  id: string;
  category: 'brigade';
  name: string;
  coordinates: string;
  text: string;
}
interface Area {
  id: string;
  category: 'area';
  coordinates: string; 
  name: string;
  text?: string; 
}

type Item = Clinic | Brigade | Area;

// Type Guards
function isClinic(item: Item): item is Clinic {
  return item.category === 'clinic';
}

function isBrigade(item: Item): item is Brigade {
  return item.category === 'brigade';
}

function isArea(item: Item): item is Area {
  return item.category === 'area';
}
const categoryTranslations: { [key: string]: string } = {
    clinic: 'Клиники',
    brigade: 'Бригады',
    area: 'Области',
  };
// Main Component
const App: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [items, setItems] = useState<Item[]>([]);

  // Form State (Generic)
  const [name, setName] = useState('');
  const [coordinates, setCoordinates] = useState('');
  const [text, setText] = useState('');

  // Form State (Clinic specific)
  const [icon, setIcon] = useState<File | null>(null);
  const [iconName, setIconName] = useState<string>(''); 
  // Handlers
  const handleOptionChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedOption(value);
  };

  const handleAddItem = () => {
    setIsFormVisible(true);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    let newItem: Item;

    switch (selectedOption) {
      case 'clinic':
        newItem = {
          id: Date.now().toString(),
          category: 'clinic',
          name,
          coordinates,
          text,
          icon: icon || null, // Ensure icon is provided
        };
        break;
      case 'brigade':
        newItem = {
          id: Date.now().toString(),
          category: 'brigade',
          name,
          coordinates,
          text,
        };
        break;
      case 'area':
        newItem = {
          id: Date.now().toString(),
          category: 'area',
          coordinates,
          name,
          text,
        };
        break;
      default:
        // Handle the case where selectedOption is null or invalid
        console.error('Invalid selected option');
        return; // Exit the function
    }

    setItems([...items, newItem]);
    setIsFormVisible(false);

    // Clear form
    setName('');
    setCoordinates('');
    setText('');
    setIcon(null);
    setSelectedOption(null);
  };
  const handleRemoveIcon = () => {
    setIcon(null);
    setIconName('');
  };
  const handleCancel = () => {
    setIsFormVisible(false);
    setName('');
    setCoordinates('');
    setText('');
    setIcon(null);
    setSelectedOption(null);
  };
  
  const handleIconChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setIcon(file);
      setIconName(file.name);
    }
  };
  return (
<section className='settings map-add'>
            <div className="container container-small">
                <div className="section-top">
                    <h1 className="section-title">
                    Настройки карты
                    </h1>
                    <div className="settings__btn">
                        <button className="standart-btn purple-btn">
                        Сохранить изменения
                        </button>
                        <button className="standart-btn opacity-btn">
                        Отменить
                        </button>
                    </div>
                </div>

                <div className='order-type settings__inner'>
                    <h2 className='section-title settings__inner-title'>
                    Создание точек и областей
                    </h2>
                    <div className="map-add__top">
                        <p className='map-add__title'>Категория</p>
                    <div className="map-add__top-btns">
        <select onChange={handleOptionChange} value={selectedOption || ''} className='standart-select'>
          <option value="">Выберите категорию</option>
          <option value="clinic">Клиники</option>
          <option value="brigade">Бригады</option>
          <option value="area">Области</option>
        </select>
        <button
          onClick={handleAddItem}
          disabled={!selectedOption}
          className={`purple-btn standart-btn ${selectedOption ? 'active' : ''} `}
        >
          Добавить
        </button>
      </div>
                    </div>
    
      {isFormVisible && selectedOption && (
        <div className="map-add__wrapper">
            <p className='map-add__title'>Добавить категорию</p>
                <form onSubmit={handleSubmit} className="map-add__from">
          <label className='map-add__from-item'>
           <span> Название:</span>
            <input className='standart-input'
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </label>
          <label className='map-add__from-item'>
          <span>  Координаты:</span>
    {selectedOption === 'area' ? (
        <textarea
            className='standart-textarea'
            value={coordinates}
            onChange={(e) => setCoordinates(e.target.value)}
            required
        />
    ) : (
        <input
            className='standart-input'
            type="text"
            value={coordinates}
            onChange={(e) => setCoordinates(e.target.value)}
            required
        />
    )}
          </label>
          <label className='map-add__from-item'>
           <span> Текст:</span>
            <textarea
              value={text}  className='standart-textarea'
              onChange={(e) => setText(e.target.value)}
            />
          </label>

          {selectedOption === 'clinic' && (
            <label className='map-add__from-item'>
              <span> Иконка:</span>
              <input
                className='standart-input'
                type="file"
                accept="image/*"
                onChange={handleIconChange}
                id="icon-upload"
                style={{ display: 'none' }} 
              />
              <div className='map-add__icon'>
              <label htmlFor="icon-upload" className="standart-input"> 
                {iconName ? iconName : 'Загрузить иконку'}
              </label>
              {!icon && (
                <div className='map-add__icon-icon'></div>
              )}
              {icon && (
                <button type="button" onClick={handleRemoveIcon} className="settings__change settings__change_trash">
                 
                </button>
              )}
              </div>
             
            </label>
          )}
            <div className="map-add__btns">
            <button className='standart-btn purple-btn' type="submit">Сохранить</button>
            <button className='standart-btn opacity-btn'  onClick={handleCancel}>Отменить</button>
            </div>
          
        </form>
        </div>
        
      )}
      <div className='map-add__inner'>
            <p className="map-add__inner-title"> Списки значений</p>

            <table className="map-add__table">
        <thead>
          <tr>
            <th>Категория</th>
            <th>Иконка</th>
            <th>Название</th>
            <th>Координаты</th>
            <th>Текст</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={item.id} className="map-add__item">
              <td>{categoryTranslations[item.category]}</td>
              <td>
                {isClinic(item) && item.icon && (
                  <img
                    src={item.icon ? URL.createObjectURL(item.icon) : ''}
                    alt="Иконка"
                    style={{ width: '32px', height: '32px' }}
                  />
                )}
              </td>
              <td>{item.name}</td>
              <td>{item.coordinates}</td>
              <td>{item.text}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
     
     
    </div>
    </div>
         </section>
  );
};

export default App;