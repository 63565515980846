import React, { useState, useRef } from 'react';
import './Dialog.scss'; 

interface DialogProps {
    children: React.ReactNode;
}

const Dialog: React.FC<DialogProps> = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false); 
    const [position, setPosition] = useState<'left' | 'top' | 'bottom' | 'right'>('right'); 
    const iconRef = useRef<HTMLDivElement>(null); 

    const handleMouseEnter = () => {
        if (iconRef.current) {
            const iconRect = iconRef.current.getBoundingClientRect();
            const spaceRight = window.innerWidth - iconRect.right; 
            const spaceLeft = iconRect.left; 
            if (spaceRight < 320 && spaceLeft >= 320) {
                setPosition('left');
            } 
        }
        setIsVisible(true);
    };
    const handleMouseLeave = () => {
        setIsVisible(false);
    };

    return (
        <div className="dialog-container">
            <div
                ref={iconRef}
                className="dialog-icon"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            > 
            </div>
            {isVisible && (
                <div className={`dialog-tooltip ${position}`}>
                    <div className="dialog-content">{children}</div>
                </div>
            )}
        </div>
    );
};

export default Dialog;