// src/components/Templates/TemplateList.tsx
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../redux/store';
import {
    fetchTemplatesThunk,
    deleteTemplateThunk
} from '../../redux/templates/templateThunks';
import './TemplateList.scss';
import { Modal, Box, Typography, Button } from '@mui/material';

const TemplateList: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { templates, status, error } = useSelector((state: RootState) => state.templates);

    const [confirmDeleteId, setConfirmDeleteId] = useState<number | null>(null);

    useEffect(() => {
        dispatch(fetchTemplatesThunk());
    }, [dispatch]);

    const handleEdit = (id: number) => {
        navigate(`/templates/edit/${id}`);
    };

    const handleAdd = () => {
        navigate("/templates/new");
    };

    const handleDelete = async () => {
        if (confirmDeleteId !== null) {
            await dispatch(deleteTemplateThunk(confirmDeleteId));
            setConfirmDeleteId(null);
            await dispatch(fetchTemplatesThunk());
        }
    };

    return (
        <section className='settings'>
            <div className="container container-small">
                <div className='templates settings__inner'>
                    <div className='section-top settings__inner-title'>
                        <h2 className='section-title'>Шаблоны</h2>
                        <button className='templates__add' onClick={handleAdd}>
                            Добавить шаблон
                        </button>
                    </div>

                    {status === "loading" && (
                        <div className="loading-container">
                            <div className="loading-icon"></div>
                            <p>Загрузка шаблонов...</p>
                        </div>
                    )}

                    {status === "failed" && error && (
                        <p className="templates__error">Ошибка: {error}</p>
                    )}

                    {status === "succeeded" && templates.length > 0 && (
                        <ul className="resolution__list">
                            {templates.map((template) => (
                                <li key={template.id} className="resolution__content">
                                    <p className="resolution__name">{template.name}</p>
                                    <button
                                        onClick={() => handleEdit(template.id)}
                                        className="settings__change settings__change_edit"
                                    />
                                    <button
                                        onClick={() => setConfirmDeleteId(template.id)}
                                        className="settings__change settings__change_trash"
                                    />
                                </li>
                            ))}
                        </ul>
                    )}

                    {status === "succeeded" && templates.length === 0 && (
                        <p className="templates__empty">Шаблонов пока нет</p>
                    )}

                    {/* ✅ MODAL WINDOW (like ResolutionsList) ✅ */}
                    {confirmDeleteId !== null && (
                        <Modal open={true} onClose={() => setConfirmDeleteId(null)}>
                            <Box className="delete-modal">
                                <Typography variant="h6" className="delete-modal-title">
                                    Действительно ли вы хотите удалить шаблон "
                                    <strong>
                                        {templates.find((t) => t.id === confirmDeleteId)?.name}
                                    </strong>
                                    "?
                                </Typography>
                                <Box className="delete-modal-buttons">
                                    <Button
                                        variant="contained"
                                        className="delete-modal-confirm"
                                        onClick={handleDelete}
                                    >
                                        Удалить
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        className="delete-modal-cancel"
                                        onClick={() => setConfirmDeleteId(null)}
                                    >
                                        Отмена
                                    </Button>
                                </Box>
                            </Box>
                        </Modal>
                    )}
                </div>
            </div>
        </section>
    );
};

export default TemplateList;
