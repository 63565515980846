import React, { useState, useEffect } from 'react';
import {TextField, Button, InputAdornment, IconButton} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../redux/store';
import {
    createNewLegalEntity, fetchLegalEntities,
    fetchLegalEntityById,
    updateExistingLegalEntity
} from '../../redux/legalEntity/legalEntitiesThunks';
import {Visibility, VisibilityOff} from '@mui/icons-material';

type Mode = 'create' | 'edit' | 'read';

interface FormValues {
    name: string;
    secretToken: string;
}

export const LegalEntityForm: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const role = useSelector((state: RootState) => state.auth.user?.role);
    const legalEntity = useSelector((state: RootState) => state.legalEntities.selectedLegalEntity);
    const [mode, setMode] = useState<Mode>('create');

    const [formValues, setFormValues] = useState<FormValues>({
        name: '',
        secretToken: '',
    });
    const [showToken, setShowToken] = useState(false);

    const toggleTokenVisibility = () => {
        setShowToken(!showToken);
    };

    useEffect(() => {
        if (id) {
            setMode('read');
            dispatch(fetchLegalEntityById(Number(id)));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (legalEntity && id) {
            setFormValues({
                name: legalEntity.name,
                secretToken: '', // Reset to avoid exposing token
            });
        }
    }, [legalEntity, id]);

    const handleSave = async () => {
        if (mode === 'create') {
            await dispatch(createNewLegalEntity(formValues));
        } else if (mode === 'edit') {
            await dispatch(updateExistingLegalEntity({ id: Number(id), data: formValues }));
        }
        dispatch(fetchLegalEntities());
        navigate('/legal-entities');
    };

    const handleEdit = () => {
        setMode('edit');
    };

    const isSuperAdmin = role === 'SUPER_ADMIN';

    return (
        <div>
            <h2>
                {mode === 'create'
                    ? 'Add Legal Entity'
                    : mode === 'edit'
                        ? `Edit Legal Entity - ${formValues.name}`
                        : `View Legal Entity - ${formValues.name}`}
            </h2>

            {/* Name Input */}
            <TextField
                label="Name"
                value={formValues.name}
                onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
                fullWidth
                disabled={mode === 'read'}
            />

            {/* Secret Token Input */}
            {mode !== 'read' && (
                <TextField
                    label="Secret Token"
                    type={showToken ? 'text' : 'password'}
                    value={formValues.secretToken}
                    onChange={(e) => setFormValues({ ...formValues, secretToken: e.target.value })}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={toggleTokenVisibility}>
                                    {showToken ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    style={{ marginBottom: '1rem' }}
                />
            )}

            {/* Button Controls */}
            {(mode === 'create' || mode === 'edit') && (
                <div>
                    <Button variant="contained" onClick={handleSave}>
                        Save
                    </Button>
                    <Button variant="outlined" onClick={() => navigate('/legal-entities')}>
                        Cancel
                    </Button>
                </div>
            )}

            {mode === 'read' && (
                <div>
                    <Button variant="outlined" onClick={() => navigate('/legal-entities')}>
                        Back
                    </Button>
                    {isSuperAdmin && (
                        <Button variant="contained" onClick={handleEdit}>
                            Edit
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
};
