// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs .tab-buttons {
  display: flex;
  border-bottom: 1px solid #EAECF0;
}
.tabs .tab-buttons .tab-button {
  padding: 10px 15px;
  min-width: 240px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}
.tabs .tab-buttons .tab-button:hover {
  background-color: #e9e0fd;
}
.tabs .tab-buttons .tab-button.active {
  border-bottom: 2px solid #6941C6;
}
.tabs .tab-content {
  padding: 15px;
}
.tabs .tab-content .tab-panel {
  display: none;
}
.tabs .tab-content .tab-panel.active {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/Tabs/Tabs.scss"],"names":[],"mappings":"AACI;EACE,aAAA;EACA,gCAAA;AAAN;AACM;EACE,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,6BAAA;EACA,eAAA;EACA,oCAAA;AACR;AACQ;EACE,yBAAA;AACV;AAEQ;EACE,gCAAA;AAAV;AAKI;EACE,aAAA;AAHN;AAKM;EACE,aAAA;AAHR;AAKQ;EACE,cAAA;AAHV","sourcesContent":[".tabs {\n    .tab-buttons {\n      display: flex;\n      border-bottom: 1px solid #EAECF0;\n      .tab-button {\n        padding: 10px 15px;\n        min-width: 240px;\n        border: none;\n        background-color: transparent;\n        cursor: pointer;\n        border-bottom: 2px solid transparent; \n  \n        &:hover {\n          background-color: #e9e0fd;\n        }\n  \n        &.active {\n          border-bottom: 2px solid #6941C6; \n        }\n      }\n    }\n  \n    .tab-content {\n      padding: 15px;\n  \n      .tab-panel {\n        display: none;\n  \n        &.active {\n          display: block;\n        }\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
