import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../redux/store";
import Dialog from "../Dialog/Dialog";
import {
    fetchTemplateByIdThunk,
    createTemplateThunk,
    updateTemplateThunk,
    fetchTemplatesThunk,
} from "../../redux/templates/templateThunks";
import { clearSelectedTemplate } from "../../redux/templates/templateSlice";
import { fetchOrderedOrderTypes } from "../../redux/orderType/orderTypeThunks";
import { fetchOrderedSkills } from "../../redux/executorSkill/executorSkillThunks";
import { fetchOrderedAreas } from "../../redux/area/areaThunks";
import { fetchTeams } from "../../redux/team/teamThunks";
import { fetchValueLists } from "../../redux/valueList/valueListThunks";
import { CreateTemplate, Template, UpdateTemplate } from "../../redux/templates/types";
import { DynamicFields } from "./DynamicFields";
import { ReportFields } from "./ReportFields";
import { Resolutions } from "./Resolutions";
import "./TemplateList.scss";

const TemplateForm: React.FC<{ readOnly?: boolean }> = ({ readOnly = false }) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { id } = useParams();
    const selectedTemplate = useSelector((state: RootState) => state.templates.selectedTemplate);
    const orderTypes = useSelector((state: RootState) => state.orderType.orderTypes);
    const executorSkills = useSelector((state: RootState) => state.executorSkill.skills);
    const areas = useSelector((state: RootState) => state.areas.areas);
    const teams = useSelector((state: RootState) => state.teams.teams);
    const valueLists = useSelector((state: RootState) => state.valueList.valueLists);

    const selectedTemplateId = id ? parseInt(id) : null;
    const isEditing = !!selectedTemplateId && !readOnly;

    const [templateData, setTemplateData] = useState<Partial<Template>>({
        name: "",
        orderTypeId: null,
        description: "",
        duration: 0,
        executorSkillId: null,
        areaId: null,
        teamId: null,
        additionalFields: [],
        reportFields: [],
        resolutions: [],
    });

    useEffect(() => {
        dispatch(fetchOrderedOrderTypes());
        dispatch(fetchOrderedSkills());
        dispatch(fetchOrderedAreas());
        dispatch(fetchTeams());
        dispatch(fetchValueLists());
    }, [dispatch]);

    useEffect(() => {
        if ((isEditing || readOnly) && selectedTemplateId) {
            dispatch(fetchTemplateByIdThunk(selectedTemplateId));
        }
    }, [isEditing, readOnly, selectedTemplateId, dispatch]);

    useEffect(() => {
        if (selectedTemplate && selectedTemplate.id === selectedTemplateId) {
            setTemplateData(selectedTemplate);
        }
    }, [selectedTemplate, selectedTemplateId]);

    useEffect(() => {
        return () => {
            dispatch(clearSelectedTemplate());
        };
    }, [dispatch]);

    const handleChange = <K extends keyof CreateTemplate>(field: K, value: CreateTemplate[K]) => {
        if (!readOnly) {
            setTemplateData((prev) => ({ ...prev, [field]: value }));
        }
    };

    const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const [hours, minutes] = e.target.value.split(":").map(Number);
        const totalMinutes = hours * 60 + minutes;
        handleChange("duration", totalMinutes);
    };

    const handleSubmit = async () => {
        if (readOnly) return;

        const formattedResolutions = (templateData.resolutions ?? []).map(r => ({
            resolutionId: r.resolutionId,
            type: r.type,
        }));

        const formattedData = {
            ...templateData,
            resolutions: formattedResolutions,
            additionalFields: (templateData.additionalFields ?? []).map((f) => ({
                ...f,
                url: (f.fieldType === "LINK" || f.fieldType === "BUTTON") ? f.url ?? undefined : undefined,
                valueListId: f.fieldType === "SELECTOR" ? f.valueListId ?? undefined : undefined,
            })),
            reportFields: (templateData.reportFields ?? []).map((f) => ({
                ...f,
                url: (f.fieldType === "LINK" || f.fieldType === "BUTTON") ? f.url ?? undefined : undefined,
                valueListId: f.fieldType === "SELECTOR" ? f.valueListId ?? undefined : undefined,
            })),
        };

        try {
            if (isEditing && selectedTemplateId) {
                await dispatch(updateTemplateThunk({
                    id: selectedTemplateId,
                    data: formattedData as UpdateTemplate
                }));
            } else {
                await dispatch(createTemplateThunk(formattedData as CreateTemplate));
                await dispatch(fetchTemplatesThunk());
            }

            navigate("/templates");
        } catch (error) {
            console.error("❌ Submit error:", error);
        }
    };

    const back = () => navigate("/templates");

    return (
        <section className="settings">
            <div className="container container-small">
                <div className="section-top">
                    <h1 className="section-title">Настройки</h1>
                </div>

                <div className="template-add settings__inner">
                    <div className="settings__inner-title section-top">
                        <h2 className="section-title">
                            {readOnly ? "Просмотр Шаблона" : isEditing ? "Редактировать Шаблон" : "Создать Шаблон"}
                        </h2>
                        <button className="settings__back" onClick={back}></button>
                    </div>

                    {/* Название */}
                    <div className="template-add__item">
                        <div className="template-add__name">
                            Название шаблона *
                            <Dialog>
                                <p>Название шаблона отображается в мобильном приложении.</p>
                            </Dialog>
                        </div>
                        <input
                            className="standart-input"
                            placeholder="Название"
                            value={templateData.name || ""}
                            onChange={(e) => handleChange("name", e.target.value)}
                            required
                            disabled={readOnly}
                        />
                    </div>

                    {/* Тип наряда */}
                    <div className="template-add__item">
                        <div className="template-add__name">Тип наряда</div>
                        <select
                            className="standart-select"
                            value={templateData.orderTypeId ?? ""}
                            onChange={(e) =>
                                handleChange("orderTypeId", e.target.value === "" ? null : parseInt(e.target.value))
                            }
                            disabled={readOnly}
                        >
                            <option value="">Не выбрано</option>
                            {orderTypes.map((o) => (
                                <option key={o.id} value={o.id}>{o.name}</option>
                            ))}
                        </select>
                    </div>

                    {/* Описание */}
                    <div className="template-add__item">
                        <div className="template-add__name">Описание</div>
                        <textarea
                            className="standart-textarea"
                            placeholder="Добавить описание..."
                            value={templateData.description || ""}
                            onChange={(e) => handleChange("description", e.target.value)}
                            disabled={readOnly}
                        />
                    </div>

                    {/* Длительность */}
                    <div className="template-add__item">
                        <div className="template-add__name">Длительность</div>
                        <input
                            type="time"
                            className="standart-input"
                            value={`${String(Math.floor((templateData.duration ?? 0) / 60)).padStart(2, "0")}:${String((templateData.duration ?? 0) % 60).padStart(2, "0")}`}
                            onChange={handleDurationChange}
                            disabled={readOnly}
                        />
                    </div>

                    {/* Навыки исполнителя */}
                    <div className="template-add__item">
                        <div className="template-add__name">Навыки исполнителя</div>
                        <select
                            className="standart-select"
                            value={templateData.executorSkillId ?? ""}
                            onChange={(e) =>
                                handleChange("executorSkillId", e.target.value === "" ? null : parseInt(e.target.value))
                            }
                            disabled={readOnly}
                        >
                            <option value="">Не выбрано</option>
                            {executorSkills.map((s) => (
                                <option key={s.id} value={s.id}>{s.name}</option>
                            ))}
                        </select>
                    </div>

                    {/* Участок */}
                    <div className="template-add__item">
                        <div className="template-add__name">Участок</div>
                        <select
                            className="standart-select"
                            value={templateData.areaId ?? ""}
                            onChange={(e) =>
                                handleChange("areaId", e.target.value === "" ? null : parseInt(e.target.value))
                            }
                            disabled={readOnly}
                        >
                            <option value="">Не выбрано</option>
                            {areas.map((a) => (
                                <option key={a.id} value={a.id}>{a.name}</option>
                            ))}
                        </select>
                    </div>

                    {/* Исполнитель */}
                    <div className="template-add__item">
                        <div className="template-add__name">Исполнитель</div>
                        <select
                            className="standart-select"
                            value={templateData.teamId ?? ""}
                            onChange={(e) =>
                                handleChange("teamId", e.target.value === "" ? null : parseInt(e.target.value))
                            }
                            disabled={readOnly}
                        >
                            <option value="">Не выбрано</option>
                            {teams.map((t) => (
                                <option key={t.id} value={t.id}>{t.name}</option>
                            ))}
                        </select>
                    </div>

                    {/* Blocks */}
                    <DynamicFields
                        fields={templateData.additionalFields || []}
                        onUpdate={(fields) => handleChange("additionalFields", fields)}
                        valueLists={valueLists}
                        readOnly={readOnly}
                    />

                    <ReportFields
                        fields={templateData.reportFields || []}
                        onUpdate={(fields) => handleChange("reportFields", fields)}
                        valueLists={valueLists}
                        readOnly={readOnly}
                    />

                    <Resolutions
                        selectedResolutions={templateData.resolutions || []}
                        onUpdateResolutions={(res) => handleChange("resolutions", res)}
                        readOnly={readOnly}
                    />

                    {!readOnly && (
                        <div className="template-footer__btn">
                            <button className="standart-btn purple-btn" onClick={handleSubmit}>
                                {isEditing ? "Обновить" : "Добавить"}
                            </button>
                            <button className="standart-btn opacity-btn" onClick={back}>Отменить</button>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default TemplateForm;
