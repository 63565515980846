// src/redux/store.ts
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/userSlice';
import authReducer from './auth/authSlice';
import workOrderReducer from './workOrder/workOrderSlice'; // Import the workOrder reducer
import teamReducer from './team/teamSlice';
import areaReducer from './area/areaSlice'
import resolutionReducer from './resolutions/resolutionsSlice'

const store = configureStore({
    reducer: {
        auth: authReducer,             // Handles authentication state
        workOrder: workOrderReducer,    // Handles work orders state
        users: userReducer,
        teams: teamReducer,
        areas: areaReducer,
        resolutions: resolutionReducer,
        // Add other reducers here as needed
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store };
