import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import {
    fetchOrderedAreas,
    createArea,
    updateArea,
    deleteArea,
    reorderAreas,
} from "../../redux/area/areaThunks";
import { Area } from "../../redux/area/areaTypes";
import Dialog from "../Dialog/Dialog";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "./AreaList.scss";

const ITEM_TYPE = "Area";

interface AreaItemProps {
    area: Area;
    index: number;
    moveArea: (dragIndex: number, hoverIndex: number) => void;
    onDelete: (id: number) => void;
    onSave: (area: Area) => void;
}

const AreaItem: React.FC<AreaItemProps> = ({ area, index, moveArea, onDelete, onSave }) => {
    const [{ isDragging }, dragRef] = useDrag({
        type: ITEM_TYPE,
        item: { index, id: area.id },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, dropRef] = useDrop({
        accept: ITEM_TYPE,
        hover: (item: { index: number }) => {
            if (item.index !== index) {
                moveArea(item.index, index);
                item.index = index; // Update item's index after move
            }
        },
    });

    const [isEditing, setIsEditing] = useState(false);
    const [editedName, setEditedName] = useState(area.name);

    const handleSave = () => {
        onSave({ ...area, name: editedName });
        setIsEditing(false);
    };

    const handleCancelEdit = () => {
        setEditedName(area.name);
        setIsEditing(false);
    };

    return (
        <li ref={(node) => dragRef(dropRef(node))} className="area__item-container" style={{ opacity: isDragging ? 0.5 : 1 }}>
            <div className="area__item">
                {isEditing ? (
                    <div className="area__item-edit">
                        <div className="area__item-edit__top">
                            <p className="area__item-edit__title">Редактирование участка</p>
                            <button onClick={handleCancelEdit} className="area__item-edit__cancel">
                                Отменить
                            </button>
                        </div>
                        <p className="area__item-edit__input-name">Название *</p>
                        <input
                            className="area__item-edit__input"
                            value={editedName}
                            onChange={(e) => setEditedName(e.target.value)}
                        />
                    </div>
                ) : (
                    <p className="area__item-name">{area.name}</p>
                )}

                {isEditing ? (
                    <button className="settings__change settings__change_save" onClick={handleSave}></button>
                ) : (
                    <button className="settings__change settings__change_edit" onClick={() => setIsEditing(true)}></button>
                )}
                <button className="settings__change settings__change_trash" onClick={() => onDelete(area.id)}></button>
            </div>
        </li>
    );
};

const AreaList: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { areas, loading, error } = useSelector((state: RootState) => state.areas);
    const [newAreaName, setNewAreaName] = useState("");

    useEffect(() => {
        dispatch(fetchOrderedAreas());
    }, [dispatch]);

    const handleAddArea = () => {
        if (newAreaName.trim()) {
            dispatch(createArea({ name: newAreaName }));
            setNewAreaName("");
        }
    };

    const handleDeleteArea = (id: number) => {
        dispatch(deleteArea(id));
    };

    const handleSaveArea = (area: Area) => {
        dispatch(updateArea({ id: area.id, area: { name: area.name } }));
    };

    const moveArea = (dragIndex: number, hoverIndex: number) => {
        const reorderedAreas = [...areas];
        const [draggedArea] = reorderedAreas.splice(dragIndex, 1);
        reorderedAreas.splice(hoverIndex, 0, draggedArea);

        // **Important Fix**: Update Redux state before calling the backend
        const updatedAreas = reorderedAreas.map((area, i) => ({ ...area, order: i + 1 }));

        dispatch(reorderAreas(updatedAreas.map((area) => area.id))); // Save order in backend
        dispatch({ type: "area/setAreasOrder", payload: updatedAreas }); // Update Redux order state
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <section className="settings">
                <div className="container container-small">
                    <div className="section-top">
                        <h1 className="section-title">Настройки</h1>
                    </div>

                    <div className="order-type settings__inner">
                        <h2 className="section-title settings__inner-title">
                            Участки
                            <Dialog>
                                <span>Участки</span>
                                <p>
                                    Это группа адресов, обслуживаемых одной командой. Обычно это город или регион. Укажите участок в наряде,
                                    чтобы сотрудники с подходящим участком были первыми в списке. Загрузите GeoJSON-файл для автоматического
                                    выбора участка.
                                </p>
                            </Dialog>
                        </h2>

                        {loading ? (
                            <div className="loading-icon">
                                <div></div>
                            </div>
                        ) : error ? (
                            <p className="order-type__error">Ошибка: {error}</p>
                        ) : (
                            <ul className="area__list">
                                {areas.map((area, index) => (
                                    <AreaItem
                                        key={area.id}
                                        area={area}
                                        index={index}
                                        moveArea={moveArea}
                                        onDelete={handleDeleteArea}
                                        onSave={handleSaveArea}
                                    />
                                ))}
                            </ul>
                        )}

                        <input
                            className="standart-input"
                            value={newAreaName}
                            onChange={(e) => setNewAreaName(e.target.value)}
                        />
                        <button className="area__btn" onClick={handleAddArea}>
                            Добавить участок
                        </button>
                    </div>
                </div>
            </section>
        </DndProvider>
    );
};

export default AreaList;
