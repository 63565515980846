import React, { useState } from 'react';


const AddContact: React.FC = () => {
    const [contacts, setContacts] = useState<any[]>([]); 

    const addContact = () => {
        setContacts([...contacts, {}]); 
    };
    const removeContact = (index: number) => {
        const newContacts = [...contacts];
        newContacts.splice(index, 1);
        setContacts(newContacts);
    };

    const handleInputChange = (index: number, field: string, value: string) => {
        const newContacts = [...contacts];
        newContacts[index] = { ...newContacts[index], [field]: value };
        setContacts(newContacts);
    };

    return (
        <>
         {contacts.map((contact, index) => (
                <div key={index} className="jobs-popup__client-item jobs-popup__client-contact">
                    <div className="jobs-popup__client-item_flex jobs-popup__client-item_flex_2">
                        <div>
                            <input
                                type='text'
                                placeholder='Имя'
                                className='standart-input'
                                value={contact.name || ''}
                                onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                            />
                        </div>
                        <div>
                            <input
                                type='tel'
                                placeholder='+7'
                                className='standart-input'
                                value={contact.phone || ''}
                                onChange={(e) => handleInputChange(index, 'phone', e.target.value)}
                            />
                        </div>
                    </div>
                    <button className='jobs-popup__client-contact__delete' onClick={() => removeContact(index)}></button>
                </div>
            ))}     
            <button className='jobs-popup__client-contact__add' onClick={addContact}>Добавить контакт</button>
           
</>
    );
};

export default AddContact;