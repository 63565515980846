import React from 'react';
import UsersList from '../components/Users/UsersList';

const Employees: React.FC = () => {
    return (
        <>
            <UsersList/>
        </>
    );
};

export default Employees;