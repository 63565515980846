import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './LoginForm.scss';
import { useAuth } from '../../hooks/useAuth';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Lock from './../../img/icons/relock.svg';


const validationSchema = Yup.object({
    email: Yup.string().required('Поле не может быть пустым'),
    password: Yup.string().required('Поле не может быть пустым'),
});

const LoginForm: React.FC = () => {
    const { login } = useAuth();

    // State to toggle password visibility
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const formik = useFormik({
        initialValues: { email: '', password: '', rememberMe: false },
        validationSchema,
        onSubmit: async (values) => {
            try {
                await login(values.email, values.password, values.rememberMe);
                setError(null);
            } catch (error) {
                // Handle the error based on its type
                if (error instanceof Error) {
                    setError(error.message); // assuming error.message is the desired string
                } else if (typeof error === 'string') {
                    setError(error); // in case the error is already a string
                } else {
                    setError('Неверный Email или пароль'); // fallback for unexpected types
                }
            }
        },
    });
    return (
        <section className='login'>
            <div className='container login__container'>
                <form onSubmit={formik.handleSubmit} className="login__form">
                    <div className='login__item'>
                        <picture className='login__icon'>
                            <img src={Lock} alt={'lock icon'}/>
                        </picture>
                        <h1 className='login__title'>Введите данные для входа</h1>
                        <div className={`login__input${error || (formik.touched.email && formik.errors.email) ? ' login__error' : ''}`}>
                            <div className="login__input-wrapper login__email">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder='email'
                                />
                                <span className="login__error-icon"></span>
                            </div>

                            {formik.touched.email && formik.errors.email ? (
                                <div className="error login__error-input">{formik.errors.email}</div>
                            ) : null}
                        </div>
                        <div className={`login__input${error || (formik.touched.password && formik.errors.password) ? ' login__error' : ''}`}>
                            <div className="login__input-wrapper login__password">
                                <input
                                    id="password"
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder='пароль'
                                />
                                <span className="login__error-icon"></span>
                            </div>

                            {formik.touched.password && formik.errors.password ? (
                                <div className="error login__error-input">{formik.errors.password}</div>
                            ) : null}
                        </div>
                        <label className='login__remember'>
                            <label className='login__remember-wrapper'>
                                <input
                                    type="checkbox"
                                    name="rememberMe"
                                    checked={formik.values.rememberMe}
                                    onChange={formik.handleChange}
                                />
                                <span className='login__remebmer-switch'></span>
                            </label>

                            <div className='login__remember-text'>
                                <span>Запомнить меня</span>
                                <p>Сохранить мой логин и пароль на месяц.</p>
                            </div>
                        </label>
                        {error && <div className="error">{error}</div>}
                        <button type="submit" className='standart-btn purple-btn'>
                            Войти
                        </button>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default LoginForm;