// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client__history-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #EAECF0;
}
.client__history-table thead th {
  padding: 13px 24px;
  background-color: #EAECF0;
  color: #667085;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
}
.client__history-table tbody tr {
  border-top: 1px solid #EAECF0;
  font-size: 14px;
}
.client__history-table tbody tr td {
  padding: 13px 24px;
  color: #667085;
  font-size: 14px;
}
.client__history-table tbody tr td.client__history-time {
  color: #101828;
}
.client__history-table tbody tr td.client__history-time span {
  display: inline-block;
  margin-left: 8px;
  color: #667085;
}

.client__status {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
}

.client__history-status {
  font-size: 12px;
  padding: 2px 8px;
  font-weight: 500;
  border-radius: 8px;
}
.client__history-status_error {
  background-color: #FEF3F2;
  color: #B42318;
}
.client__history-status_ok {
  background-color: #e4fdef;
  color: #00ac4d;
}`, "",{"version":3,"sources":["webpack://./src/components/Clients/Client.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,yBAAA;EACA,yBAAA;AACJ;AACQ;EACI,kBAAA;EACA,yBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AACZ;AAGQ;EACI,6BAAA;EACA,eAAA;AADZ;AAEY;EACI,kBAAA;EACA,cAAA;EACA,eAAA;AAAhB;AACgB;EACI,cAAA;AACpB;AAAoB;EACI,qBAAA;EACA,gBAAA;EACA,cAAA;AAExB;;AAKA;EACI,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,QAAA;AAFJ;;AAIA;EACI,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;AADJ;AAEI;EACI,yBAAA;EACA,cAAA;AAAR;AAEI;EACI,yBAAA;EACA,cAAA;AAAR","sourcesContent":[".client__history-table {\n    width: 100%;\n    border-collapse: collapse;\n    border: 1px solid #EAECF0;\n    & thead {\n        th {\n            padding: 13px 24px;\n            background-color: #EAECF0;\n            color: #667085;\n            font-size: 12px;\n            font-weight: 500;\n            text-align: left;\n        }\n    }\n    & tbody {\n        & tr {\n            border-top: 1px solid #EAECF0;\n            font-size: 14px;\n            & td {\n                padding: 13px 24px;\n                color: #667085;\n                font-size: 14px;\n                &.client__history-time {\n                    color: #101828;\n                    & span {\n                        display: inline-block;\n                        margin-left: 8px;\n                        color: #667085;\n                    }\n                }\n            }\n        }\n    }\n}\n.client__status {\n    display: flex;\n    align-items: flex-start;\n    flex-direction: column;\n    gap: 4px;\n}\n.client__history-status {\n    font-size: 12px;\n    padding: 2px 8px;\n    font-weight: 500;\n    border-radius: 8px;\n    &_error {\n        background-color: #FEF3F2;\n        color: #B42318;\n    }\n    &_ok {\n        background-color: #e4fdef;\n        color: #00ac4d;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
