import React, { useState } from 'react';
import LocationTracker from './LocationTracker';

const TeamLocation: React.FC = () => {
    const [mapVisible, setMapVisible] = useState(false);

    const toggleMap = () => {
        setMapVisible(prevVisible => !prevVisible);
    };

    return (
        <div>
            <button
                type="button"
                onClick={toggleMap}
                style={{ marginBottom: '10px', padding: '10px 20px', cursor: 'pointer' }}
            >
                {mapVisible ? 'Закрыть карту' : 'Показать бригады'}
            </button>

            {mapVisible && (
                <div style={{ marginTop: '20px' }}>
                    <LocationTracker />
                </div>
            )}
        </div>
    );
};

export default TeamLocation;
