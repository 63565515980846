import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUsers, deleteUserById } from '../../redux/user/userThunks';
import { RootState, AppDispatch } from '../../redux/store';
import { Button, Table, TableRow, TableCell, IconButton, TableHead, TableBody } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useNavigate } from 'react-router-dom';

const UsersList: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const users = useSelector((state: RootState) => state.users.users);
    const role = useSelector((state: RootState) => state.auth.user?.role);
    const navigate = useNavigate();

    // State for sorting users by name
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

    useEffect(() => {
        dispatch(fetchUsers());
    }, [dispatch]);

    // Sorting logic based on full name
    const sortedUsers = [...users].sort((a, b) => {
        if (sortOrder === 'asc') {
            return a.fullName.localeCompare(b.fullName);
        } else {
            return b.fullName.localeCompare(a.fullName);
        }
    });

    const toggleSortOrder = () => {
        setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };

    const handleEdit = (id: number) => {
        navigate(`/users/${id}/edit`);
    };

    const handleDelete = (id: number, fullName: string) => {
        if (window.confirm(`Are you sure you want to delete user ${fullName}?`)) {
            dispatch(deleteUserById(id))
                .then(() => {
                    dispatch(fetchUsers()); // Refresh the list after deletion
                    navigate('/employees'); // Redirect to the list after deletion
                })
                .catch((error) => {
                    console.error('Failed to delete user:', error);
                    // Optionally show an error message to the user
                });
        }
    };

    // Check if the user is SUPER_ADMIN
    const isSuperAdmin = role === 'SUPER_ADMIN';

    return (
        <div>
            <h2>Employees</h2>
            <h3>Total Employees: {users.length}</h3>

            {/* Show "Add New Employee" button only for SUPER_ADMIN */}
            {isSuperAdmin && (
                <Button
                    onClick={() => navigate('/users/new')}
                    variant="contained"
                    color="primary"
                    style={{ marginBottom: '10px' }}
                >
                    Add New Employee
                </Button>
            )}

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Full Name
                            {/* Sort Button */}
                            <IconButton onClick={toggleSortOrder}>
                                {sortOrder === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                            </IconButton>
                        </TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Mobile Phone</TableCell>
                        <TableCell>Options</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedUsers.map((user) => (
                        <TableRow key={user.id} onClick={() => navigate(`/users/${user.id}`)}>
                            <TableCell>{user.fullName}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.mobilePhone}</TableCell>
                            <TableCell>
                                {/* Show Edit and Delete buttons only for SUPER_ADMIN */}
                                {isSuperAdmin && (
                                    <>
                                        <IconButton onClick={() => handleEdit(user.id)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={(e) => {
                                            e.stopPropagation(); // Prevent row click from navigating to the user's card
                                            handleDelete(user.id, user.fullName);
                                        }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default UsersList;
