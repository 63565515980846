import React, { useState, useRef, ReactNode, MouseEvent, cloneElement, ReactElement, useEffect } from 'react';
import './Popup.scss';

interface PopupProps {
    children: ReactNode;
    popupId?: string;
    closeOnClickOutside?: boolean;
    closeButtonDataAttribute?: string;
    useCustomCloseButton?: boolean;
}
const Popup: React.FC<PopupProps> = ({
    children,
    popupId,
    closeOnClickOutside = true,
    closeButtonDataAttribute = 'data-close-button',
    useCustomCloseButton = false,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const popoverRef = useRef<HTMLDivElement | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);

    const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
        setIsOpen(true);
        document.documentElement.classList.add('popup-open');
    };

    const handleClose = () => {
        setIsOpen(false);
        document.documentElement.classList.remove('popup-open');
    };
    const handleClickOutside = (event: MouseEvent) => {
        const isDateTimePickerClick = (event.target as HTMLElement)?.closest('.MuiDialog-root');
        const isDateTimePickerClick2 = (event.target as HTMLElement)?.closest('.MuiPopper-root');
        const isFancybox = (event.target as HTMLElement)?.closest('.fancybox__container') !== null;
        const isClickInsideContent = (event.target as HTMLElement)?.closest('.popup__content') !== null;
        if (
            closeOnClickOutside &&
            !isClickInsideContent &&
            !isDateTimePickerClick &&
            !isDateTimePickerClick2 &&
            !isFancybox &&
            isOpen
        ) {
            handleClose();
        }
    };
    const handleCloseBtnClick = (event: Event | React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        handleClose();
    };
    useEffect(() => {
        if (isOpen && useCustomCloseButton) {
            setTimeout(() => {
                const closeButton = popoverRef.current?.querySelector(`[${closeButtonDataAttribute}]`) as HTMLButtonElement;
                if (closeButton) {
                    closeButton.addEventListener('click', handleCloseBtnClick as unknown as EventListener);
                }
            }, 0);
    
            return () => {
                const closeButton = popoverRef.current?.querySelector(`[${closeButtonDataAttribute}]`);
                if (closeButton) {
                    closeButton.removeEventListener('click', handleCloseBtnClick as unknown as EventListener);
                }
            };
        }
    }, [isOpen, useCustomCloseButton]);
    const popupBtn = React.Children.toArray(children).find(child => {
        return React.isValidElement(child) && (child as ReactElement<{ className?: string }>).props.className?.includes('popup-btn');
    });
    const contentWithoutCloseButton = React.Children.toArray(children).filter(child => {
        return !(React.isValidElement(child) && (child as ReactElement<{ className?: string }>).props.className?.includes('popup-btn'));
    });
    const renderContent = () => (
        <>
            {!useCustomCloseButton && <button className="close-popup" onClick={handleCloseBtnClick}></button>}
            {contentWithoutCloseButton}
        </>
    );
    return (
        <>
            {popupBtn && React.isValidElement(popupBtn) ? cloneElement(popupBtn, {
                onClick: (event: MouseEvent<HTMLButtonElement>) => {
                    handleOpen(event);
                },
                ref: buttonRef,
            } as React.PropsWithChildren<{ onClick: (event: MouseEvent<HTMLButtonElement>) => void; ref: React.Ref<HTMLButtonElement> }>) : null}
            {isOpen && (
                <div
                    className="popup"
                    data-target={popupId}
                    ref={popoverRef}
                >
                    <div className="popup__body" onClick={handleClickOutside}>
                        <div className="popup__content" ref={contentRef}>
                            {renderContent()}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default Popup;
