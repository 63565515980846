import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AppDispatch, RootState } from '../../redux/store';
import { createResolutionThunk, updateResolutionThunk, fetchResolutionByIdThunk } from '../../redux/resolutions/resolutionsThunks';
import { TextField, Button, Typography, Radio, RadioGroup, FormControlLabel, Box } from '@mui/material';

type Mode = 'create' | 'edit' | 'read';

const ResolutionForm: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const modeFromState = location.state?.mode as Mode;
    const resolution = useSelector((state: RootState) => state.resolutions.selectedResolution);

    const [mode, setMode] = useState<Mode>('create');
    const [name, setName] = useState('');
    const [type, setType] = useState<'SUCCESSFUL' | 'UNSUCCESSFUL'>('SUCCESSFUL');
    const [noteRequired, setNoteRequired] = useState(false);

    useEffect(() => {
        if (id) {
            setMode(modeFromState || 'edit');
            dispatch(fetchResolutionByIdThunk(Number(id)));
        } else {
            setMode('create');
        }
    }, [id, dispatch, modeFromState]);

    useEffect(() => {
        if (resolution && mode !== 'create') {
            setName(resolution.name);
            setType(resolution.type);
            setNoteRequired(resolution.noteRequired);
        }
    }, [resolution, mode]);

    const handleSave = () => {
        const resolutionData = { name, type, noteRequired };
        if (mode === 'create') {
            dispatch(createResolutionThunk(resolutionData));
        } else if (mode === 'edit') {
            dispatch(updateResolutionThunk({ id: Number(id), data: resolutionData }));
        }
        navigate('/resolutions');
    };

    const handleCancel = () => {
        navigate('/resolutions');
    };

    const pageTitle = {
        create: 'Add a new resolution',
        edit: `Change resolution ${name}`,
        read: `View resolution ${name}`
    }[mode];

    return (
        <div>
            <Typography variant="h4">{pageTitle}</Typography>
            <Box component="form" sx={{ marginTop: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField
                    label="Resolution Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    disabled={mode === 'read'}
                    fullWidth
                />

                <Typography variant="h6">Resolution Type</Typography>
                <RadioGroup
                    row
                    value={type}
                    onChange={(e) => setType(e.target.value as 'SUCCESSFUL' | 'UNSUCCESSFUL')}
                >
                    <FormControlLabel
                        value="SUCCESSFUL"
                        control={<Radio />}
                        label="Successful"
                        disabled={mode === 'read'}
                        sx={{ fontWeight: type === 'SUCCESSFUL' ? 'bold' : 'normal' }}
                    />
                    <FormControlLabel
                        value="UNSUCCESSFUL"
                        control={<Radio />}
                        label="Unsuccessful"
                        disabled={mode === 'read'}
                        sx={{ fontWeight: type === 'UNSUCCESSFUL' ? 'bold' : 'normal' }}
                    />
                </RadioGroup>

                <Typography variant="h6">Resolution Note</Typography>
                <RadioGroup
                    row
                    value={String(noteRequired)}
                    onChange={(e) => setNoteRequired(e.target.value === 'true')}
                >
                    <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Note is optional"
                        disabled={mode === 'read'}
                        sx={{ fontWeight: noteRequired ? 'bold' : 'normal' }}
                    />
                    <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="No note"
                        disabled={mode === 'read'}
                        sx={{ fontWeight: !noteRequired ? 'bold' : 'normal' }}
                    />
                </RadioGroup>

                <Box display="flex" gap={2}>
                    {mode === 'read' ? (
                        <Button variant="contained" onClick={handleCancel}>Go Back</Button>
                    ) : (
                        <>
                            <Button variant="contained" onClick={handleSave}>Save</Button>
                            <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
                        </>
                    )}
                </Box>
            </Box>
        </div>
    );
};

export default ResolutionForm;
