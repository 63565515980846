import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    fetchClients,
    fetchClientById,
    createClient,
    updateClient,
    deleteClient,
    reverseGeocode,
    geocodeAddress,
    searchClients,
} from '../../api/clientsApi';
import {
    CreateClientDto,
    UpdateClientDto,
    GeocodeAddressDto,
    AddressDto,
    GeocodeResult,
    Client,
} from './types';

const handleGeocoding = async (address: AddressDto) => {
    if (
        address &&
        !address.latitude &&
        !address.longitude &&
        address.city &&
        address.street &&
        address.building
    ) {
        const coords = await geocodeAddress({
            city: address.city,
            street: address.street,
            building: address.building,
        });
        address.latitude = coords.latitude;
        address.longitude = coords.longitude;
    }
};

export const fetchClientsThunk = createAsyncThunk<Client[], void, { rejectValue: string }>(
    'clients/fetchAll',
    async (_, { rejectWithValue }) => {
        try {
            return await fetchClients();
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to fetch clients.');
        }
    }
);

export const searchClientsThunk = createAsyncThunk<Client[], string, { rejectValue: string }>(
    'clients/search',
    async (query, { rejectWithValue }) => {
        try {
            return await searchClients(query);
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to search clients.');
        }
    }
);

export const fetchClientByIdThunk = createAsyncThunk<Client, number, { rejectValue: string }>(
    'clients/fetchById',
    async (id, { rejectWithValue }) => {
        try {
            return await fetchClientById(id);
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to fetch client by ID.');
        }
    }
);

export const createClientThunk = createAsyncThunk<Client, CreateClientDto, { rejectValue: string }>(
    'clients/create',
    async (clientData, { rejectWithValue }) => {
        try {
            if (clientData.address) await handleGeocoding(clientData.address);
            return await createClient(clientData);
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to create client.');
        }
    }
);

export const updateClientThunk = createAsyncThunk<
    Client,
    { id: number; clientData: UpdateClientDto },
    { rejectValue: string }
>('clients/update', async ({ id, clientData }, { rejectWithValue }) => {
    try {
        if (clientData.address) await handleGeocoding(clientData.address);
        return await updateClient(id, clientData);
    } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || 'Failed to update client.');
    }
});

export const deleteClientThunk = createAsyncThunk<number, number, { rejectValue: string }>(
    'clients/delete',
    async (id, { rejectWithValue }) => {
        try {
            await deleteClient(id);
            return id;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to delete client.');
        }
    }
);

export const geocodeAddressThunk = createAsyncThunk<GeocodeResult, GeocodeAddressDto, { rejectValue: string }>(
    'clients/geocode',
    async (addressData, { rejectWithValue }) => {
        try {
            return await geocodeAddress(addressData);
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to geocode address.');
        }
    }
);

export const reverseGeocodeThunk = createAsyncThunk<AddressDto, { latitude: number; longitude: number }, { rejectValue: string }>(
    'clients/reverseGeocode',
    async ({ latitude, longitude }, { rejectWithValue }) => {
        try {
            return await reverseGeocode(latitude, longitude);
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to reverse geocode coordinates.');
        }
    }
);
