import React, { useState, useEffect } from "react";

const AddressInputWithMap: React.FC = () => {
  const [inputValue, setInputValue] = useState<string>(""); // Адрес в инпуте
  const [isMapVisible, setIsMapVisible] = useState<boolean>(false); // Флаг отображения карты
  const [mapInstance, setMapInstance] = useState<any>(null); // Экземпляр карты
  const [placemark, setPlacemark] = useState<any>(null); // Единственная метка
  const [ymapsInstance, setYmapsInstance] = useState<any>(null); // Экземпляр ymaps

  // Функция загрузки Yandex Maps API
  const loadYandexMaps = () => {
    return new Promise((resolve, reject) => {
      if (window.ymaps) {
        resolve(window.ymaps);
        return;
      }
      const script = document.createElement("script");
      script.src = `https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=ВАШ_API_КЛЮЧ`;
      script.onload = () => {
        if (window.ymaps) {
          window.ymaps.ready(() => resolve(window.ymaps));
        } else {
          reject(new Error("Ошибка загрузки Yandex Maps API"));
        }
      };
      script.onerror = () => reject(new Error("Ошибка загрузки Yandex Maps API"));
      document.body.appendChild(script);
    });
  };

  // Функция для переключения карты
  const toggleMap = () => {
    setIsMapVisible((prev) => !prev);

    if (!isMapVisible) {
      loadYandexMaps().then((ymaps: any) => {
        setYmapsInstance(ymaps);

        // Удаляем старую карту перед созданием новой
        if (mapInstance) {
          mapInstance.destroy();
          setMapInstance(null);
        }

        setTimeout(() => {
          const map = new ymaps.Map("map", {
            center: placemark ? placemark.geometry.getCoordinates() : [55.751244, 37.618423], // Координаты Москвы по умолчанию
            zoom: 10,
          });

          map.events.add("click", async (e: any) => {
            const coords = e.get("coords");
            removeAllPlacemarks(map);
            updatePlacemark(ymaps, map, coords);
            const address = await getAddressFromCoords(ymaps, coords);
            setInputValue(address);
          });

          if (placemark) {
            updatePlacemark(ymaps, map, placemark.geometry.getCoordinates());
          }

          setMapInstance(map);
        }, 100);
      });
    } else {
      if (mapInstance) {
        mapInstance.destroy();
        setMapInstance(null);
      }
    }
  };

  // Функция для удаления ВСЕХ меток перед добавлением новой
  const removeAllPlacemarks = (map: any) => {
    map.geoObjects.removeAll();
    setPlacemark(null);
  };

  // Функция для установки или перемещения ОДНОЙ метки на карте
  const updatePlacemark = (ymaps: any, map: any, coords: number[]) => {
    const newPlacemark = new ymaps.Placemark(coords, {}, { draggable: true });
    map.geoObjects.add(newPlacemark);

    newPlacemark.events.add("dragend", async () => {
      const newCoords = newPlacemark.geometry.getCoordinates();
      const address = await getAddressFromCoords(ymaps, newCoords);
      setInputValue(address);
    });

    setPlacemark(newPlacemark);
  };

  // Функция для получения адреса по координатам (геокодинг)
  const getAddressFromCoords = async (ymaps: any, coords: number[]) => {
    return new Promise<string>((resolve) => {
      ymaps.geocode(coords).then((res: any) => {
        const firstGeoObject = res.geoObjects.get(0);
        if (firstGeoObject) {
          resolve(firstGeoObject.getAddressLine());
        } else {
          resolve("Адрес не найден");
        }
      });
    });
  };

  return (
    <div className="address-map">
      <input
        className="standart-input"
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder="Введите адрес"
      />
      <button className="address-map-btn" onClick={toggleMap}>
        {isMapVisible ? "Скрыть карту" : "Показать на карте"}
      </button>

      {isMapVisible && <div id="map" style={{ width: "100%", height: "400px", marginTop: "20px" }}></div>}
    </div>
  );
};

export default AddressInputWithMap;
