import axiosInstance from './axiosConfig';
import { CreateAreaPayload } from '../redux/area/areaTypes';

// Fetch all areas
export const getAreas = async () => {
    const response = await axiosInstance.get('/areas');
    return response.data;
};

// Create a new area
export const createArea = async (data: CreateAreaPayload) => {
    const response = await axiosInstance.post('/areas', data);
    return response.data;
};