import React from "react";
import Dialog from '../Dialog/Dialog';
import { Tabs, Tab } from '../Tabs/Tabs';
import AddContact from '../AddContact/AddContact';
import './Client.scss';

const Client: React.FC = () => {
return (   
    <><div className='jobs-popup__top'>
    <p className="popup__title">Редактирование клиента</p>
    <div className="jobs-popup__top-btns">
 <button className="jobs-popup__top-delete"></button>
 <button className="jobs-popup__top-btn standart-btn purple-btn">Сохранить и закрыть</button>
 <button className="jobs-popup__top-btn standart-btn opacity-btn" data-close-button="true">Отменить</button>
 </div>
 </div>
<div className="client">
      <ul className="client__list">
        <li className="client__item">
          <p className="client__name">
          Клиент
          </p>
          <div className="client__content">
            <div className="client__content-item">
              <span>Имя *</span>
              <input type="text"  className='standart-input'/>
            </div>
            <div className="client__content-item">
              <span>Отчество</span>
              <input type="text"  className='standart-input'/>
            </div>
            <div className="client__content-item">
              <span>Фамилия</span>
              <input type="text"  className='standart-input'/>
            </div>
          </div>
        </li>
        <li className="client__item">
          <p className="client__name">
          Мобильный телефон
          </p>
          <div className="client__content">
          <div className="client__content-item">
            <input type="tel" className="standart-input" placeholder='+7' />
            </div>
          </div>
          </li>
          <li className="client__item">
          <p className="client__name">
          Адрес
          </p>
          <div className="client__content">
          <div className="client__content-item client__content-item_100">
          <span>Город, улица, дом</span>
          <input type="text"  className='standart-input'/>
            </div>
            <div className="client__content-item">
          <span>Квартира/офис</span>
          <input type="text"  className='standart-input'/>
            </div>
            <div className="client__content-item">
          <span>Этаж</span>
          <input type="text"  className='standart-input'/>
            </div>
            <div className="client__content-item">
          <span>Подъезд</span>
          <input type="text"  className='standart-input'/>
            </div>
          </div>
          </li>
          <li className="client__item">
          <div className="client__name">
          Участок <Dialog><p>Тут текст</p></Dialog>
          </div>
          <div className="client__content">
          <div className="client__content-item">
            <select className="standart-select" >
              <option value="Не указано" defaultChecked>Не указано</option>
              <option value="Вариант 1" defaultChecked>Вариант 1</option>
              <option value="Вариант 2" defaultChecked>Вариант 2</option>
              </select>
            </div>
          </div>
          </li>
          <li className="client__item">
          <p className="client__name">
          Описание адреса
          </p>
          <div className="client__content">
            <textarea className='standart-textarea' placeholder='Добавить описание...'></textarea>
          </div>
          </li>
          <li className="client__item">
          <p className="client__name">
          Контакты
          </p>
          <div className="client__content">
                 <div className="client__content-item">          <span>Имя</span>
                 <input type="text"  className='standart-input'/></div>
                 <div className="client__content-item">          <span>Телефон</span>
                 <input type="text"  className='standart-input'/></div>
                 <div className="client__content-item client__content-item_100">
                 <AddContact />
                 </div>
          </div>
          </li>
      </ul>
      <div className="clinet__bottom">
        <div className="client__history">
        <Tabs>
      <Tab label="Наряды">
        <table className="client__history-table">
          <thead>
            <tr>
              <th>№ </th>
              <th>Запланирован на</th>
              <th>Шаблон</th>
              <th>Исполнитель</th>
              <th>Состояние и резолюция</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>247505</td>
              <td className="client__history-time">10.12.2024 <span>10:15</span></td>
              <td>Первичный выезд</td>
              <td>Не назначен</td>
              <td className="client__status"><span className="client__history-status client__history-status_error">Отменен</span> Клиент не вышел на связь (не отвечает)</td>
            </tr>
            <tr>
              <td>247505</td>
              <td className="client__history-time">10.12.2024 <span>10:15</span></td>
              <td>Первичный выезд</td>
              <td>Не назначен</td>
              <td className="client__status"><span className="client__history-status client__history-status_ok">Принят</span> Клиент заплатил деньги</td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab label="Повторяющиеся наряды">
      <table className="client__history-table">
          <thead>
            <tr>
              <th>№ </th>
              <th>Запланирован на</th>
              <th>Шаблон</th>
              <th>Исполнитель</th>
              <th>Состояние и резолюция</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>247505</td>
              <td className="client__history-time">10.12.2024 <span>10:15</span></td>
              <td>Первичный выезд</td>
              <td>Не назначен</td>
              <td className="client__status"><span className="client__history-status client__history-status_error">Отменен</span> Клиент не вышел на связь (не отвечает)</td>
            </tr>
            
          </tbody>
        </table>
      </Tab>
    </Tabs>
        </div>
        <p className="client__date">
        Добавлен 09.12.2024 в 04:33, внешний идентификатор: contact-4254010
        </p>
      </div>
      
</div></>

)
}

export default Client;