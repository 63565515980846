import React from 'react';
import LegalEntitiesList from '../components/LegalEntities/LegalEntitiesList';

export const LegalEntities = () => {
    return (
        <div>
            <LegalEntitiesList />
        </div>
    );
};
