import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchResolutionsThunk, fetchResolutionByIdThunk, createResolutionThunk, updateResolutionThunk, deleteResolutionThunk } from './resolutionsThunks';

interface Resolution {
    id: number;
    name: string;
    noteRequired: boolean;
    type: 'SUCCESSFUL' | 'UNSUCCESSFUL';
}

interface ResolutionsState {
    resolutions: Resolution[];
    selectedResolution: Resolution | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: ResolutionsState = {
    resolutions: [],
    selectedResolution: null,
    status: 'idle',
    error: null,
};

const resolutionsSlice = createSlice({
    name: 'resolutions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Handle fetch all resolutions
            .addCase(fetchResolutionsThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchResolutionsThunk.fulfilled, (state, action: PayloadAction<Resolution[]>) => {
                state.status = 'succeeded';
                state.resolutions = action.payload;
                state.error = null;
            })
            .addCase(fetchResolutionsThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Failed to fetch resolutions';
            })

            // Handle fetch resolution by ID
            .addCase(fetchResolutionByIdThunk.fulfilled, (state, action: PayloadAction<Resolution>) => {
                state.selectedResolution = action.payload;
                state.error = null;
            })

            // Handle create resolution
            .addCase(createResolutionThunk.fulfilled, (state, action: PayloadAction<Resolution>) => {
                state.resolutions.unshift(action.payload); // Add new resolution to the start of the list
                state.error = null;
            })

            // Handle update resolution
            .addCase(updateResolutionThunk.fulfilled, (state, action: PayloadAction<Resolution>) => {
                const index = state.resolutions.findIndex(res => res.id === action.payload.id);
                if (index !== -1) state.resolutions[index] = action.payload;
                state.error = null;
            })

            // Handle delete resolution
            .addCase(deleteResolutionThunk.fulfilled, (state, action: PayloadAction<number>) => {
                state.resolutions = state.resolutions.filter(res => res.id !== action.payload);
                state.error = null;
            });
    },
});

export default resolutionsSlice.reducer;
