import React, { useState, useRef, useEffect } from "react";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TemplateField, FieldType } from "../../redux/templates/types";
import Dialog from "../Dialog/Dialog";
import CustomPopover from "../CustomPopover/CustomPopover";
import { dynamicFieldTypes } from "./fieldTypes";

interface DynamicFieldsProps {
    fields: TemplateField[];
    onUpdate: (fields: TemplateField[]) => void;
    valueLists: { id: number; name: string }[];
    readOnly?: boolean;
}

const ITEM_TYPE = "FieldItem";

interface FieldItemProps {
    field: TemplateField;
    index: number;
    moveField: (from: number, to: number) => void;
    handleRemoveField: (index: number) => void;
    handleUpdateField: (index: number, updates: Partial<TemplateField>) => void;
    valueLists: { id: number; name: string }[];
    readOnly?: boolean;
}

const FieldItem: React.FC<FieldItemProps> = ({
                                                 field,
                                                 index,
                                                 moveField,
                                                 handleRemoveField,
                                                 handleUpdateField,
                                                 valueLists,
                                                 readOnly = false,
                                             }) => {
    const ref = useRef<HTMLDivElement>(null);

    const [{ isDragging }, drag] = useDrag({
        type: ITEM_TYPE,
        item: { index },
        canDrag: !readOnly,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: ITEM_TYPE,
        hover(item: { index: number }) {
            if (!ref.current || item.index === index || readOnly) return;
            moveField(item.index, index);
            item.index = index;
        },
    });

    drag(drop(ref));

    const icon = dynamicFieldTypes.find(type => type.value === field.fieldType)?.icon || "field-icon-text";

    return (
        <div ref={ref} className="field-item" style={{ opacity: isDragging ? 0.5 : 1 }}>
            <span className={`field-icon ${icon}`} />

            <input
                type="text"
                placeholder="Название поля"
                value={field.name}
                onChange={(e) => handleUpdateField(index, { name: e.target.value })}
                className="standart-input"
                disabled={readOnly}
            />

            {field.fieldType === "SELECTOR" && (
                <select
                    value={field.valueListId ?? ""}
                    onChange={(e) => handleUpdateField(index, { valueListId: +e.target.value })}
                    className="standart-select"
                    disabled={readOnly}
                >
                    <option value="">Выберите список значений</option>
                    {valueLists.map((list) => (
                        <option key={list.id} value={list.id}>
                            {list.name}
                        </option>
                    ))}
                </select>
            )}

            {(field.fieldType === "LINK" || field.fieldType === "BUTTON") && (
                <div className="field-dialog">
                    <Dialog>
                        <p>Укажите URL для POST-запроса</p>
                    </Dialog>
                    <input
                        type="text"
                        placeholder="URL"
                        value={field.url || ""}
                        onChange={(e) => handleUpdateField(index, { url: e.target.value })}
                        className="standart-input"
                        disabled={readOnly}
                    />
                </div>
            )}

            {!readOnly && (
                <button
                    className="field-item__icon field-item__trash"
                    onClick={() => handleRemoveField(index)}
                />
            )}
        </div>
    );
};

export const DynamicFields: React.FC<DynamicFieldsProps> = ({
                                                                fields,
                                                                onUpdate,
                                                                valueLists,
                                                                readOnly = false,
                                                            }) => {
    const [localFields, setLocalFields] = useState<TemplateField[]>(fields);

    useEffect(() => {
        setLocalFields(fields);
    }, [fields]);

    const handleAddField = (fieldType: FieldType) => {
        if (readOnly) return;
        const newField: TemplateField = {
            name: "",
            fieldType,
            required: false,
            placeholder: "",
            url: "",
        };
        const updatedFields = [...localFields, newField];
        setLocalFields(updatedFields);
        onUpdate(updatedFields);
    };

    const handleRemoveField = (index: number) => {
        const updatedFields = localFields.filter((_, i) => i !== index);
        setLocalFields(updatedFields);
        onUpdate(updatedFields);
    };

    const handleUpdateField = (index: number, updates: Partial<TemplateField>) => {
        const updatedFields = localFields.map((f, i) => (i === index ? { ...f, ...updates } : f));
        setLocalFields(updatedFields);
        onUpdate(updatedFields);
    };

    const moveField = (from: number, to: number) => {
        const updatedFields = [...localFields];
        const [moved] = updatedFields.splice(from, 1);
        updatedFields.splice(to, 0, moved);
        setLocalFields(updatedFields);
        onUpdate(updatedFields);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="template__item">
                <p className="template-add__labels-title">Дополнительные поля</p>
                <p className="template-add__labels-subtitle">
                    Добавьте поля, которых не хватает в наряде.
                </p>

                <div>
                    {localFields.map((field, index) => (
                        <FieldItem
                            key={field.id ?? index}
                            field={field}
                            index={index}
                            moveField={moveField}
                            handleRemoveField={handleRemoveField}
                            handleUpdateField={handleUpdateField}
                            valueLists={valueLists}
                            readOnly={readOnly}
                        />
                    ))}
                </div>

                {!readOnly && (
                    <div className="popover-wrapper">
                        <CustomPopover
                            id="selectFieldType"
                            renderTrigger={({ onClick }) => (
                                <button
                                    className="standart-btn template-add__labels-btn"
                                    onClick={onClick}
                                >
                                    Добавить поле
                                </button>
                            )}
                        >
                            <div className="popover">
                                <ul className="field-type-selector">
                                    {dynamicFieldTypes.map((type) => (
                                        <li key={type.value}>
                                            <button onClick={() => handleAddField(type.value as FieldType)}>
                                                <span className={`field-icon ${type.icon}`}></span>
                                                {type.label}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </CustomPopover>
                    </div>
                )}
            </div>
        </DndProvider>
    );
};
