import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../redux/store';
import { Button, Table, TableRow, TableCell, IconButton, TableHead, TableBody, Modal, Box, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useNavigate } from 'react-router-dom';
import { deleteLegalEntityById, fetchLegalEntities } from '../../redux/legalEntity/legalEntitiesThunks';

const LegalEntitiesList: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const legalEntities = useSelector((state: RootState) => state.legalEntities.legalEntities);
    const role = useSelector((state: RootState) => state.auth.user?.role);
    const navigate = useNavigate();

    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [openModal, setOpenModal] = useState(false);
    const [deleteName, setDeleteName] = useState('');
    const [deleteId, setDeleteId] = useState<number | null>(null);

    useEffect(() => {
        dispatch(fetchLegalEntities());
    }, [dispatch]);

    const sortedEntities = [...legalEntities].sort((a, b) => {
        if (sortOrder === 'asc') {
            return a.name.localeCompare(b.name);
        } else {
            return b.name.localeCompare(a.name);
        }
    });

    const toggleSortOrder = () => {
        setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };

    const handleEdit = (id: number) => {
        navigate(`/legal-entities/${id}`);
    };

    const handleDelete = (id: number, name: string) => {
        setDeleteName(name);
        setDeleteId(id);
        setOpenModal(true);
    };

    const confirmDelete = () => {
        if (deleteId !== null) {
            dispatch(deleteLegalEntityById(deleteId))
                .then(() => {
                    setOpenModal(false);
                    dispatch(fetchLegalEntities());
                })
                .catch((error) => {
                    console.error('Failed to delete legal entity:', error);
                });
        }
    };

    const isSuperAdmin = role === 'SUPER_ADMIN';

    return (
        <div>
            <h2>Legal Entities</h2>
            <h3>Total: {legalEntities.length}</h3>

            {isSuperAdmin && (
                <Button
                    onClick={() => navigate('/legal-entities/new')}
                    variant="contained"
                    color="primary"
                    style={{ marginBottom: '10px' }}
                >
                    Add New Legal Entity
                </Button>
            )}

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Name
                            <IconButton onClick={toggleSortOrder}>
                                {sortOrder === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                            </IconButton>
                        </TableCell>
                        <TableCell>Created At</TableCell>
                        <TableCell>Updated At</TableCell>
                        {isSuperAdmin && <TableCell>Options</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedEntities.map((entity) => (
                        <TableRow key={entity.id} onClick={() => navigate(`/legal-entities/${entity.id}`)}>
                            <TableCell>{entity.name}</TableCell>
                            <TableCell>{new Date(entity.createdAt).toLocaleDateString()}</TableCell>
                            <TableCell>{new Date(entity.updatedAt).toLocaleDateString()}</TableCell>
                            {isSuperAdmin && (
                                <TableCell>
                                    <IconButton onClick={() => handleEdit(entity.id)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={(e) => {
                                        e.stopPropagation();
                                        handleDelete(entity.id, entity.name);
                                    }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <Box
                    sx={{
                        padding: 2,
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        width: '400px',
                        margin: '10% auto',
                        boxShadow: 24,
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        Are you sure you want to delete the legal entity <strong>{deleteName}</strong>?
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                        <Button variant="outlined" onClick={() => setOpenModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="secondary" onClick={confirmDelete}>
                            Yes, delete
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default LegalEntitiesList;