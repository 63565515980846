import React from 'react';
import Map from '../components/Map/MapCreate';

export const MapCreate = () => {
    return (
        <>
            <Map/>
        </>
    );
};
