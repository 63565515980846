// src/redux/workOrder/workOrderSlice.ts
import { createSlice } from '@reduxjs/toolkit';
import { fetchWorkOrders, fetchWorkOrderById, saveWorkOrder } from './workOrderThunks';

interface WorkOrder {
    id: number;
    description: string;
    customer: string;
    address?: string;
    latitude?: number;
    longitude?: number;
}

interface WorkOrderState {
    workOrders: WorkOrder[];
    selectedWorkOrder: WorkOrder | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: WorkOrderState = {
    workOrders: [],
    selectedWorkOrder: null,
    status: 'idle',
    error: null,
};

const workOrderSlice = createSlice({
    name: 'workOrder',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchWorkOrders.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchWorkOrders.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.workOrders = action.payload;
            })
            .addCase(fetchWorkOrderById.fulfilled, (state, action) => {
                state.selectedWorkOrder = action.payload;
            })
            .addCase(saveWorkOrder.fulfilled, (state, action) => {
                state.selectedWorkOrder = action.payload;
            });
    },
});

export default workOrderSlice.reducer;
