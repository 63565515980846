import React, { useState, useRef, useEffect } from 'react';
import Popup from '../../Popup/Popup';
import Client from '../../Clients/Client';
import './Search.scss';

interface SearchInputProps {
  setSearchResults: React.Dispatch<React.SetStateAction<string[]>>; 
  placeholder?: string;
  data: string[];
  initialValue?: string;
  isClient?: boolean;
}

const SearchInput: React.FC<SearchInputProps> = ({ setSearchResults, placeholder, data, initialValue = '', isClient  }) => {
  const [inputValue, setInputValue] = useState(initialValue);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isItemSelected, setIsItemSelected] = useState(false); 
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (initialValue) {
      handleSearch(initialValue, data);
      setIsItemSelected(true);
    }
  }, [initialValue, data]);

  const handleSearch = (query: string, array: string[]) => {
        const results = array.filter(item =>
            item.toLowerCase().includes(query.toLowerCase())
        );
        setSearchResults(results);
    };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    handleSearch(newValue, data);
    setIsDropdownVisible(newValue.length > 0 && newValue !== initialValue);
    setIsItemSelected(false); 
  };

  const handleItemClick = (item: string) => {
    setInputValue(item);
    setIsDropdownVisible(false);
    setIsItemSelected(true); 
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filteredData = data.filter(item =>
    item.toLowerCase().includes(inputValue.toLowerCase())
  );

  return (
    <div className="create-jobs__client-search">
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder={placeholder || 'Поиск...'}
        className="search-input"
      />
      {isItemSelected  && isClient &&  ( 
        <Popup popupId={'client-info'}  useCustomCloseButton={true}>
             <button className="create-jobs__client-btn popup-btn">
             </button>
            <Client />
        </Popup>
       
      )}
      {isDropdownVisible && filteredData.length > 0 && (
        <div className="create-jobs__client-search__list" ref={dropdownRef}>
          <ul>
            {filteredData.map((item, index) => (
              <li
                key={index}
                className="create-jobs__client-search__item"
                onClick={() => handleItemClick(item)}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchInput;