// src/api/userApi.ts
import axiosInstance from './axiosConfig';
import { CreateUserPayload, UpdateUserPayload } from '../redux/user/userTypes';

// Fetch all users (employees)
export const getUsers = async () => {
    const response = await axiosInstance.get('/users');
    return response.data;
};

// Fetch a single user by ID
export const getUserById = async (id: number) => {
    const response = await axiosInstance.get(`/users/${id}`);
    return response.data;
};

// Create a new user (employee)
export const createUser = async (data: CreateUserPayload) => {
    const response = await axiosInstance.post('/users', data);
    return response.data;
};

// Update an existing user (employee)
export const updateUser = async (id: number, data: UpdateUserPayload) => {
    // Filter out undefined or empty fields (including password if it's not provided)
    const payload = Object.fromEntries(
        Object.entries(data).filter(([key, value]) => value !== undefined && value !== '')
    );
    const response = await axiosInstance.patch(`/users/${id}`, payload);
    return response.data;
};

// Delete a user (employee)
export const deleteUser = async (id: number) => {
    const response = await axiosInstance.delete(`/users/${id}`);
    return response.data;
};
