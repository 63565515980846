import React from 'react';
import LoginForm from '../components/Auth/LoginForm';

const Login: React.FC = () => {
    return (
        <>
            <LoginForm />
        </>
    );
};

export default Login;
