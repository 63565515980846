import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import 'dayjs/locale/ru';
import './DatePicker.scss';
import dayjs from 'dayjs'; 

interface BasicDateTimePickerProps {
  initialDate?: dayjs.Dayjs | null; 
}

export default function BasicDateTimePicker({ initialDate }: BasicDateTimePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
      <DateTimePicker label="Выбрать дату" value={initialDate} />
    </LocalizationProvider>
  );
}