import axiosInstance from './axiosConfig';
import { AxiosError } from 'axios';

export interface Resolution {
    id: number;
    name: string;
    requiresNote: boolean;
    type: 'SUCCESSFUL' | 'UNSUCCESSFUL';
}

export interface ResolutionInput {
    name: string;
    requiresNote: boolean;
    type: 'SUCCESSFUL' | 'UNSUCCESSFUL';
}

// Helper function to extract error message safely
const getErrorMessage = (error: unknown): string => {
    if (error instanceof AxiosError && error.response) {
        return error.response.data?.message || 'An error occurred';
    }
    return 'An unexpected error occurred';
};

// Fetch all resolutions
export const fetchResolutions = async (): Promise<Resolution[]> => {
    try {
        const response = await axiosInstance.get('/resolutions');
        return response.data;
    } catch (error) {
        throw new Error(getErrorMessage(error));
    }
};

// Get a single resolution by ID
export const fetchResolutionById = async (id: number): Promise<Resolution> => {
    try {
        const response = await axiosInstance.get(`/resolutions/${id}`);
        return response.data;
    } catch (error) {
        throw new Error(getErrorMessage(error));
    }
};

// Create a new resolution
export const createResolution = async (data: ResolutionInput): Promise<Resolution> => {
    try {
        const response = await axiosInstance.post('/resolutions', {
            ...data,
            type: data.type.toUpperCase(),
        });
        return response.data;
    } catch (error) {
        throw new Error(getErrorMessage(error));
    }
};

// Update an existing resolution
export const updateResolution = async (id: number, data: ResolutionInput): Promise<Resolution> => {
    try {
        const response = await axiosInstance.patch(`/resolutions/${id}`, {
            ...data,
            type: data.type.toUpperCase(),
        });
        return response.data;
    } catch (error) {
        throw new Error(getErrorMessage(error));
    }
};

// Delete a resolution by ID
export const deleteResolution = async (id: number): Promise<void> => {
    try {
        await axiosInstance.delete(`/resolutions/${id}`);
    } catch (error) {
        throw new Error(getErrorMessage(error));
    }
};
