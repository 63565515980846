import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import "./ResponsiveAppBar.scss";
import Popup from '../Popup/Popup';
import CustomPopover from '../CustomPopover/CustomPopover';
import CreateJobs from '../Create-jobs/Create-Jobs';
import CreateJobsTemaplate from '../Create-jobs/Create-Jobs-Template';
import Logo from './../../img/icons/logo.svg';
import Avatar from './../../img/avatar/avatar.jpg';
const ResponsiveAppBar: React.FC = () => {
    const { token, logout } = useAuth();
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorElSettings, setAnchorElSettings] = useState<null | HTMLElement>(null);
    const settingsMenuRef = useRef<HTMLButtonElement | null>(null);
    const navigate = useNavigate();
    const location = useLocation();



    const isSettingsSelected = ['/templates', '/resolutions', '/areas', '/value-lists', '/executor-skill', '/order-types', '/legal-entities', '/mapcategory', '/mapcreate'].some(
        path => location.pathname === path
    );


    useEffect(() => {
        const handleTabClose = async (event: BeforeUnloadEvent) => {
            if (token) {
                event.preventDefault();
                await logout();
            }
        };

        window.addEventListener('beforeunload', handleTabClose);

        return () => {
            window.removeEventListener('beforeunload', handleTabClose);
        };
    }, [token, logout]);





    return (
        <header className='header'>
             <div className='container'>
                <div className='header__inner'>
                <a href='/' className='header__logo'>
                <picture>
                    <img src={Logo} />
                </picture>
                <span>Алковрач</span>
                </a>
                    {token && (
                        <>
                            <div className='header__wrapper'>
                                <button className='header__burger'>
                            
                                </button>
                                <nav className='header__nav'>
                                <ul>
                                        <li className='header__link'>
                                            <button 
                                            onClick={() => navigate('/workorders')}>
                                                    Наряды
                                            </button>
                                        </li>
                                        <li className='header__link'>
                                            <button
                                            onClick={() => navigate('/map')}>
                                                    Карта
                                            </button>
                                        </li>
                                       <li className='header__link header__submenu'>
                                        <button>
                                            Настройки
                                        </button>
                                     <div className='header__submenu-content'>
                                        <div className="header__submenu-wrapper">
                                        <ul className="header__submenu-list">
                                        <li className='header__submenu-link'>
                                            <p className='header__submenu-title'>Наряды</p>
                                        </li>
                                            <li className='header__submenu-link'>
                                            <button 
                                            onClick={() => navigate('/templates')}>
                                                    Шаблоны
                                            </button>
                                            </li>
                                            {/* <li className='header__submenu-link'>
                                            <button 
                                            onClick={() => navigate('/teams')}>
                                                    teams
                                            </button>
                                            </li>*/}
                                           {/*  <li className='header__submenu-link'>
                                            <button 
                                            onClick={() => navigate('/employees')}>
                                                    Employees
                                            </button>
                                            </li>*/}
                                            <li className='header__submenu-link'>
                                            <button 
                                            onClick={() => navigate('/resolutions')}>
                                                    Резолюции
                                            </button>
                                            </li>
                                            <li className='header__submenu-link'>
                                            <p className='header__submenu-title'>Сотрудники</p>
                                            </li>
                                            <li className='header__submenu-link'>
                                            <button 
                                            onClick={() => navigate('/areas')}>
                                                    Участки
                                            </button>
                                            </li>
                                            <li className='header__submenu-link'>
                                            <button 
                                            onClick={() => navigate('/value-lists')}>
                                                    Списки значений
                                            </button>
                                            </li>
                                            {/*<li className='header__submenu-link'>
                                            <button 
                                            onClick={() => navigate('/executor-skill')}>
                                                    Навыки исполнителя
                                            </button>
                                            </li>*/}
                                            <li className='header__submenu-link'>
                                            <button 
                                            onClick={() => navigate('/order-types')}>
                                                    Типы нарядов
                                            </button>
                                            </li>

                                            <li className='header__submenu-link'>
                                            <p className='header__submenu-title'>Карта</p>
                                        </li>
                                            <li className='header__submenu-link'>
                                            <button 
                                            onClick={() => navigate('/mapcategory')}>
                                                    Категории
                                            </button>
                                            </li>
                                            <li className='header__submenu-link'>
                                            <button 
                                            onClick={() => navigate('/mapcreate')}>
                                                    Создание точек и областей
                                            </button>
                                            </li>

                                            {/*<li className='header__submenu-link'>
                                            <button 
                                            onClick={() => navigate('/legal-entities')}>
                                                 Юридические лица
                                            </button>
                                            </li>*/}
                                        </ul>
                                        </div>
                                    </div>
                                    </li>
                        </ul>
                                </nav>
                            </div>
                          <div className='header__btns'>
                            <div className="popover-wrapper">

                       
                            <CustomPopover
                              id="popover-jobs"
                              renderTrigger={({ onClick, id }) => (
                                <button id={id}  onClick={onClick}  className='header__button standart-btn white-btn create-jobs'
                                >
                                    Добавить наряд
                                </button>
                              )}
                              >
                                <div className='popover'>
                                     <Popup popupId={'create-jobs-template'} closeOnClickOutside={false}   useCustomCloseButton={true} >
                                     <button className='popup-btn popover-jobs__btn'>
                                            Наряд на основе шаблона
                                        </button>
                                        <CreateJobsTemaplate />
                                        
                                    </Popup>
                                    <Popup popupId={'create-jobs'} closeOnClickOutside={false}  useCustomCloseButton={true}
                                >
                                     <button className='popup-btn popover-jobs__btn'>
                                            Новый наряд
                                        </button>


                                    
                                        <CreateJobs />
                                        
                                    </Popup>
                                </div>
                            </CustomPopover>
                            
                              </div>
                          
       
                    <button className='header__notifications'>
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.7503 17.1572C10.6038 17.4098 10.3935 17.6194 10.1404 17.7652C9.88744 17.9109 9.60058 17.9876 9.30859 17.9876C9.01661 17.9876 8.72975 17.9109 8.47674 17.7652C8.22372 17.6194 8.01344 17.4098 7.86693 17.1572M14.3086 6.32389C14.3086 4.99781 13.7818 3.72604 12.8441 2.78836C11.9064 1.85067 10.6347 1.32389 9.30859 1.32389C7.98251 1.32389 6.71074 1.85067 5.77306 2.78836C4.83538 3.72604 4.30859 4.99781 4.30859 6.32389C4.30859 12.1572 1.80859 13.8239 1.80859 13.8239H16.8086C16.8086 13.8239 14.3086 12.1572 14.3086 6.32389Z" stroke="#D6BBFB" strokeWidth="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                     </button>
                          <button className='header__avatar'
                                onClick={logout} >
                                <picture>
                                    <img src={Avatar}/>
                                </picture>
                           </button>
                          </div>
                          
                        </>
                    )}
                </div>
            </div>
        </header>
           
     
    );
};

export default ResponsiveAppBar;
