import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchResolutions, fetchResolutionById, createResolution, updateResolution, deleteResolution } from '../../api/resolutionsApi';

export const fetchResolutionsThunk = createAsyncThunk(
    'resolutions/fetchAll',
    async (_, { rejectWithValue }) => {
        try {
            return await fetchResolutions();
        } catch (error) {
            return rejectWithValue('Failed to fetch resolutions');
        }
    }
);

export const fetchResolutionByIdThunk = createAsyncThunk(
    'resolutions/fetchById',
    async (id: number, { rejectWithValue }) => {
        try {
            return await fetchResolutionById(id);
        } catch (error) {
            return rejectWithValue(`Failed to fetch resolution with ID ${id}`);
        }
    }
);

export const createResolutionThunk = createAsyncThunk(
    'resolutions/create',
    async (data: { name: string; noteRequired: boolean; type: 'SUCCESSFUL' | 'UNSUCCESSFUL'; }, { rejectWithValue }) => {
        try {
            return await createResolution(data);
        } catch (error) {
            return rejectWithValue('Failed to create resolution');
        }
    }
);

export const updateResolutionThunk = createAsyncThunk(
    'resolutions/update',
    async ({ id, data }: { id: number; data: { name: string; noteRequired: boolean; type: 'SUCCESSFUL' | 'UNSUCCESSFUL'; } }, { rejectWithValue }) => {
        try {
            return await updateResolution(id, data);
        } catch (error) {
            return rejectWithValue(`Failed to update resolution with ID ${id}`);
        }
    }
);

export const deleteResolutionThunk = createAsyncThunk(
    'resolutions/delete',
    async (id: number, { rejectWithValue }) => {
        try {
            await deleteResolution(id);
            return id; // Return ID to remove it from the list in the slice
        } catch (error) {
            return rejectWithValue(`Failed to delete resolution with ID ${id}`);
        }
    }
);
