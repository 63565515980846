import React from 'react';
import { Map, Placemark, YMaps } from 'react-yandex-maps';

interface ExecutorLocation {
    email: string;
    latitude: number;
    longitude: number;
}

interface YandexMapProps {
    center: [number, number];
    zoom: number;
    executorLocations: ExecutorLocation[];
}

const YandexMap: React.FC<YandexMapProps> = ({ center, zoom, executorLocations }) => {
    return (
        <YMaps>
            <Map state={{ center, zoom }} width="80%" height="400px">
                {executorLocations.map((loc) => (
                    <Placemark
                        key={loc.email}
                        geometry={[loc.latitude, loc.longitude]}
                        properties={{
                            hintContent: loc.email,
                            balloonContent: `User: ${loc.email}`,
                        }}
                    />
                ))}
            </Map>
        </YMaps>
    );
};

export default YandexMap;
