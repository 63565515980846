import React from 'react';
import './DraggableListItem.scss';

interface DraggableListItemProps {
    id: string;
    text: string;
    onRemove: (id: string) => void;
    onDragStart: (event: React.DragEvent<HTMLLIElement>, id: string) => void;
    isLocked: boolean;
}

const DraggableListItem: React.FC<DraggableListItemProps> = ({ id, text, onRemove, onDragStart, isLocked }) => {
    return (
        <li
            className={`draggable-list-item ${isLocked ? 'draggable-list-item_locked' : ''}`}
            draggable={!isLocked}
            draggable-id={id}
            onDragStart={!isLocked ? (event) => onDragStart(event, id) : undefined}
        >
            <span className="draggable-list-item__text">{text}</span>
            {!isLocked && <button className="draggable-list-item__remove" onClick={() => onRemove(id)}>
                
            </button>}
        </li>
    );
};

export default DraggableListItem;