import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAreas, createArea } from '../../api/areaApi';
import { CreateAreaPayload } from './areaTypes';

// Fetch all areas
export const fetchAreas = createAsyncThunk('areas/fetchAreas', async () => {
    return await getAreas();
});

// Create a new area
export const createNewArea = createAsyncThunk('areas/createArea', async (area: CreateAreaPayload) => {
    return await createArea(area);
});
