import React from 'react';
import WorkOrders from '../components/WorkOrders/WorkOrders';

const WorkOrdersPage: React.FC = () => {
    return (
        <>
            <h1>Work Orders</h1>
            <WorkOrders/>
        </>
    );
};

export default WorkOrdersPage;