import React, { useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import YandexMap from './YandexMap';

interface ExecutorLocation {
    email: string;
    latitude: number;
    longitude: number;
}

const LocationTracker: React.FC = () => {
    const [socket, setSocket] = useState<Socket | null>(null);
    const [executorLocations, setExecutorLocations] = useState<ExecutorLocation[]>([]);
    const [mapCenter, setMapCenter] = useState<[number, number]>([53.9, 27.5667]); // Default Minsk
    const [zoom, setZoom] = useState(10);

    const getAuthToken = (): string | null => {
        return localStorage.getItem('token') || sessionStorage.getItem('token');
    };

    // Establish WebSocket connection and handle map updates
    useEffect(() => {
        const authToken = getAuthToken();
        if (!authToken) {
            console.error('No token found');
            return;
        }

        const newSocket = io('https://api.alcovrach.tech', {
            query: { token: authToken },
            transports: ['websocket'],
            withCredentials: true,
        });

        newSocket.on('connect', () => {
            console.log('WebSocket connected');

            // Request all current executor locations when connected
            newSocket.emit('getExecutorLocations');
        });

        // Receive and set all executor locations when the map is opened
        newSocket.on('executorLocations', (locations: ExecutorLocation[]) => {
            setExecutorLocations(locations);
            console.log('Received all executor locations:', locations);
        });

        // Update individual executor location in real-time
        newSocket.on('locationUpdate', (updatedLocation) => {
            setExecutorLocations((prevLocations) => {
                const index = prevLocations.findIndex(loc => loc.email === updatedLocation.email);
                const updatedLocations = [...prevLocations];
                if (index >= 0) updatedLocations[index] = updatedLocation;
                else updatedLocations.push(updatedLocation);
                return updatedLocations;
            });

            // Optionally update map center and zoom
            setMapCenter([updatedLocation.latitude, updatedLocation.longitude]);
            setZoom(12);
            console.log('Received updated location:', updatedLocation);
        });

        setSocket(newSocket);

        return () => {
            newSocket.disconnect();
        };
    }, []);

    // Send the current user's location updates
    useEffect(() => {
        if (socket) {
            const watchId = navigator.geolocation.watchPosition((position) => {
                const { latitude, longitude } = position.coords;
                console.log("Sending location to server:", latitude, longitude);
                socket.emit('sendLocation', { latitude, longitude });
            }, (error) => {
                console.error("Error fetching location:", error);
            }, {
                enableHighAccuracy: true,
                maximumAge: 0,
                timeout: 5000,
            });

            return () => {
                navigator.geolocation.clearWatch(watchId);
            };
        }
    }, [socket]);

    return (
        <>
            <YandexMap center={mapCenter} zoom={zoom} executorLocations={executorLocations} />
        </>
    );
};

export default LocationTracker;
