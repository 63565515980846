import React, { useState, useRef, useEffect, ReactNode } from 'react';

import "./AccordionItem.scss";

interface AccordionProps {
    title: string;
    children: ReactNode;
    customContent?: ReactNode;
}

const Accordion: React.FC<AccordionProps> = ({ title, children, customContent }) => {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef<HTMLDivElement | null>(null);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className='hide-item'>
            <div className='hide-top'>

         
            <button className={`hide-title ${isOpen ? ' active' : ''}`} onClick={toggleAccordion}>
                {title}
            </button>
            {customContent && (
                customContent
            )} 
            </div>
            <div className={`hide-content ${isOpen ? ' active' : ''}`} ref={contentRef} >
                {children}
            </div>
        </div>
    );
};

export default Accordion;