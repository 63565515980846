import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import ResponsiveAppBar from './components/AppBar/ResponsiveAppBar';
import Login from './pages/Login';
import { Teams } from './pages/Teams';
import Employees from './pages/Employees';
import ProtectedRoute from './routes/ProtectedRoute';
import WorkOrdersPage from './pages/WorkOrdersPage';
import { Map } from './pages/MapCategory'
import { MapCreate } from './pages/MapCreate'
// import WorkOrderForm from './components/WorkOrders/WorkOrderForm';
import UserForm from './components/Users/UserForm';
import TeamForm from './components/Teams/TeamForm';
import MapPage from './components/Map/Map';
import { Resolutions } from './pages/Resolutions';
import { ExecutorSkill } from './pages/ExecutorSkill'; // Import ExecutorSkill page
import { useKeepAlive } from './hooks/useKeepAlive';
import { OrderType } from './pages/OrderType';
import Areas from './pages/Areas';
import { ValueList } from './pages/ValueList'; // Import ValueList page
import ValueListForm from './components/ValueList/ValueListForm';
import { Templates } from './pages/Templates';
import TemplateForm from './components/Templates/TemplateForm';
import { LegalEntities } from './pages/LegalEntities';
import {LegalEntityForm} from './components/LegalEntities/LegalEntityForm';





const App: React.FC = () => {
    useKeepAlive(5 * 60 * 1000);

    return (
        <AuthProvider>
            <ResponsiveAppBar />
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route element={<ProtectedRoute />}>
                    {/* Work Orders Routes */}
                    <Route path="/workorders" element={<WorkOrdersPage />} />
                    {/*<Route path="/workorders/new" element={<WorkOrderForm />} />*/}
                    {/*<Route path="/workorders/:id" element={<WorkOrderForm />} />*/}

                    {/* Teams Routes */}
                    <Route path="/teams" element={<Teams />} />
                    <Route path="/teams/new" element={<TeamForm />} />
                    <Route path="/teams/:id" element={<TeamForm />} />

                    {/* Resolutions Routes */}
                    <Route path="/resolutions" element={<Resolutions />} />

                    {/* Employees Routes */}
                    <Route path="/employees" element={<Employees />} />
                    <Route path="/users/new" element={<UserForm />} />
                    <Route path="/users/:id" element={<UserForm />} />

                    {/* Executor Skill Route */}
                    <Route path="/executor-skill" element={<ExecutorSkill />} /> {/* ExecutorSkill */}

                    {/* Order Type Route */}
                    <Route path="/order-types" element={<OrderType />} /> {/* OrderType */}

                    {/* Areas Route */}
                    <Route path="/areas" element={<Areas />} /> {/* Areas */}

                    <Route path="/map" element={<MapPage />} /> {/* Map */}
                    <Route path="/mapcategory" element={<Map />} /> {/* Map Category */}
                    <Route path="/mapcreate" element={<MapCreate />} /> {/* Map Category */}
                   
                    {/* Value List Routes */}
                    <Route path="/value-lists" element={<ValueList />} /> {/* ValueList */}
                    <Route path="/value-lists/new" element={<ValueListForm />} /> {/* Create ValueList */}
                    <Route path="/value-lists/:id" element={<ValueListForm />} /> {/* Edit ValueList */}


                    {/* Template Routes */}
                    <Route path="/templates" element={<Templates />} />
                    <Route path="/templates/new" element={<TemplateForm readOnly={false} />} />
                    <Route path="/templates/edit/:id" element={<TemplateForm readOnly={false} />} />
                    <Route path="/templates/view/:id" element={<TemplateForm readOnly={true} />} />



                    <Route path="/legal-entities" element={<LegalEntities />} />
                    <Route path="/legal-entities/new" element={<LegalEntityForm />} />
                    <Route path="/legal-entities/:id" element={<LegalEntityForm />} />

                </Route>

                <Route path="/" element={<Navigate to="/login" />} />
            </Routes>
        </AuthProvider>
    );
};

export default App;
