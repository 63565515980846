import axiosInstance from './axiosConfig';
import { CreateTeamPayload, UpdateTeamPayload } from '../redux/team/teamTypes';

// Fetch all teams
export const getTeams = async () => {
    const response = await axiosInstance.get('/teams');
    return response.data;
};

// Fetch a single team by ID
export const getTeamById = async (id: number) => {
    const response = await axiosInstance.get(`/teams/${id}`);
    return response.data;
};

// Create a new team
export const createTeam = async (data: CreateTeamPayload) => {
    const response = await axiosInstance.post('/teams', data);
    return response.data;
};

// Update an existing team
export const updateTeam = async (id: number, data: UpdateTeamPayload) => {
    const response = await axiosInstance.patch(`/teams/${id}`, data);
    return response.data;
};

// Delete a team
export const deleteTeam = async (id: number) => {
    const response = await axiosInstance.delete(`/teams/${id}`);
    return response.data;
};