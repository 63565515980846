import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
    fetchResolutions,
    fetchResolutionById,
    createResolution,
    updateResolution,
    deleteResolution,
    ResolutionInput, // Keep this since it's used
} from '../../api/resolutionsApi';

// Helper function to safely extract error messages
const getErrorMessage = (error: unknown): string => {
    if (error instanceof AxiosError && error.response) {
        return error.response.data?.message || 'An error occurred';
    }
    return 'An unexpected error occurred';
};

export const fetchResolutionsThunk = createAsyncThunk(
    'resolutions/fetchAll',
    async (_, { rejectWithValue }) => {
        try {
            return await fetchResolutions();
        } catch (error) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchResolutionByIdThunk = createAsyncThunk(
    'resolutions/fetchById',
    async (id: number, { rejectWithValue }) => {
        try {
            return await fetchResolutionById(id);
        } catch (error) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const createResolutionThunk = createAsyncThunk(
    'resolutions/create',
    async (data: ResolutionInput, { rejectWithValue }) => {
        try {
            return await createResolution(data);
        } catch (error) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const updateResolutionThunk = createAsyncThunk(
    'resolutions/update',
    async ({ id, data }: { id: number; data: ResolutionInput }, { rejectWithValue }) => {
        try {
            return await updateResolution(id, data);
        } catch (error) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const deleteResolutionThunk = createAsyncThunk(
    'resolutions/delete',
    async (id: number, { rejectWithValue }) => {
        try {
            await deleteResolution(id);
            return id; // Optimistic UI: Return ID for immediate UI update
        } catch (error) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);
