import React, { useEffect, useState, useRef, useCallback, useMemo  } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllWorkOrdersThunk, createWorkOrderThunk } from '../../redux/workOrder/workOrderThunks';
import { RootState, AppDispatch } from '../../redux/store';
import WorkOrdersItem, { WorkOrdersItemStatus, Job } from './WorkOrdersItem';

import FileUpload from "../FileUpload/FileUpload";
import CustomPopover from '../CustomPopover/CustomPopover';
import DraggableListItem from "../DraggableListItem/DraggableListItem";
import FilterSidebar from '../FilterSidebar/FilterSidebar';
import Popup from '../Popup/Popup';
import BasicDateTimePicker from '../DatePicker/DateTimePicker';
import BasicDateRangePicker from '../DatePicker/DateRange';
import BasicDatePicker from '../DatePicker/DatePicker';
import ExclusiveCheckboxes from '../ExclusiveCheckboxes/ExclusiveCheckboxes';
import WorkPopup from './WorkOrdersPopup';
import FileDownload from '../FileDownload/FileDownload';
import "./WorkOrders.scss";
import CustomSelect, { Filter, Element } from '../Select/CustomSelect';


const WorkOrders: React.FC = () => {



  const dispatch = useDispatch<AppDispatch>();
  const { workOrders } = useSelector((state: RootState) => state.workOrder);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [masterChecked, setMasterChecked] = useState(false); 
  const [isSelectMode, setIsSelectMode] = useState(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectedJobId, setSelectedJobId] = useState<number | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false); 


  
  const handleJobClick = (id: number) => {
    setSelectedJobId(id); 
    setIsPopupOpen(true); 
    document.documentElement.classList.add('popup-open');
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false); 
    document.documentElement.classList.remove('popup-open');
  };

  const jobs = [
    {
      id: 123007,
      address: {
        city: 'г. Брянск',
        street: 'пер Пилотов, д 16 к 1, кв. 12',
      },
      clientName: 'Елена',
      taskType: 'Первичный выезд',
      time: '14:00',
      worker: 'Ибрагим Д.',
      status: WorkOrdersItemStatus.Published,
    },
    {
      id: 2123,
      address: {
        city: 'г. Москва',
        street: 'ул. Ленина, д 10, кв. 5',
      },
      clientName: 'Иван',
      taskType: 'Повторный выезд',
      time: '15:00',
      worker: 'Ибрагим Д.',
      status: WorkOrdersItemStatus.Finished,
    },
    {
      id: 876,
      address: {
        city: 'г. Санкт-Петербург',
        street: 'пр. Невский, д 25, кв. 7',
      },
      clientName: 'Алексей',
      taskType: 'Ремонт',
      time: '16:00',
      worker: 'Ибрагим Д.',
      status: WorkOrdersItemStatus.Started,
    },
    {
      id: 67,
      address: {
        city: 'г. Казань',
        street: 'ул. Баумана, д 15, кв. 3',
      },
      clientName: 'Мария',
      taskType: 'Консультация',
      time: '17:00',
      worker: 'Ибрагим Д.',
      status: WorkOrdersItemStatus.Closed,
    },
    {
      id: 123126,
      address: {
        city: 'г. Брянск',
        street: 'пер Пилотов, д 16 к 1, кв. 12',
      },
      clientName: 'Елена',
      taskType: 'Первичный выезд',
      time: '14:00',
      worker: 'Ибрагим Д.',
      status: WorkOrdersItemStatus.Published,
    },
    {
      id: 3332,
      address: {
        city: 'г. Москва',
        street: 'ул. Ленина, д 10, кв. 5',
      },
      clientName: 'Иван',
      taskType: 'Повторный выезд',
      time: '15:00',
      worker: 'Ибрагим Д.',
      status: WorkOrdersItemStatus.Finished,
    },
    {
      id: 1,
      address: {
        city: 'г. Санкт-Петербург',
        street: 'пр. Невский, д 25, кв. 7',
      },
      clientName: 'Алексей',
      taskType: 'Ремонт',
      time: '16:00',
      worker: 'Ибрагим Д.',
      status: WorkOrdersItemStatus.Started,
    },
    {
      id: 8,
      address: {
        city: 'г. Казань',
        street: 'ул. Баумана, д 15, кв. 3',
      },
      clientName: 'Мария',
      taskType: 'Консультация',
      time: '17:00',
      worker: 'Ибрагим Д.',
      status: WorkOrdersItemStatus.Closed,
    },
    {
      id: 245687505,
      address: {
        city: 'г. Брянск',
        street: 'пер Пилотов, д 16 к 1, кв. 12',
      },
      clientName: 'Елена',
      taskType: 'Первичный выезд',
      time: '14:00',
      worker: 'Ибрагим Д.',
      status: WorkOrdersItemStatus.Published,
    },
    {
      id: 33,
      address: {
        city: 'г. Москва',
        street: 'ул. Ленина, д 10, кв. 5',
      },
      clientName: 'Иван',
      taskType: 'Повторный выезд',
      time: '15:00',
      worker: 'Ибрагим Д.',
      status: WorkOrdersItemStatus.Finished,
    },
    {
      id: 12341,
      address: {
        city: 'г. Санкт-Петербург',
        street: 'пр. Невский, д 25, кв. 7',
      },
      clientName: 'Алексей',
      taskType: 'Ремонт',
      time: '16:00',
      worker: 'Ибрагим Д.',
      status: WorkOrdersItemStatus.Started,
    },
    {
      id: 865,
      address: {
        city: 'г. Казань',
        street: 'ул. Баумана, д 15, кв. 3',
      },
      clientName: 'Мария',
      taskType: 'Консультация',
      time: '17:00',
      worker: 'Ибрагим Д.',
      status: WorkOrdersItemStatus.Closed,
    },
    {
      id: 654,
      address: {
        city: 'г. Брянск',
        street: 'пер Пилотов, д 16 к 1, кв. 12',
      },
      clientName: 'Елена',
      taskType: 'Первичный выезд',
      time: '14:00',
      worker: 'Ибрагим Д.',
      status: WorkOrdersItemStatus.Published,
    },
    {
      id: 523,
      address: {
        city: 'г. Москва',
        street: 'ул. Ленина, д 10, кв. 5',
      },
      clientName: 'Иван',
      taskType: 'Повторный выезд',
      time: '15:00',
      worker: 'Ибрагим Д.',
      status: WorkOrdersItemStatus.Finished,
    },
    {
      id: 4512512,
      address: {
        city: 'г. Санкт-Петербург',
        street: 'пр. Невский, д 25, кв. 7',
      },
      clientName: 'Алексей',
      taskType: 'Ремонт',
      time: '16:00',
      worker: 'Ибрагим Д.',
      status: WorkOrdersItemStatus.Started,
    },
    {
      id: 2,
      address: {
        city: 'г. Казань',
        street: 'ул. Баумана, д 15, кв. 3',
      },
      clientName: 'Мария',
      taskType: 'Консультация',
      time: '17:00',
      worker: 'Ибрагим Д.',
      status: WorkOrdersItemStatus.Closed,
    },
    {
      id: 223,
      address: {
        city: 'г. Брянск',
        street: 'пер Пилотов, д 16 к 1, кв. 12',
      },
      clientName: 'Елена',
      taskType: 'Первичный выезд',
      time: '14:00',
      worker: 'Ибрагим Д.',
      status: WorkOrdersItemStatus.Published,
    },
    {
      id: 123,
      address: {
        city: 'г. Москва',
        street: 'ул. Ленина, д 10, кв. 5',
      },
      clientName: 'Иван',
      taskType: 'Повторный выезд',
      time: '15:00',
      worker: 'Ибрагим Д.',
      status: WorkOrdersItemStatus.Finished,
    },
    {
      id: 2474307,
      address: {
        city: 'г. Санкт-Петербург',
        street: 'пр. Невский, д 25, кв. 7',
      },
      clientName: 'Алексей',
      taskType: 'Ремонт',
      time: '16:00',
      worker: 'Ибрагим Д.',
      status: WorkOrdersItemStatus.Started,
    },
    {
      id: 24643508,
      address: {
        city: 'г. Казань',
        street: 'ул. Баумана, д 15, кв. 3',
      },
      clientName: 'Мария',
      taskType: 'Консультация',
      time: '17:00',
      worker: 'Ибрагим Д.',
      status: WorkOrdersItemStatus.Closed,
    },
    {
      id: 2475405,
      address: {
        city: 'г. Брянск',
        street: 'пер Пилотов, д 16 к 1, кв. 12',
      },
      clientName: 'Елена',
      taskType: 'Первичный выезд',
      time: '14:00',
      worker: 'Ибрагим Д.',
      status: WorkOrdersItemStatus.Published,
    },
    {
      id: 2475011,
      address: {
        city: 'г. Москва',
        street: 'ул. Ленина, д 10, кв. 5',
      },
      clientName: 'Иван',
      taskType: 'Повторный выезд',
      time: '15:00',
      worker: 'Ибрагим Д.',
      status: WorkOrdersItemStatus.Finished,
    },
    {
      id: 247507,
      address: {
        city: 'г. Санкт-Петербург',
        street: 'пр. Невский, д 25, кв. 7',
      },
      clientName: 'Алексей',
      taskType: 'Ремонт',
      time: '16:00',
      worker: 'Ибрагим Д.',
      status: WorkOrdersItemStatus.Started,
    },
    {
      id: 247528,
      address: {
        city: 'г. Казань',
        street: 'ул. Баумана, д 15, кв. 3',
      },
      clientName: 'Мария',
      taskType: 'Консультация',
      time: '17:00',
      worker: 'Ибрагим Д.',
      status: WorkOrdersItemStatus.Closed,
    },
  ];

  const [selectedElements, setSelectedElements] = useState<Element[]>([]);

  const elements: Element[] = [
    { id: '1', name: 'Григорий', type: 'item', filter: 'job' },
    { id: '2', name: 'Марк', type: 'item', filter: 'job' },
    { id: '3', name: 'Твен', type: 'item', filter: 'job' },
    { id: '4', name: 'Маркс', type: 'item', filter: 'job' },
    {
        id: '5',
        name: 'Ростов 1',
        type: 'brigada',
        filter: 'brigade',
        isCompound: true,
        compoundItems: [
            { id: '5-1', name: 'Ростов 1', type: 'brigada', filter: 'brigade' }, 
            { id: '5-2', name: 'Павел', type: 'item', filter: 'job' },  
            { id: '5-3', name: 'Ибрагим', type: 'item', filter: 'job' },  
        ],
    },
    {
        id: '6',
        name: 'Анадырь 1',
        type: 'brigada',
        filter: 'brigade',
        isCompound: true,
        compoundItems: [
            { id: '6-1', name: 'Анадырь 1', type: 'brigada', filter: 'brigade' }, 
            { id: '6-2', name: 'Павел 2', type: 'item', filter: 'job' }, 
            { id: '6-3', name: 'Михаил', type: 'item', filter: 'job' }, 
        ],
    },
    { id: '7', name: 'Григорий 2', type: 'item', filter: 'job' },
    { id: '8', name: 'Марк 2', type: 'item', filter: 'job' },
    { id: '10', name: 'Твен 2', type: 'item', filter: 'job' },
    { id: '11', name: 'Маркс 2', type: 'item', filter: 'job' },
    {
      id: '12',
      name: 'Ростов 2',
      type: 'brigada',
      filter: 'brigade',
      isCompound: true,
      compoundItems: [
          { id: '12-1', name: 'Ростов 2', type: 'brigada', filter: 'brigade' }, 
          { id: '12-2', name: 'Карен', type: 'item', filter: 'job' },  
          { id: '12-3', name: 'Алексей', type: 'item', filter: 'job' },  
          { id: '12-4', name: 'Григорий', type: 'item', filter: 'job' },  
          { id: '12-5', name: 'Афанасий', type: 'item', filter: 'job' },  
      ],
  },
];
  const filtersSelect: Filter[] = [
      { id: 'job', name: 'Исполнитель' },
      { id: 'brigade', name: 'Бригада' },
  ];

  const handleMasterCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked = e.target.checked;
    setMasterChecked(newChecked);

    if (newChecked) {
      const allIds = jobs.map((job) => job.id);
      setSelectedIds(allIds);

    } else {
      setSelectedIds([]);
    }
  };

  const handleSelectClick = () => {
    if (isSelectMode) {
      setSelectedIds([]);
    }
    setIsSelectMode(!isSelectMode);
    setMasterChecked(false);
  };
  const handleCheckboxChange = (id: number, isChecked: boolean) => {
    if (isChecked) {
      setSelectedIds([...selectedIds, id]);
    } else {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    }
  };

  useEffect(() => {
    dispatch(fetchAllWorkOrdersThunk());
  }, [dispatch]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const input = form.querySelector('input') as HTMLInputElement;
    setSearchTerm(input.value);
  };
  /*const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };*/
  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
};

const sortedItems = [...jobs].sort((a, b) => {
   return sortOrder === 'asc' ? a.id - b.id : b.id - a.id
})
const [filters, setFilters] = useState([
  'Все наряды', 'Незавершенные за неделю', 'Контакты клиентов', 'Завершенные', 'Успешные', 'Неуспешные', 'День', 'Владимир Мизев', 'Ежедневная', 'Аналитика по выручке', 'Тут ещё вариант', 'И ещё один'
]);
const [draggedItemId, setDraggedItemId] = useState<string | null>(null);

const handleDragStart = (event: React.DragEvent<HTMLLIElement>, id: string) => {
  setDraggedItemId(id);
  event.dataTransfer.setData('text/plain', id);
  event.dataTransfer.effectAllowed = 'move';
};

const handleRemoveFilter = (id: string) => {
   if (filters.indexOf(id) > 1) {
       setFilters(prevFilters => prevFilters.filter(filter => filter !== id));
   }
};

const handleDragOver = (event: React.DragEvent<HTMLUListElement>) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = 'move';
};

const handleDrop = (event: React.DragEvent<HTMLUListElement>) => {
 event.preventDefault();
 const droppedOnElement = event.target as HTMLElement;
  const droppedOnListItem = droppedOnElement.closest('li')
  if (droppedOnListItem) {
    const droppedOnId = droppedOnListItem.getAttribute('draggable-id') ||  event.dataTransfer.getData('text/plain');

  if (draggedItemId && droppedOnId && draggedItemId !== droppedOnId) {
          const dragItemIndex = filters.indexOf(draggedItemId);
         const dropItemIndex = filters.indexOf(droppedOnId);
          if (dragItemIndex > 1 && dropItemIndex > 1 && dragItemIndex !== -1 && dropItemIndex !== -1) {
                const newFilters = [...filters];
              const [removed] = newFilters.splice(dragItemIndex, 1);
              newFilters.splice(dropItemIndex, 0, removed);
              setFilters(newFilters);
          }
         setDraggedItemId(null);
     }
   }
};

const handleResetFilters = () => {
  setFilters([
    'Все наряды', 'Незавершенные за неделю', 'Контакты клиентов', 'Завершенные', 'Успешные', 'Неуспешные', 'День', 'Владимир Мизев', 'Ежедневная', 'Аналитика по выручке', 'Тут ещё вариант', 'И ещё один' 
  ]);
};

const visibleFilters = filters.slice(0, 10);
const hiddenFilters = filters.slice(10);



  const filteredWorkOrders = [...workOrders]
    .filter((order) => order.id.toString().includes(searchTerm))
    .sort((a, b) => (sortOrder === 'asc' ? a.id - b.id : b.id - a.id));

  const getNaryadForm = (count: number): string => {
    const lastDigit = count % 10;
    const lastTwoDigits = count % 100;
    if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
      return 'нарядов';
    }
    if (lastDigit === 1) {
      return 'наряд';
    } else if (lastDigit >= 2 && lastDigit <= 4) {
      return 'наряда';
    } else {
      return 'нарядов';
    }
  };



  const SideBaritems = [
    {
        title: "Бригады",
        items: [
            "1 Абакан Бригада",
            "1 Архангельск Бригада",
            "1 Астрахань Бригада",
            "1 Ачинск Бригада",
            "1 Балаково Бригада",
            "1 Бийск Бригада",
            "1 Благовещенск",
        ],
    },
    {
        title: "Подразделения",
        items: [
           "2 Абакан Бригада",
            "2 Архангельск Бригада",
            "2 Астрахань Бригада",
            "2 Ачинск Бригада",
            "2 Балаково Бригада",
            "2 Бийск Бригада",
            "2 Благовещенск",
        ],
    },
];
const clientsItems = [
  {
    items: [
  'Заполнено (любое значение)',
  'Заполнено (текст)',
  'Не заполнено',
    ]
  }
]
const [data, setData] = useState([
  { text: "Павел", filter: 'Сотрудники' },
  { text: "Игорь", filter: 'Сотрудники' },
  { text: "Джеймс", filter: 'Сотрудники' },
  {
    text: 'Бригада 1',
    filter: 'Бригады',
    members: [
      { text: 'Павел Бригада', filter: 'Сотрудники' },
      { text: 'Виктор Бригада', filter: 'Сотрудники' },
    ],
  },
  {
    text: 'Бригада 2',
    filter: 'Бригады',
    members: [
      { text: 'Павел Бригада', filter: 'Сотрудники' },
      { text: 'Виктор Бригада', filter: 'Сотрудники' },
    ],
  },
  { text: "Елена", filter: 'Сотрудники' },
  { text: "Светлана", filter: 'Сотрудники' },
  { text: "Артур", filter: 'Сотрудники' },
  { text: "Григорий", filter: 'Сотрудники' },
  { text: "Елизавета", filter: 'Сотрудники' },
  { text: "Мария", filter: 'Сотрудники' },
  { text: "Ксения", filter: 'Сотрудники' },
]);

const refreshAllButtons = document.querySelectorAll<HTMLInputElement>('.all-refresh-btn');
if (refreshAllButtons) {
  refreshAllButtons.forEach(btn => {
    const refreshWrapper = btn.closest('.all-refresh');
    if (refreshWrapper) {
      const filterRefreshBtns = refreshWrapper.querySelectorAll<HTMLInputElement>('.all-refresh-refresh');
      const checkboxs = refreshWrapper.querySelectorAll<HTMLInputElement>("input[type='checkbox']");
      btn.addEventListener('click', ()=> {
        if (filterRefreshBtns.length > 0) {
          filterRefreshBtns.forEach(elem => {
            elem.click();
          })
        }
        if (checkboxs.length > 0) {
          checkboxs.forEach(elem => {
            elem.dataset.checked = 'false'; 
            elem.checked = false; 
          })
        }
      })
    }
  });

}
  return (
    <section className='jobs'>
      <div className='container'>
        <div className='jobs__top'>
          <h1 className="jobs__title">
            Наряды
          </h1>
          <div className='jobs__btns'>
            <div className='jobs__btns'>
              <div className='jobs__item'>
                {isSelectMode ? (
                  <>
                    <button className='standart-btn jobs__btn jobs__btn-inputs jobs__btn-archive' data-path='' disabled={selectedIds.length === 0}>
                      Переместить в архив
                    </button>
                    <div className="popover-wrapper">
                    <CustomPopover
                        id="example-popover"
                        renderTrigger={({ onClick, id }) => (
                          <button id={id}  onClick={onClick}  className='standart-btn jobs__btn jobs__btn-inputs jobs__btn-users' disabled={selectedIds.length === 0}>Другой исполнитель</button>
                        )}
      >
          <div className="popover">
                        <p className="popover-title">
                          Назначить {selectedIds.length} {getNaryadForm(selectedIds.length)}
                        </p>
                        <div className='jobs__popover-wrapper'>
                        <CustomSelect 
                        elements={elements}
                      filters={filtersSelect}
                       onChange={setSelectedElements} />
                        </div>
                        <div className="jobs__popover-btns">
                              <button className='standart-btn opacity-btn'>
                              Отменить
                              </button>
                              <button className='standart-btn purple-btn'>
                                Подтвердить
                              </button>
                        </div>
                      </div>
      </CustomPopover>


                              </div>
                              <div className="popover-wrapper">
                              <CustomPopover
                        id="plan-popover"
                       renderTrigger={({ onClick, id }) => (
                          <button id={id}  onClick={onClick}  className='popup-btn standart-btn jobs__btn jobs__btn-inputs jobs__btn-plan' disabled={selectedIds.length === 0}>Перепланировать</button>
                        )}
      >
         <div className="popover">
                        <p className="popover-title">
                          Перепланировать {selectedIds.length} {getNaryadForm(selectedIds.length)}
                        </p>
                        <p className="popover-subtitle">Запланировать на:</p>
                          <div className="jobs__calendar calendar">
                              <BasicDateTimePicker />
                          </div>
                          <div className="jobs__popover-btns">
                              <button className='standart-btn opacity-btn'>
                              Отменить
                              </button>
                              <button className='standart-btn purple-btn'>
                                Подтвердить
                              </button>
                        </div>
                      </div>
      </CustomPopover>

                          

                    </div>
                  </>
                ) : (
                  <>
                    <form className='jobs__search' onSubmit={handleSubmit}>
                      <input type='text' className='jobs__input' placeholder='Поиск' />
                      <button className='jobs__search-btn' type='submit'></button>
                    </form>
                    <Popup popupId={'jobs__upload'}>
              

                      <button className='popup-btn standart-btn jobs__upload jobs__btn'>
                        Загрузить
                      </button>
                      <h2 className='popup__title'>Загрузка нарядов</h2>
                      <p className='popup__subtitle'>Вы можете загрузить наряды из файла в формате XLSX или CSV. <a href="#">Подробное описание формата и документация</a></p>
                      <FileUpload />
                    </Popup>
                    <div className="popover-wrapper">
                    <CustomPopover
                        id="excel-popover"
                       renderTrigger={({ onClick, id }) => (
                          <button id={id}  onClick={onClick}  className='standart-btn jobs__btn jobs__load-excel'>Выгрузить в Excel</button>
                        )}
      >
       <div className="popover excel-popover__btns">
                        <FileDownload />
                      </div>
      </CustomPopover>
                    </div>
                  </>
                )}
                <button className='standart-btn purple-btn_light' onClick={handleSelectClick}>
                  {isSelectMode ? 'Назад' : 'Выбрать...'}
                </button>
              </div>

              <button className='jobs__refresh'>
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.1668 3.99054V8.99054M19.1668 8.99054H14.1668M19.1668 8.99054L15.3002 5.35721C14.4045 4.46114 13.2965 3.80655 12.0795 3.45452C10.8625 3.10249 9.57608 3.0645 8.3404 3.34409C7.10472 3.62368 5.96 4.21174 5.01305 5.05339C4.06611 5.89504 3.3478 6.96286 2.92516 8.15721M0.833496 17.3239V12.3239M0.833496 12.3239H5.8335M0.833496 12.3239L4.70016 15.9572C5.59579 16.8533 6.70381 17.5079 7.92084 17.8599C9.13787 18.2119 10.4242 18.2499 11.6599 17.9703C12.8956 17.6907 14.0403 17.1027 14.9873 16.261C15.9342 15.4194 16.6525 14.3516 17.0752 13.1572" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className='jobs__navigation'>
          <div className="jobs__filters">
            <div className="jobs__filters-wrapper">
            {visibleFilters.map((filter, index) => (
                  <button key={index} className={`jobs__filter${index === 0 ? ' active' : ''}`}>
                    {filter}
                 </button>
            ))}
            </div>
           
            <div className="popover-wrapper">
            <CustomPopover
                        id="navigations-popover"
                       renderTrigger={({ onClick, id }) => (
                          <button id={id}  onClick={onClick}  className='jobs__filter-more'></button>
                        )}
      >
      <div className="popover jobs__navigations-popover">
                          <div className="jobs__navigations-popover__item">
                              <ul>
                              {hiddenFilters.map((filter, index) => (
                                <li key={index}><button className='jobs__navigations-popover__btn'>{filter}</button></li>
                                ))}
                               
                              </ul>
                          </div>
                          <button className="jobs__navigations-popover__trash">
                          Архив нарядов
                          </button>
                          <div className="jobs__navigations-popover__btns">
                        
                            <Popup popupId={'jobs__navigations-popover'}>
                            <button className='standart-btn purple-btn jobs__navigations-popover__btn popup-btn'>
                            Настроить вид
                            </button>
                              <h2 className='popup__title jobs__filters-popup__title'>Настроить вид</h2>
                              <ul className='jobs__navigations-popover__list' onDragOver={handleDragOver} onDrop={handleDrop}>
                {filters.map((filter, index) => {
                    const isLocked = index < 2;
                   return (
                        <DraggableListItem
                            key={index}
                            id={filter}
                            text={filter}
                            onRemove={handleRemoveFilter}
                            onDragStart={handleDragStart}
                            isLocked={isLocked}
                        />
                   )
                })}
            </ul>
                   <div className="jobs__navigations-popover__actions">
                   <button className='standart-btn purple-btn'>Сохранить</button>
                      <button className='standart-btn opacity-btn' onClick={handleResetFilters}>Сбросить</button>
                  
                 </div>
                            </Popup>
                            <button className="standart-btn opacity-btn jobs__navigations-popover__btn" onClick={handleResetFilters}>
                            Сбросить
                            </button>
                          </div>
                      </div>
      </CustomPopover>
  
            </div>
           
                
          </div>
          <p className='jobs__counter'>{jobs.length} {getNaryadForm(jobs.length)}</p>
         
          <Popup popupId={'jobs__views'}>
                <button className='standart-btn jobs__settings popup-btn'>
                </button>
                <h2 className='popup__title jobs__views-popup__title'>Показывать колонки</h2>
                <div className="jobs__filters-popup__inner">
                  <div className="jobs__filters-popup__list">
                    <div className="jobs__filters-popup__head">
                      <ul>
                        <li>Поля наряда</li>
                        <li>Дополнительные поля</li>
                        <li>Поля отчета</li>
                      </ul>
                    </div>
                    <div className="jobs__filters-popup__item">
                      <ul className="jobs__filters-popup__item-list">
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Номер наряда</label></li>
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Внешний идентификатор</label></li>
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Адрес</label></li>

                      </ul>
                      <ul className="jobs__filters-popup__item-list">
                      <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Номер наряда</label></li>
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Внешний идентификатор</label></li>
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Адрес</label></li>
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Номер наряда</label></li>
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Внешний идентификатор</label></li>
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Адрес</label></li>
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Номер наряда</label></li>
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Внешний идентификатор</label></li>
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Адрес</label></li>
                          </ul>
                      <ul className="jobs__filters-popup__item-list">
                      <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Номер наряда</label></li>
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Внешний идентификатор</label></li>
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Адрес</label></li>
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Номер наряда</label></li>
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Внешний идентификатор</label></li>
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Адрес</label></li>
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Номер наряда</label></li>
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Внешний идентификатор</label></li>
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Адрес</label></li>
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Номер наряда</label></li>
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Внешний идентификатор</label></li>
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Адрес</label></li>
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Номер наряда</label></li>
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Внешний идентификатор</label></li>
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Адрес</label></li>
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Номер наряда</label></li>
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Внешний идентификатор</label></li>
                          <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Адрес</label></li>

                      </ul>
                    </div>
                  </div>
                  <div className="jobs__filtes-popup__btns">
                    <button className='standart-btn purple-btn'>
                    Сохранить вид
                    </button>
                    <button className='standart-btn opacity-btn jobs__filtes-popup__btn'>
                    Отменить
                    </button>
                  </div>
                </div>
       </Popup>
        </div>
        <div className="jobs__wrapper">
          <aside className="jobs__sidebar sidebar all-refresh">
            <div className="sidebar__inner">
            <div className="sidebar__filters">
                <FilterSidebar name="Исполнитель" 
                showResetButton={true}
                showSearchInput={true}
                listObjects={SideBaritems}
                >
                </FilterSidebar>
                <FilterSidebar name="Состояние" 
                showResetButton={true}
                showSearchInput={true}
                listObjects={SideBaritems}
                >
                </FilterSidebar>
                <FilterSidebar name="Шаблон" 
                listObjects={clientsItems}
                showResetButton={true}
                >
                </FilterSidebar>
                <FilterSidebar name="Запланирован"
                  showResetButton={true}
                  ExclusiveCheckboxes={true}
                  >
                <ExclusiveCheckboxes>
                <ul className='sidebar__filter-list'>
                     <li className='sidebar__filter-list__head'>Дата</li>
                     <li>
                    <label className='custom-checkbox'>  <input
                            type="checkbox"
                        /><span></span>
                         В промежутке:</label>
                         </li>
                         <li className='sidebar__filter-calendar calendar'>
                              <BasicDateRangePicker />
                         </li>
                         <li>
                    <label className='custom-checkbox'>  <input
                            type="checkbox"
                        /><span></span>
                      Равная  </label>
                         </li>
                         <li className='sidebar__filter-calendar calendar'>
                        <BasicDatePicker />
                         </li>
                         <li>
                    <label className='custom-checkbox'>  <input
                            type="checkbox"
                        /><span></span>
                    Ранее чем</label>
                         </li>
                         <li className='sidebar__filter-calendar calendar'>
                         <BasicDatePicker />
                         </li>
                         <li>
                    <label className='custom-checkbox'>  <input
                            type="checkbox"
                        /><span></span>
                         Позднее чем</label>
                         </li>
                         <li className='sidebar__filter-calendar calendar'>
                         <BasicDatePicker />
                         </li>
                 </ul>
                </ExclusiveCheckboxes>
                </FilterSidebar>
                <FilterSidebar name="Завершен"
                   showResetButton={true}
                   ExclusiveCheckboxes={true}
                   >
                <ExclusiveCheckboxes>
                <ul className='sidebar__filter-list'>
                     <li className='sidebar__filter-list__head'>Дата</li>
                     <li>
                    <label className='custom-checkbox'>  <input
                            type="checkbox"
                        /><span></span>
                         В промежутке:</label>
                         </li>
                         <li className='sidebar__filter-calendar calendar'>
                              <BasicDateRangePicker />
                         </li>
                         <li>
                    <label className='custom-checkbox'>  <input
                            type="checkbox"
                        /><span></span>
                      Равная  </label>
                         </li>
                         <li className='sidebar__filter-calendar calendar'>
                        <BasicDatePicker />
                         </li>
                         <li>
                    <label className='custom-checkbox'>  <input
                            type="checkbox"
                        /><span></span>
                    Ранее чем</label>
                         </li>
                         <li className='sidebar__filter-calendar calendar'>
                         <BasicDatePicker />
                         </li>
                         <li>
                    <label className='custom-checkbox'>  <input
                            type="checkbox"
                        /><span></span>
                         Позднее чем</label>
                         </li>
                         <li className='sidebar__filter-calendar calendar'>
                         <BasicDatePicker />
                         </li>
                  </ul>
                  </ExclusiveCheckboxes>
                </FilterSidebar>
                <FilterSidebar name="Клиент или объект" 
                listObjects={clientsItems}
                showResetButton={true}
                >
                </FilterSidebar>
                <FilterSidebar name="Участок" 
                listObjects={clientsItems}
                showResetButton={true}
                >
                </FilterSidebar>
                <FilterSidebar name="Тип" 
                listObjects={clientsItems}
                showResetButton={true}
                >
                </FilterSidebar>
                <FilterSidebar name="Координаты адреса" 
                listObjects={clientsItems}
                showResetButton={true}
                >
                </FilterSidebar>
                <FilterSidebar name="Врач-партнер" 
                listObjects={clientsItems}
                showResetButton={true}
                >
                </FilterSidebar>
                <FilterSidebar name="Диспетчер" 
                listObjects={clientsItems}
                showResetButton={true}
                >
                </FilterSidebar>
                <FilterSidebar name="Услуга" 
                listObjects={clientsItems}
                showResetButton={true}
                >
                </FilterSidebar>
                <FilterSidebar name="Администратор" 
                listObjects={clientsItems}
                showResetButton={true}
                >
                </FilterSidebar>
                <FilterSidebar name="Дата выписки" 
                listObjects={clientsItems}
                showResetButton={true}
                >
                </FilterSidebar>
                <FilterSidebar name="Дата первичной консультации" 
                listObjects={clientsItems}
                showResetButton={true}
                >
                </FilterSidebar>

              </div>
              <div className="sidebar__bottom">
                <div className="sidebar__btns">
                  <button className="sidebar__btn standart-btn purple-btn">
                    Применить
                  </button>
                  <button className="sidebar__btn standart-btn transparent-btn all-refresh-btn">
                    Сбросить
                  </button>
                </div>
                <Popup popupId={'jobs__filters'}>
                    <button className="standart-btn grey-btn sidebar__settings popup-btn">
                    Настроить фильтры
                    </button>
                      <h2 className='popup__title jobs__filters-popup__title'>Настроить фильтры</h2>
                      <div className="jobs__filters-popup__inner">
                        <div className="jobs__filters-popup__list">
                          <div className="jobs__filters-popup__head">
                            <ul>
                              <li>Поля наряда</li>
                              <li>Дополнительные поля</li>
                              <li>Поля отчета</li>
                            </ul>
                          </div>
                          <div className="jobs__filters-popup__item">
                            <ul className="jobs__filters-popup__item-list">
                                <li><label className='custom-checkbox'><input type="checkbox"/><span></span> Номер наряда</label></li>
                                <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Внешний идентификатор</label></li>
                                <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Адрес</label></li>
                                <li><label className='custom-checkbox'><input type="checkbox"/><span></span> Номер наряда</label></li>
                                <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Внешний идентификатор</label></li>
                                <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Адрес</label></li>
                            </ul>
                            <ul className="jobs__filters-popup__item-list">
                            <li><label className='custom-checkbox'><input type="checkbox"/><span></span> Номер наряда</label></li>
                                <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Внешний идентификатор</label></li>
                                <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Адрес</label></li>
                            </ul>
                            <ul className="jobs__filters-popup__item-list">
                            <li><label className='custom-checkbox'><input type="checkbox"/><span></span> Номер наряда</label></li>
                                <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Внешний идентификатор</label></li>
                                <li><label className='custom-checkbox'><input type="checkbox"/><span></span>Адрес</label></li>

                            </ul>
                          </div>
                        </div>
                        <div className="jobs__filtes-popup__btns">
                          <button className='standart-btn purple-btn'>
                          Сохранить
                          </button>
                          <button className='standart-btn opacity-btn jobs__filtes-popup__btn'>
                          Отменить
                          </button>
                        </div>
                      </div>
                    </Popup>
               
              </div>
            </div>
          </aside>
          <div className="jobs__table">
            <div className='jobs__table-wrapper'>
              <table>
                <thead>
                  <tr>
                    <td className={`jobs__table-id ${isSelectMode ? 'can-selected' : ''}`}>
                      <div>

                        <label className='custom-checkbox'> <input
                          type="checkbox"
                          checked={masterChecked}
                          onChange={handleMasterCheckboxChange}
                        /><span></span>
                      
                        №  </label>
                        <button
                          onClick={toggleSortOrder}
                          className={`jobs__table-sort ${sortOrder === 'asc' ? 'jobs__table-sort_down' : 'jobs__table-sort_up'}`}
                        >

                        </button>

                      </div>
                    </td>
                    <td>
                      Адрес
                    </td>
                    <td>Объект (клиент)</td>
                    <td>Шаблон</td>
                    <td>Время</td>
                    <td>Исполнитель</td>
                    <td>Состояние</td>
                  </tr>
                 
                </thead>
                <tbody>
                <tr>
                    <td></td>
                    <td><b>Не запланированные</b>, 50+ нарядов</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  {sortedItems.map((job) => (
                    <WorkOrdersItem
                      key={job.id}
                      {...job}
                      isSelectMode={isSelectMode}
                      isChecked={selectedIds.includes(job.id)}
                      onCheckboxChange={handleCheckboxChange}
                      onClick={handleJobClick}
                    />
                  ))}
                </tbody>
                
              </table>
            </div>
          </div>

        </div>
      </div>
      {isPopupOpen && selectedJobId && (
        <WorkPopup jobId={selectedJobId} onClose={handleClosePopup} />
      )}
    </section>
  );
};
export default WorkOrders;
