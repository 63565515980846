// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background: #f5f5f5;
  border-radius: 8px;
}
.login-form .text-field {
  padding: 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Auth/LoginForm.scss"],"names":[],"mappings":"AAAA;EAIE,gBAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AAFF;AALE;EACE,eAAA;AAOJ","sourcesContent":[".login-form {\n  .text-field {\n    padding: 10px 0;\n  }\n  max-width: 400px;\n  margin: 0 auto;\n  padding: 20px;\n  background: #f5f5f5;\n  border-radius: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
