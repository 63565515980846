// src/index.tsx
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { store } from './redux/store';
import App from './App';
import './styles/global.scss';

import theme from './styles/Theme'; 
import { ThemeProvider } from '@mui/material/styles';

const container = document.getElementById('root');
const root = createRoot(container!); // `!` asserts that container is not null

root.render(
    <Provider store={store}>
        <Router>
        <ThemeProvider theme={theme}>
            <App />
            </ThemeProvider>
        </Router>
    </Provider>
);
