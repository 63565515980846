import React from 'react';
import WorkOrders from '../components/WorkOrders/WorkOrders';

const WorkOrdersPage: React.FC = () => {
    return (
        <>
            <WorkOrders/>
        </>
    );
};

export default WorkOrdersPage;