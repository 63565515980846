import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import 'dayjs/locale/ru';
import './TimePicker.scss';
import dayjs from 'dayjs'; // Импортируем dayjs

interface BasicTimePickerProps {
  initialTime?: dayjs.Dayjs | null; // Определяем тип пропа initialTime
}

export default function BasicTimePicker({ initialTime }: BasicTimePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
      <TimePicker label="Время" value={initialTime} />
    </LocalizationProvider>
  );
}