import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import 'dayjs/locale/ru';
import './DateRange.scss';
export default function BasicDatePicker() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
        <DateRangePicker localeText={{ start: 'От', end: 'До' }} />
    </LocalizationProvider>
  );
}