import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../redux/store';
import {
  fetchValueLists,
  createValueList,
  updateValueList,
  deleteValueList,
  fetchValueListById,
  reorderValueListOptions,
} from '../../redux/valueList/valueListThunks';
import { ValueList } from '../../redux/valueList/types';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './Value.scss';

const ITEM_TYPE = 'Option';

interface Option {
  id: number;
  value: string;
}

const ValueListForm: React.FC<{
  valueList?: ValueList;
  onSave: (payload: { name: string; options: string[] }) => void;
  onCancel: () => void;
}> = ({ valueList, onSave, onCancel }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [name, setName] = useState(valueList?.name || '');
  const [options, setOptions] = useState<Option[]>(
      valueList?.options.map((value, index) => ({ id: index, value })) || []
  );

  // ✅ Keep track of the active input field
  const activeInputRef = useRef<HTMLInputElement | null>(null);

  const handleSave = () => {
    const payload = { name, options: options.map((option) => option.value) };
    onSave(payload);
  };

  const handleAddOption = () => {
    setOptions((prevOptions) => [...prevOptions, { id: Date.now(), value: '' }]);
  };

  const handleOptionDelete = (id: number) => {
    setOptions((prevOptions) => prevOptions.filter((option) => option.id !== id));
  };

  // ✅ Fix cursor disappearance issue
  const handleOptionChange = (id: number, newValue: string) => {
    setOptions((prevOptions) =>
        prevOptions.map((opt) =>
            opt.id === id ? { ...opt, value: newValue } : opt
        )
    );
  };

  // ✅ Keep input focus after updates
  useEffect(() => {
    if (activeInputRef.current) {
      activeInputRef.current.focus();
    }
  }, [options]);

  // ✅ Restore Drag & Drop
  const moveOption = (dragIndex: number, hoverIndex: number) => {
    setOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      const [draggedOption] = newOptions.splice(dragIndex, 1);
      newOptions.splice(hoverIndex, 0, draggedOption);

      if (valueList) {
        dispatch(reorderValueListOptions({ id: valueList.id, orderedOptions: newOptions.map(opt => opt.value) }));
      }

      return newOptions;
    });
  };

  // ✅ Draggable list item component
  const OptionItem: React.FC<{
    option: Option;
    index: number;
    moveOption: (dragIndex: number, hoverIndex: number) => void;
    onDelete: (id: number) => void;
  }> = ({ option, index, moveOption, onDelete }) => {
    const [, ref] = useDrag({
      type: ITEM_TYPE,
      item: { index },
    });

    const [, drop] = useDrop({
      accept: ITEM_TYPE,
      hover: (item: { index: number }) => {
        if (item.index !== index) {
          moveOption(item.index, index);
          item.index = index;
        }
      },
    });

    return (
        <li ref={(node) => ref(drop(node))} className="value__edit-list__item">
          <div className="value__input-wrapper">
            <input
                ref={(el) => {
                  if (option.id === options[options.length - 1].id) {
                    activeInputRef.current = el;
                  }
                }}
                value={option.value}
                onChange={(e) => handleOptionChange(option.id, e.target.value)}
            />
            <button
                className="settings__change settings__change_trash"
                onClick={() => onDelete(option.id)}
            ></button>
          </div>
        </li>
    );
  };

  return (
      <DndProvider backend={HTML5Backend}>
        <div className="popup">
          <div className="popup__body">
            <div className="popup__content">
              <button className="close-popup" onClick={onCancel}></button>
              <p className="value__edit-title">{valueList ? 'Редактирование списка' : 'Добавление списка'}</p>
              <p className="value__edit-name">Название списка</p>
              <input
                  className="standart-input"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
              />
              <p className="value__edit-list__title">Значения</p>
              <ul className="value__edit-list">
                {options.map((option, index) => (
                    <OptionItem
                        key={option.id}
                        option={option}
                        index={index}
                        moveOption={moveOption}
                        onDelete={handleOptionDelete}
                    />
                ))}
              </ul>
              <button onClick={handleAddOption} className="value__add">Добавить значение</button>
              <div className="value__edit-btns">
                <button className="standart-btn purple-btn" onClick={handleSave}>Сохранить</button>
                <button className="standart-btn opacity-btn" onClick={onCancel}>Отменить</button>
              </div>
            </div>
          </div>
        </div>
      </DndProvider>
  );
};

const ValueListList: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { valueLists, loading, error } = useSelector((state: RootState) => state.valueList);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editingValueList, setEditingValueList] = useState<ValueList | null>(null);

  useEffect(() => {
    dispatch(fetchValueLists());
  }, [dispatch]);

  const handleCreate = () => {
    setEditingValueList(null);
    setIsFormVisible(true);
  };

  const handleEdit = (valueList: ValueList) => {
    dispatch(fetchValueListById(valueList.id));
    setEditingValueList(valueList);
    setIsFormVisible(true);
  };

  const handleCopy = (valueList: ValueList) => {
    const copiedValueList = { name: `${valueList.name} (Копия)`, options: [...valueList.options] };
    dispatch(createValueList(copiedValueList));
  };

  const handleSave = (payload: { name: string; options: string[] }) => {
    if (editingValueList) {
      dispatch(updateValueList({ id: editingValueList.id, data: payload }));
    } else {
      dispatch(createValueList(payload));
    }
    setIsFormVisible(false);
  };

  const handleDelete = (id: number) => {
    dispatch(deleteValueList(id));
    setIsFormVisible(false);
  };

  return (
      <section className="settings">
        <div className="container container-small">
          <div className="section-top">
            <h1 className="section-title">Настройки</h1>
          </div>
          <div className="order-type settings__inner">
            <div className="section-top settings__inner-title">
              <h2 className="section-title">Списки значений</h2>
              <button className="value__add" onClick={handleCreate}>Добавить список</button>
            </div>
            {loading ? (
                <div className="loading-icon"><div></div></div>
            ) : error ? (
                <p className="order-type__error">Ошибка: {error}</p>
            ) : (
                <>
                  <table className="value__table">
                    <thead>
                    <tr><th>Название</th><th>Значения</th><th></th></tr>
                    </thead>
                    <tbody>
                    {valueLists.map((list) => (
                        <tr key={list.id}>
                          <td className="value__name">{list.name}</td>
                          <td className="value__option">{list.options.join(', ')}</td>
                          <td>
                            <div className="value__btns">
                              <button className="value__btn value__btn-copy" onClick={() => handleCopy(list)}></button>
                              <button className="value__btn value__btn-trash" onClick={() => handleDelete(list.id)}></button>
                              <button className="value__btn value__btn-change" onClick={() => handleEdit(list)}></button>
                            </div>
                          </td>
                        </tr>
                    ))}
                    </tbody>
                  </table>
                  {isFormVisible && <ValueListForm valueList={editingValueList || undefined} onSave={handleSave} onCancel={() => setIsFormVisible(false)} />}
                </>
            )}
          </div>
        </div>
      </section>
  );
};

export default ValueListList;
