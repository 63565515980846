// src/redux/workOrder/workOrderThunks.ts
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getWorkOrders, getWorkOrderById, createWorkOrder, updateWorkOrder } from '../../api/workOrderApi';

export const fetchWorkOrders = createAsyncThunk('workOrder/fetchWorkOrders', async () => {
    return await getWorkOrders(); // Delegate to API function
});

export const fetchWorkOrderById = createAsyncThunk('workOrder/fetchWorkOrderById', async (id: number) => {
    return await getWorkOrderById(id); // Delegate to API function
});

export const saveWorkOrder = createAsyncThunk(
    'workOrder/saveWorkOrder',
    async (workOrder: { id?: number; description: string; customer: string; address: string; latitude: number; longitude: number }) => {
        const { id, ...data } = workOrder; // Extract 'id' and use the rest of the data
        if (id) {
            return await updateWorkOrder(id, data); // Call update with id and data
        } else {
            return await createWorkOrder(data); // Create new work order
        }
    }
);
