import React, { useState } from 'react';

enum WorkOrdersItemStatus {
  Published = 'Размещён',
  Finished = 'Завершён',
  Started = 'Создан',
  Closed = 'Отменён',
}

// Создаём объект для преобразования статус в класс
const statusClassMapping: Record<WorkOrdersItemStatus, string> = {
  [WorkOrdersItemStatus.Published]: 'published',
  [WorkOrdersItemStatus.Finished]: 'finished',
  [WorkOrdersItemStatus.Started]: 'started',
  [WorkOrdersItemStatus.Closed]: 'closed',
};

export interface Job {
  id: number;
  address: {
    city: string;
    street: string;
  };
  clientName: string;
  taskType: string;
  time: string;
  worker: string;
  status: WorkOrdersItemStatus;
  isSelectMode: boolean;
  isChecked: boolean;
  onCheckboxChange: (id: number, isChecked: boolean) => void;
  onClick: (id: number) => void; 
}

const WorkOrdersItem: React.FC<Job> = ({
  id,
  address,
  clientName,
  taskType,
  time,
  worker,
  status,
  isSelectMode,
  isChecked,
  onCheckboxChange,
  onClick, 
}) => {
  const statusClass = `jobs__table-status jobs__table-status_${statusClassMapping[status]}`;

  return (
    <tr >
    <td className={`jobs__table-id ${isSelectMode ? 'can-selected' : ''}`}>
      <div>
        <label className='custom-checkbox'><input
          type="checkbox"
          checked={isChecked}
          onChange={(e) => onCheckboxChange(id, e.target.checked)}
        /><span></span>
        </label>
    <button onClick={() => onClick(id)}>
    {id}
    </button>
       
    
        
      </div>
       
      </td>
      <td className="jobs__table-address">
        <p>{address.city}</p>
        <span>{address.street}</span>
      </td>
      <td>
        <b>{clientName}</b>
      </td>
      <td>{taskType}</td>
      <td>{time}</td>
      <td>{worker}</td>
      <td>
        <span className={statusClass}>{status}</span>
      </td>
    </tr>
  );
};

export default WorkOrdersItem;
export { WorkOrdersItemStatus }; 
