import React, { useState, useEffect, useRef } from 'react';
import './Medecine.scss';

// Типы данных
export interface ElementMedecine {
  id: string;
  name: string;
  filter?: string; // filterId, но теперь это не обязательно
}

export interface FilterMedecine {
  id: string;
  name: string;
}

interface SearchableMultiSelectProps {
  elements: ElementMedecine[];
  filters: FilterMedecine[];
  onChange?: (selectedElements: ElementMedecine[]) => void;
  initialSelectedIds?: string[];
}

const SearchableMultiSelect: React.FC<SearchableMultiSelectProps> = ({ elements, filters, initialSelectedIds = [], onChange }) => {
  const [selectedElements, setSelectedElements] = useState<ElementMedecine[]>(() => {
    if (initialSelectedIds.length > 0) {
      return elements.filter(element => initialSelectedIds.includes(element.id));
    }
    return [];
  });

  const [isOpen, setIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const selectRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node) && isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const filterElements = (element: ElementMedecine) => {
    if (activeFilter === null || activeFilter === 'all') {
      return true;
    }
    return element.filter === activeFilter;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setIsOpen(true);
  };

  const handleFilterClick = (filterId: string | null) => {
    setActiveFilter(filterId);
    setSearchTerm('');
  };

  const handleElementClick = (element: ElementMedecine) => {
    const isSelected = selectedElements.some((selected) => selected.id === element.id);
    if (isSelected) {
      setSelectedElements(selectedElements.filter((selected) => selected.id !== element.id));
    } else {
      setSelectedElements([...selectedElements, element]);
    }
  };

  const handleRemoveSelectedElement = (element: ElementMedecine) => {
    setSelectedElements(selectedElements.filter((selected) => selected.id !== element.id));
  };

  const renderSelectedElements = () => {
    return (
      <div className="custom-select-search__items medecine__items">
        <p className="medecine__title">
        Укажите дозировки
        </p>
        {selectedElements.map((element) => (
          <div key={element.id} className="medecine__item">
               <p className="medecine__name"> {element.name}</p>
                 <select className='standart-select'>
        <option value="Не выбрано" defaultChecked>Не выбрано</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
      </select>
        
            <button className="delete-item" onClick={() => handleRemoveSelectedElement(element)}></button>
          </div>
        ))}
      </div>
    );
  };

  const renderFilterButtons = () => {
    return (
      <div className="filters">
        <FilterButton
          filter={{ id: 'all', name: 'Все фильтры' }}
          isActive={activeFilter === null || activeFilter === 'all'}
          onClick={() => handleFilterClick('all')}
        />
        {filters.map((filter) => (
          <FilterButton
            key={filter.id}
            filter={filter}
            isActive={activeFilter === filter.id}
            onClick={() => handleFilterClick(filter.id)}
          />
        ))}
      </div>
    );
  };

  const renderElementList = () => {
    const filteredElements = elements
      .filter(filterElements)
      .filter((element) => element.name.toLowerCase().includes(searchTerm.toLowerCase()));

    const groupedElements = filters.map((filter) => ({
      filter,
      elements: filteredElements.filter((element) => element.filter === filter.id),
    }));

    return (
      <ul className="custom-select-search__list">
           {(activeFilter === null || activeFilter === 'all') && (
          <>
          
            {elements.filter(element => !element.filter).map((element) => (
              <ElementListItem
                key={element.id}
                element={element}
                onClick={() => handleElementClick(element)}
              />
            ))}
          </>
        )}
        {groupedElements.map((group) => (
          <React.Fragment key={group.filter.id}>
            {group.elements.length > 0 && (
              <>
                <li className="custom-select-search__list-filter__name">{group.filter.name}</li>
                {group.elements.map((element) => (
                  <ElementListItem
                    key={element.id}
                    element={element}
                    onClick={() => handleElementClick(element)}
                  />
                ))}
              </>
            )}
          </React.Fragment>
        ))}
     
      </ul>
    );
  };

  useEffect(() => {
    if (onChange) {
      onChange(selectedElements);
    }
  }, [selectedElements, onChange]);

  const handleAddButtonClick = () => {
    setIsOpen(true);
    setSearchTerm('');
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className="searchable-multi-select custom-select-search" ref={selectRef}>
      {(selectedElements.length > 0) && (
      renderSelectedElements()
      )}
      {selectedElements.length > 0 && !isOpen ? (
        <button className="custom-select-search__add-more" onClick={handleAddButtonClick}>
          Выбрать ещё
        </button>
      ) : (
        <div className="custom-select-search__input">
          <input
            type="text"
            placeholder="Поиск..."
            onChange={handleInputChange}
            value={searchTerm}
            onClick={() => setIsOpen(true)}
            ref={inputRef}
          />
        </div>
      )}
      {isOpen && (
        <div className="custom-select-search__dropdown">
          {renderFilterButtons()}
          {renderElementList()}
        </div>
      )}
    </div>
  );
};

const ElementListItem: React.FC<{ element: ElementMedecine; onClick: () => void }> = ({ element, onClick }) => {
  return (
    <li onClick={onClick}>
 
      {element.name}
    </li>
  );
};

const FilterButton: React.FC<{ filter: FilterMedecine; isActive: boolean; onClick: () => void }> = ({ filter, isActive, onClick }) => {
  return (
    <button
      className={`filter-button ${isActive ? 'active' : ''}`}
      onClick={onClick}
    >
      {filter.name}
    </button>
  );
};

export default SearchableMultiSelect;
